import { QueryClient, QueryClientProvider } from '@tanstack/react-query';

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      retry: (failureCount, error) => {
        const status = error?.response?.status;

        if (status && status === 400) {
          return false;
        }

        return failureCount < 3;
      },
    },
  },
});

// ----------------------------------------------------------------------

export const ReactQueryProvider = props => {
  const { children } = props;

  return <QueryClientProvider client={queryClient}>{children}</QueryClientProvider>;
};
