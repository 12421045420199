import Button from './Button';
import Card from './Card';
import LoadingButton from './LoadingButton';
import Skeleton from './Skeleton';
import Typography from './Typography';
import Autocomplete from './Autocomplete';
import Input from './Input';
import DatePicker from './DatePicker';

export const overrideComponents = theme =>
  Object.assign(
    Button(theme),
    Card(theme),
    LoadingButton(theme),
    Skeleton(theme),
    Typography(theme),
    Autocomplete(theme),
    DatePicker(theme),
    Input(theme),
  );
