import { Card, CardContent, Typography, Stack, Divider, Box, Tooltip } from '@mui/material';
import MainContainer from '../MainContainer/MainContainer';
import Bedclothes from '../../Assets/serviceIcons/Bedclothes.png';
import InfotainmentService from '../../Assets/serviceIcons/InfotainmentService.png';
import Meal from '../../Assets/serviceIcons/Meal.png';
import Pets from '../../Assets/serviceIcons/Pets.png';
import RestaurantCarOrBuffet from '../../Assets/serviceIcons/RestaurantCarOrBuffet.png';

export const TrainCard = ({ children, trainData }) => {
  const getDate = data => {
    const date = data.split('T')[0].slice(5).split('-');

    return [date[1], date[0]].join('.');
  };

  const getTime = time => {
    return time.split('T')[1].slice(0, -3);
  };

  const getTripDuration = duration => {
    const hours = Math.trunc(duration / 60);
    const minutes = duration - hours * 60;

    return (
      <>
        {hours} ч {minutes} мин
      </>
    );
  };

  const capitalizeLetters = string => {
    if (string) {
      let capitalizedString = string
        .toLowerCase()
        .split(/ |-/)
        .map(word => {
          return word[0].toUpperCase() + word.slice(1).toLowerCase();
        })
        .join('-');
      return capitalizedString;
    }
    return string;
  };

  const getTrainDescription = description => {
    switch (description) {
      case 'В-СКОР':
      case 'СК':
      case 'СКРСТ':
        return 'Скоростной';
      case 'СК ФИРМ':
        return 'Скорый Фирменный';
      case 'ФИРМ':
        return 'Фирменный';
      case 'ПАСС':
        return 'Пассажирский';
      case 'Скорый с местами':
        return 'Скорый с местами';
      default:
        return '';
    }
  };

  const getService = description => {
    switch (description) {
      case 'Meal':
        return { src: Meal, description: 'Питание' };
      case 'RestaurantCarOrBuffet':
        return { src: RestaurantCarOrBuffet, description: 'Вагон-ресторан или буфет' };
      case 'InfotainmentService':
        return { src: InfotainmentService, description: 'Развлекательные услуги' };
      case 'Bedclothes':
        return { src: Bedclothes, description: 'Постельное белье' };
      case 'Pets':
        return { src: Pets, description: 'Место рядом с питомцем' };
      default:
        return { src: '', description: '' };
    }
  };

  const hasPlacesNearPets = trainData.CarGroups.some(group => group.HasPlacesNearPets);

  return (
    <Box marginBottom={5}>
      <MainContainer
        direction="column"
        alignItems="start"
        sx={{
          padding: { xs: '20px 20px', md: '30px 40px' },
          borderRadius: 1.25,
          boxShadow: '0px 0px 10px rgba(0, 0, 0, 0.05)',
        }}
      >
        <Card sx={{ width: '100%', boxShadow: 'none', color: 'black', borderRadius: 0 }}>
          <CardContent sx={{ padding: 0, '&:last-child': { padding: 0 } }}>
            <Stack direction={{ xs: 'column', md: 'row' }} justifyContent="space-between" spacing={4}>
              <Box flex={1}>
                <Typography fontSize={{ xs: 16, lg: 20 }} mb={{ xs: 2.5, lg: 3.75 }}>
                  {trainData.TrainNumber} {capitalizeLetters(trainData.InitialTrainStationName)} —{' '}
                  {capitalizeLetters(trainData.DestinationStationName)}
                </Typography>
                <Stack direction="column" spacing={1.5} alignItems="center" mt={1}>
                  <Stack direction="row" width="100%" height={{ xs: 'auto', lg: '55px' }}>
                    <Typography
                      variant="h4"
                      sx={{ alignSelf: 'flex-end', marginRight: { xs: '15px', md: '30px' } }}
                      fontSize={{ xs: 20, lg: 28 }}
                      fontWeight={500}
                    >
                      {getTime(trainData.LocalDepartureDateTime)}
                    </Typography>
                    <Box flexGrow={1} sx={{ alignSelf: { xs: 'flex-end', lg: 'flex-start' } }}>
                      <Typography align="center" mb={{ xs: 0, md: 1 }} color={'secondary'} fontSize={12}>
                        В пути: {getTripDuration(trainData.TripDuration)}
                      </Typography>
                      <Divider sx={{ borderBottomWidth: { xs: 0, md: 4 }, borderColor: '#FF7517', opacity: 1 }} />
                    </Box>
                    <Typography
                      variant="h4"
                      sx={{ alignSelf: 'flex-end', marginLeft: { xs: '15px', md: '30px' } }}
                      fontSize={{ xs: 20, md: 24, lg: 28 }}
                      fontWeight={500}
                    >
                      {getTime(trainData.LocalArrivalDateTime)}
                    </Typography>
                  </Stack>
                  <Divider
                    sx={{ borderBottomWidth: { xs: 4, md: 0 }, width: '100%', borderColor: '#FF7517', opacity: 1 }}
                  />
                  <Stack direction="row" width="100%" justifyContent={'space-between'}>
                    <Box>
                      <Typography color={'secondary'} fontSize={{ xs: 12, lg: 16 }}>
                        {capitalizeLetters(trainData.InitialTrainStationName)}
                      </Typography>
                      <Typography color={'secondary'} fontSize={{ xs: 12, lg: 16 }}>
                        {getDate(trainData.LocalDepartureDateTime)}
                      </Typography>
                    </Box>
                    <Box textAlign="right">
                      <Typography color={'secondary'} fontSize={{ xs: 12, lg: 16 }}>
                        {capitalizeLetters(trainData.DestinationStationName)}
                      </Typography>
                      <Typography color={'secondary'} fontSize={{ xs: 12, lg: 16 }}>
                        {getDate(trainData.LocalArrivalDateTime)}
                      </Typography>
                    </Box>
                  </Stack>
                </Stack>
                <Stack direction="row" alignItems="center" mt={2} flexWrap={'wrap'}>
                  <Typography variant="h4" fontSize={{ xs: 16, lg: 20 }} marginRight={1} mb={{ xs: 1, md: 0 }}>
                    {trainData.Provider} — {getTrainDescription(trainData.TrainDescription)}
                    {trainData.TrainName !== '' ? ` — ${trainData.TrainName}` : ''}
                  </Typography>
                  <Stack direction="row" spacing={1}>
                    {trainData.CarServices.map((item, i) => (
                      <Tooltip title={getService(item).description} key={i}>
                        <img src={getService(item).src} />
                      </Tooltip>
                    ))}
                    {hasPlacesNearPets && (
                      <Tooltip title="Место рядом с питомцем">
                        <img src={Pets} alt="Pets" />
                      </Tooltip>
                    )}
                  </Stack>
                </Stack>
              </Box>
              {children}
            </Stack>
          </CardContent>
        </Card>
      </MainContainer>
    </Box>
  );
};
