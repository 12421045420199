import { Button, Stack } from 'react-bootstrap';
import { CustomDialog } from '../../../Components/dialogs/CustomDialog';

export const FioWarningDialog = props => {
  const { isOpen, onConfirm, onClose } = props;

  return (
    <CustomDialog
      show={isOpen}
      onHide={onClose}
      title="У пассажира действительно нет отчества?"
      body={
        <>
          <p>
            Если в документе есть отчество, его указание является обязательным требованием законадательства Российской
            Федерации.
          </p>
          <p>Пассажир, имеющий отчество, но не указавший его, может быть не допущен в поезд.</p>
          <p>Продолжить оформление без указания отчества?</p>
        </>
      }
      actions={
        <Stack direction="horizontal" gap={3}>
          <Button variant="danger" onClick={onConfirm}>
            Нет отчества
          </Button>
          <Button variant="light" color="red" onClick={onClose}>
            Отчество есть
          </Button>
        </Stack>
      }
    />
  );
};
