import { useEffect, useRef, useState } from 'react';
import { Box, Button, Stack, styled, Typography } from '@mui/material';
import { DefaultSelection } from './DefaultSelection';
import LuxuryComponent from './LuxuryComponent';
import MainContainer from '../../MainContainer/MainContainer';

const ErrorBox = styled(Box)`
  padding: 30px;
  color: white;
  background-color: #ff7517;
  border-radius: 20px;
`;
const PreFormComponent = ({ setPreFormInfo, forward, backward, carrier, carType }) => {
  // Если backward пустой, приводим его к null
  if (backward.length === 0) {
    backward = null;
  }

  // Преобразуем forward и backward в объекты по ключу Place
  forward = forward.reduce((acc, item) => {
    const { Place, ...rest } = item;
    acc[Place] = rest;
    return acc;
  }, {});

  if (backward !== null) {
    backward = backward.reduce((acc, item) => {
      const { Place, ...rest } = item;
      acc[Place] = rest;
      return acc;
    }, {});
  }

  const PlaceReservationType = forward[Object.keys(forward)[0]].PlaceReservationType;
  const [carPlaceTypeMeaning, setCarPlaceTypeMeaning] = useState([]);
  const ServiceClass = forward[Object.keys(forward)[0]].ServiceClass;

  const availableSeats = {
    forward: Object.keys(forward),
    backward: backward !== null ? Object.keys(backward) : null,
  };

  const [error, setError] = useState('');
  const [restSeats, setRestSeats] = useState({ ...availableSeats });
  const divSelectionRef = useRef();
  const [refs, setRefs] = useState([]);

  const initialRow = {
    category: 'Adult',
    forward: availableSeats.forward[0],
    backward: availableSeats.backward === null ? null : availableSeats.backward[0],
    forwardNonReturnableTariff: forward[availableSeats.forward[0]]['HasNonRefundableTariff'],
    backwardNonReturnableTariff:
      availableSeats.backward === null ? false : backward[availableSeats.backward[0]]['HasNonRefundableTariff'],
    forwardGender: forward[availableSeats.forward[0]]['Gender'],
    backwardGender: availableSeats.backward === null ? false : backward[availableSeats.backward[0]]['Gender'],
    forwardCarPlaceType: forward[availableSeats.forward[0]].CarPlaceType,
    backwardCarPlaceType: availableSeats.backward === null ? false : backward[availableSeats.backward[0]].CarPlaceType,
    carType: carType,
  };

  const [preFormParam, setPreFormParam] = useState(
    ['TwoPlacesAtOnce', 'FourPlacesAtOnce'].includes(PlaceReservationType) ? [initialRow] : [],
  );

  const categories = {
    Adult: 'Взрослые и дети старше 10 лет',
    Child: 'Дети до 10 лет с местом',
    BabyWithoutPlace: 'Малыши до 5 лет без места, бесплатно',
  };

  useEffect(() => {
    let updateRefs = [...refs];
    updateRefs.push(divSelectionRef);
    setRefs(updateRefs);
  }, [preFormParam]);

  useEffect(() => {
    const CarPlaceTypeMeaning = [];
    for (let i in forward) {
      if (forward[i].CarPlaceType === 'WithChild' || forward[i].CarPlaceType === 'MotherAndBaby') {
        CarPlaceTypeMeaning.push(forward[i].CarPlaceType);
      }
    }
    if (backward !== null) {
      for (let i in backward) {
        if (backward[i].CarPlaceType === 'WithChild' || backward[i].CarPlaceType === 'MotherAndBaby') {
          CarPlaceTypeMeaning.push(backward[i].CarPlaceType);
        }
      }
    }
    setCarPlaceTypeMeaning(CarPlaceTypeMeaning.filter((item, index) => CarPlaceTypeMeaning.indexOf(item) === index));
  }, []);

  const addPassengerRow = category => {
    if (category === 'Adult' || category === 'Child') {
      if (restSeats.forward.length === 0) {
        setError('Нет доступных мест для выбора!');
        return;
      }
      const assignedForward = restSeats.forward[0];
      let assignedBackward = null;
      if (restSeats.backward && restSeats.backward.length > 0) {
        assignedBackward = restSeats.backward[0];
      }
      const newRow = {
        category: category,
        forward: assignedForward,
        backward: assignedBackward,
        forwardNonReturnableTariff: forward[assignedForward]['HasNonRefundableTariff'],
        backwardNonReturnableTariff:
          restSeats.backward && assignedBackward ? backward[assignedBackward]['HasNonRefundableTariff'] : false,
        forwardGender: forward[assignedForward]['Gender'],
        backwardGender: restSeats.backward && assignedBackward ? backward[assignedBackward]['Gender'] : false,
        forwardCarPlaceType: forward[assignedForward].CarPlaceType,
        backwardCarPlaceType: restSeats.backward && assignedBackward ? backward[assignedBackward].CarPlaceType : false,
        carType: carType,
      };
      setPreFormParam([...preFormParam, newRow]);
      setRestSeats(prev => ({
        ...prev,
        forward: prev.forward.filter(seat => seat !== assignedForward),
        backward: prev.backward ? prev.backward.filter(seat => seat !== assignedBackward) : null,
      }));
    } else if (category === 'BabyWithoutPlace') {
      const adultCount = preFormParam.filter(item => item.category === 'Adult').length;
      const babyCount = preFormParam.filter(item => item.category === 'BabyWithoutPlace').length;
      if (babyCount >= adultCount) {
        setError(
          "Количество 'Малыши до 5 лет без места, бесплатно' не может превышать количество 'Взрослые и дети старше 10 лет'",
        );
        return;
      }
      const newRow = {
        category: 'BabyWithoutPlace',
        forward: null,
        backward: null,
        forwardNonReturnableTariff: false,
        backwardNonReturnableTariff: false,
        forwardGender: 'NoValue',
        backwardGender: 'NoValue',
        forwardCarPlaceType: 'NoValue',
        backwardCarPlaceType: 'NoValue',
        carType: carType,
      };
      setPreFormParam([...preFormParam, newRow]);
    }
    setError('');
  };

  const removePassengerRow = category => {
    const reversed = preFormParam.map((item, idx) => ({ ...item, idx })).reverse();
    const found = reversed.find(item => item.category === category);
    if (!found) return;
    const actualIndex = found.idx;
    const removedRow = preFormParam[actualIndex];
    const updatedParams = preFormParam.filter((_, i) => i !== actualIndex);
    setPreFormParam(updatedParams);
    if (removedRow.forward) {
      setRestSeats(prev => ({ ...prev, forward: [...prev.forward, removedRow.forward] }));
    }
    if (removedRow.backward && removedRow.backward !== null && restSeats.backward) {
      setRestSeats(prev => ({ ...prev, backward: [...prev.backward, removedRow.backward] }));
    }
  };

  // Валидация выбранных пассажиров по дополнительным условиям
  const validateSelections = () => {
    const adultCount = preFormParam.filter(item => item.category === 'Adult').length;
    const childCount = preFormParam.filter(item => item.category === 'Child').length;
    const babyCount = preFormParam.filter(item => item.category === 'BabyWithoutPlace').length;

    if (babyCount > adultCount) {
      setError('Количество детей без места не может превышать количество взрослых пассажиров.');
      return false;
    }

    if (carPlaceTypeMeaning.includes('MotherAndBaby') && babyCount !== 1) {
      setError('При выборе места матери с ребенком должен быть выбран один ребенок без места.');
      return false;
    }

    if (carPlaceTypeMeaning.includes('WithChild')) {
      if (!(adultCount === 1 || adultCount === 2)) {
        setError('При выборе места с ребенком должен быть выбран один или два взрослых пассажира.');
        return false;
      }
      if (childCount + babyCount < 1) {
        setError(
          'При выборе места с ребенком должен быть выбран хотя бы один пассажир: ребенок до 10 лет с местом или малыш до 5 лет без места',
        );
        return false;
      }
    }

    if (restSeats.forward.length > 0 || (restSeats.backward && restSeats.backward.length > 0)) {
      if (!['TwoPlacesAtOnce', 'FourPlacesAtOnce'].includes(PlaceReservationType)) {
        setError('Не все места выбраны');
        return false;
      }
    }

    setError('');
    return true;
  };

  const handleNext = () => {
    if (validateSelections()) {
      setPreFormInfo(preFormParam);
      console.log('preFormInfo', preFormParam);
    }
  };

  return (
    <Box marginTop={{ xs: 7.5, md: 15 }} marginBottom={{ xs: 2.5, md: 15 }} sx={{ padding: '0 20px' }}>
      <MainContainer direction="column" sx={{ marginBottom: { xs: 2.5, md: 7.5 } }}>
        <Typography fontSize={{ xs: 24, md: 48, lg: 70 }}>
          Укажите <strong>пассажиров</strong>
        </Typography>
        {['TwoPlacesAtOnce', 'FourPlacesAtOnce'].includes(PlaceReservationType) ? (
          <LuxuryComponent
            reservationType={PlaceReservationType}
            serviceClass={ServiceClass}
            formParam={preFormParam}
            setFormParam={setPreFormParam}
            places={availableSeats.forward.length}
            carrier={carrier}
            carType={carType}
          />
        ) : (
          <DefaultSelection
            preFormParam={preFormParam}
            restSeats={restSeats}
            categories={categories}
            removePassengerRow={removePassengerRow}
            addPassengerRow={addPassengerRow}
          />
        )}
      </MainContainer>
      <MainContainer sx={{ marginBottom: { xs: 2.5, md: 7.5 } }}>
        <Stack direction={'column'} spacing={3} width={'100%'}>
          {['TwoPlacesAtOnce', 'FourPlacesAtOnce'].includes(PlaceReservationType) && (
            <ErrorBox>
              <Typography fontSize={{ xs: 24, md: 40 }} mb={1}>
                Обратите внимание!
              </Typography>
              <Typography fontSize={{ xs: 16, md: 20 }}>
                Если количество пассажиров меньше, чем количество бронируемых мест, вы можете не распределять все места
                вручную — система сделает это автоматически.
              </Typography>
            </ErrorBox>
          )}
          {error && <ErrorBox>{error}</ErrorBox>}
          {carPlaceTypeMeaning.length > 0 ? (
            <ErrorBox>
              <Typography fontSize={{ xs: 24, md: 40 }} mb={1}>
                Обратите внимание!
              </Typography>
              {carPlaceTypeMeaning.map((item, index) =>
                item === 'MotherAndBaby' ? (
                  <Typography key={index} fontSize={{ xs: 16, md: 20 }}>
                    Должен быть выбран один пассажир с ребенком до 1 года (без занятия отдельного места)
                  </Typography>
                ) : item === 'WithChild' ? (
                  <Typography key={index} fontSize={{ xs: 16, md: 20 }}>
                    Должен быть хотя бы один взрослый пассажир с ребёнком до 10 лет и не более двух взрослых пассажиров
                  </Typography>
                ) : null,
              )}
            </ErrorBox>
          ) : null}
        </Stack>
      </MainContainer>
      <MainContainer direction="column">
        <Button
          variant="contained"
          sx={{ width: { xs: '100px', md: '206px' }, height: { xs: '45px', md: '60px' } }}
          onClick={handleNext}
        >
          Далее
        </Button>
      </MainContainer>
    </Box>
  );
};

export default PreFormComponent;
