import { Footer } from '../Components/Footer/Footer';
import { Header } from '../Components/Header/Header';
import { TrainInfo } from '../Components/Train/TrainInfo';
import { CarAndPlacesTable } from '../Components/ReservationPage/CarAndPlacesTable';

export const ConfirmRefundPage = () => {
  return (
    <>
      <Header />
      <div className="confirm-refund"></div>
      <Footer />
    </>
  );
};
