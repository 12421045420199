import { createTheme, StyledEngineProvider, ThemeProvider as MuiThemeProvider } from '@mui/material/styles';
import { getPalette } from './palette.theme';
import { typography } from './typography.theme';
import { shape } from './shape.theme';
import { getShadows } from './shadows.theme';
import { overrideComponents } from '../overrides/index';
import { CssBaseline } from '@mui/material';
import { globalStyles } from './GlobalStyles';

export const theme = createTheme({
  palette: getPalette('light'),
  typography,
  shape,
  shadows: getShadows('light'),
});

theme.components = overrideComponents(theme);

window.theme = theme;

export const ThemeProvider = props => {
  const { children } = props;

  return (
    <StyledEngineProvider injectFirst>
      <MuiThemeProvider theme={theme}>
        <CssBaseline />
        {globalStyles}
        {children}
      </MuiThemeProvider>
    </StyledEngineProvider>
  );
};
