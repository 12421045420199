import { useGetSet, useDebounce } from 'react-use';
import { SearchAutocompleteField } from '../SearchField/index.js';
import { defaultCities } from '../../shared/constants/location.constants.js';
import { useCities } from '../../shared/hooks/useCities.js';

const SearchFieldStyles = {
  '.MuiOutlinedInput-root': {
    height: '60px',
    backgroundColor: '#FFFFFF',
  },

  '& .MuiOutlinedInput-input': {
    paddingTop: '30px !important',
    paddingBottom: '8px !important',
    lineHeight: 1.2,
  },

  width: '100%',
};

export const SearchInput = props => {
  const { searchHintName, label = 'Поиск', onChange, value, sx, ...restOfProps } = props;

  const [getInputValue, setInputValue] = useGetSet('');
  const inputValue = getInputValue();

  const { data: cities, isFetching } = useCities({ city_name: inputValue });

  useDebounce(
    () => {
      setInputValue(inputValue);
    },
    500,
    [inputValue],
  );

  const handleChange = value => {
    onChange(value);
    setInputValue('');
  };

  const handleInputChange = (_, value) => {
    setInputValue(value);
  };

  const handlePaste = event => {
    event.preventDefault();

    const clipboardData = event.clipboardData.getData('text');

    setInputValue(clipboardData.trim());
  };

  const handleBlur = () => {
    if (inputValue.length >= 3 && cities) {
      onChange(cities[0]);
      setInputValue('');
    }
  };

  return (
    <SearchAutocompleteField
      sx={{ ...SearchFieldStyles, ...sx }}
      textFieldProps={{
        label,
      }}
      value={value}
      loading={isFetching}
      onPaste={handlePaste}
      onChange={handleChange}
      onBlur={handleBlur}
      onInputChange={handleInputChange}
      defaultOptions={defaultCities}
      options={cities}
      getOptionLabel={option => (typeof option === 'string' ? option : option.NameRu || '')}
      renderOption={(props, option) => <li {...props}>{option.NameRu}</li>}
      {...restOfProps}
    />
  );
};
