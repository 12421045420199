import { useState } from 'react';
import alterLogo from '../../Assets/alterLogo.png';
import { Button, Stack, Box, List, ListItem } from '@mui/material';
import { baseUrl } from '../../config';
import styled from 'styled-components';
import MainContainer from '../MainContainer/MainContainer';
import { Link } from 'react-router-dom';
import { PrivatePolicyModal } from './modals/PrivatePolicyModal';
import { PublicOfferModal } from './modals/PublicOfferModal';
import { RulesModal } from './modals/RulesModal';

const StyledListItem = styled(ListItem)`
  margin-bottom: 20px;
  padding: 0;
  cursor: pointer;
  a {
    color: #ffffff;
    text-decoration: none;
  }
`;

export const Footer = () => {
  const [modal, setOpenModal] = useState({
    'public-offer': false,
    'rules-of-registration': false,
    'privacy-policy': false,
  });

  const onCloseModalHandler = () => {
    setOpenModal({
      'public-offer': false,
      'rules-of-registration': false,
      'privacy-policy': false,
    });
  };

  return (
    <Box component="footer" sx={{ backgroundColor: '#000000', padding: '60px 30px' }}>
      <MainContainer
        sx={{
          flexDirection: { xs: 'column', md: 'row' },
          alignItems: { xs: 'center', md: 'flex-start' },
        }}
      >
        <Link to={'/'} sx={{ marginBottom: { xs: '20px', md: '0' } }}>
          <img src={alterLogo} alt="logo" />
        </Link>
        <Stack
          direction="row"
          sx={{ marginTop: { xs: '40px', md: '0' }, color: '#ffffff', fontSize: '16px', lineHeight: '120%' }}
        >
          <List sx={{ padding: 0, margin: 0 }}>
            <StyledListItem
              sx={{ justifyContent: { xs: 'center', md: 'left' }, textAlign: 'center' }}
              onClick={() => window.scrollTo(0, 0)}
            >
              Купить билет
            </StyledListItem>
            <StyledListItem
              sx={{ justifyContent: { xs: 'center', md: 'left' }, textAlign: 'center' }}
              onClick={() =>
                setOpenModal(prev => ({
                  ...prev,
                  'rules-of-registration': true,
                }))
              }
            >
              Правила оформления
            </StyledListItem>
            <StyledListItem sx={{ justifyContent: { xs: 'center', md: 'left' }, textAlign: 'center' }}>
              <a href="#">Оплата</a>
            </StyledListItem>
            <StyledListItem
              sx={{ justifyContent: { xs: 'center', md: 'left' }, textAlign: 'center' }}
              onClick={() =>
                setOpenModal(prev => ({
                  ...prev,
                  'privacy-policy': true,
                }))
              }
            >
              Политика конфиденциальности
            </StyledListItem>
            <StyledListItem
              sx={{ justifyContent: { xs: 'center', md: 'left' }, textAlign: 'center' }}
              onClick={() =>
                setOpenModal(prev => ({
                  ...prev,
                  'public-offer': true,
                }))
              }
            >
              Публичная оферта
            </StyledListItem>
          </List>
        </Stack>
        <Stack
          direction="column"
          spacing={4}
          sx={{ color: '#ffffff', fontSize: '20px' }}
          marginTop={{ xs: '40px', md: '0' }}
          textAlign={{ xs: 'center', md: 'left' }}
        >
          <List sx={{ listStyle: 'none', padding: 0, margin: 0 }}>
            <ListItem sx={{ marginBottom: '20px', padding: 0, justifyContent: { xs: 'center', md: 'left' } }}>
              Служба поддержки
            </ListItem>
            <ListItem
              sx={{ fontWeight: 'bold', fontSize: '20px', padding: 0, justifyContent: { xs: 'center', md: 'left' } }}
            >
              +7 926 737 7777
            </ListItem>
            <ListItem
              sx={{ fontWeight: 'bold', fontSize: '20px', padding: 0, justifyContent: { xs: 'center', md: 'left' } }}
            >
              zapad-77@mail.ru
            </ListItem>
          </List>
          <Button variant="outlined" size="large" color="primary" href={`${baseUrl}/pay/Railway/return_request`}>
            Возврат билетов
          </Button>
        </Stack>
      </MainContainer>
      <PrivatePolicyModal open={modal['privacy-policy']} onOpen={setOpenModal} onClose={onCloseModalHandler} />
      <PublicOfferModal open={modal['public-offer']} onOpen={setOpenModal} onClose={onCloseModalHandler} />
      <RulesModal open={modal['rules-of-registration']} onOpen={setOpenModal} onClose={onCloseModalHandler} />
    </Box>
  );
};
