import { useEffect, useLayoutEffect, useState } from 'react';
import { LiveSearch } from '../LiveSearch/LiveSearch';
import axios from 'axios';
import { baseUrl } from '../../config';
import { FormControl, MenuItem, Select, Stack, TextField } from '@mui/material';
import { FormElementTextField } from './form-controls/FormElementTextField';

export const DocumentElement = ({
  validationDocumentObjects,
  setValidatiomDocumentObjects,
  formId,
  category,
  countriesList,
  setCustomer,
  setCustomerHandler,
  customer,
  inputValues,
  carsInfo,
  formInputStyles,
}) => {
  const [citizenshipValue, setCitizenshipValue] = useState(''); //значение отображаемое в инпуте гражданства
  const [countries, setCountries] = useState(countriesList); //Список стран который будет отфильтрован
  const [inputLength, setInputLength] = useState(16);
  const [documentValue, setDocumentValue] = useState('');

  const allowedDocuments = carsInfo.forward.AllowedDocumentTypes;
  const isDocumentsIncludesRussianPass = allowedDocuments.AdultTypes.includes('RussianPassport');
  const isDocumentsIncludesBirthCert = allowedDocuments.ChildTypes.includes('BirthCertificate');

  const initialAdultDocument = isDocumentsIncludesRussianPass ? 'RussianPassport' : allowedDocuments.AdultTypes[0];
  const initialChildDocument = isDocumentsIncludesBirthCert ? 'BirthCertificate' : allowedDocuments.ChildTypes[0];

  const [documentType, setDocumentType] = useState(category === 'Adult' ? initialAdultDocument : initialChildDocument);

  const [documentValidation, setDocumentValidation] = useState({
    IsValid: true,
    message: '',
  });

  useEffect(() => {
    setValidatiomDocumentObjects({
      ...validationDocumentObjects,
      [formId]: documentValidation,
    });
  }, [documentValidation]);

  useLayoutEffect(() => {
    let timerId;

    if (documentValue) {
      // Устанавливаем таймер на 1 секунду после ввода каждого символа
      timerId = setTimeout(() => {
        const json = {
          documentNumber: documentValue,
          documentType: documentType, // Замените на ваш тип документа
        };

        axios
          .post(`${baseUrl}/api/info/PassengerDocumentNumber`, json, {
            headers: {
              'Content-Type': 'application/json',
            },
          })
          .then(response => setDocumentValidation(response.data))
          .catch(error => {
            console.error(error);
          });
      }, 500); // Задержка в 1 секунду
    }

    // Очищаем таймер при каждом изменении ввода
    return () => {
      clearTimeout(timerId);
    };
  }, [documentValue, documentType]);

  const setCitizenshipCodeHandler = value => {
    if (value === 'RU') {
      if (documentType === 'DiplomaticPassport' || documentType === 'ServicePassport') {
        setInputLength(9);
      }
    } else {
      setInputLength(20);
    }

    setCustomer({
      ...customer,
      ['CitizenshipCode']: value,
    });
  };

  useEffect(() => {
    switch (documentType) {
      case 'RussianPassport':
      case 'UssrPassport':
      case 'RussianForeignPassport':
      case 'SailorPassport':
      case 'MilitaryCard':
      case 'MilitaryOfficerCard':
      case 'LostPassportCertificate':
      case 'BirthCertificate':
      case 'PrisonReleaseCertificate':
      case 'MedicalBirthCertificate':
      case 'RussianTemporaryIdentityCard':
        setCitizenshipCodeHandler('RU');
        setCitizenshipValue('');
        break;
      case 'DiplomaticPassport':
      case 'ServicePassport':
        if (customer['CitizenshipCode'] === 'RU') {
          setInputLength(9);
        } else {
          setInputLength(20);
        }
        break;
    }
  }, [documentType]);

  const inputLengthSetter = document => {
    switch (document) {
      case 'RussianPassport':
      case 'UssrPassport':
      case 'RussianForeignPassport':
      case 'MilitaryCard':
      case 'MilitaryOfficerCard':
      case 'LostPassportCertificate':
      case 'PrisonReleaseCertificate':
      case 'RussianTemporaryIdentityCard':
      case 'ForeignPassport':
      case 'BirthCertificate':
      case 'DiplomaticPassport':
      case 'ServicePassport':
      case 'StatelessPersonIdentityCard':
      case 'ResidencePermit':
      case 'RefugeeCertificate':
      case 'RefugeeIdentity':
        setInputLength(20);
        break;
      case 'ReturnToCisCertificate':
        setInputLength(20);
        break;
      case 'SailorPassport':
        setInputLength(20);
        break;
      case 'MedicalBirthCertificate':
        setInputLength(20);
        break;
    }
  };

  const setDocumentTypeHandler = e => {
    inputLengthSetter(e.target.value);
    setCustomerHandler(e);
    setDocumentType(e.target.value);
  };

  const setDocumentCustomerHandler = e => {
    setCustomerHandler(e);
    setDocumentValue(e.target.value);
  };

  // Фильтрация стран при выборе гражданства

  const countriesFilter = value => {
    const regex = new RegExp(value.charAt(0).toUpperCase() + value.slice(1));
    const filteredCountries = countriesList.Countries.filter(country => regex.test(country.NameRu));

    setCountries(filteredCountries);
    if (value === '') {
      setCountries([]);
    }
  };

  const noSigns = event => {
    if (
      [
        '!',
        '@',
        '#',
        '$',
        '%',
        '^',
        '&',
        '*',
        '(',
        ')',
        '_',
        '+',
        '=',
        '?',
        '.',
        '?',
        '<',
        '>',
        ';',
        ':',
        '/',
        '[',
        ']',
        '|',
        '{',
        '}',
        '"',
        '`',
        ',',
        "'",
        ' ',
        '\\',
      ].indexOf(event.key) !== -1
    )
      event.preventDefault();
  };

  const documentTypeToRussian = documentType => {
    switch (documentType) {
      case 'RussianPassport':
        return 'Паспорт РФ (для лиц старше 14 лет)';
      case 'RussianForeignPassport':
        return 'Заграничный паспорт РФ';
      case 'BirthCertificate':
        return 'Свидетельство о рождении';
      case 'ForeignPassport':
        return 'Иностранный документ';
      case 'MilitaryCard':
        return 'Военный билет';
      case 'MilitaryOfficerCard':
        return 'Удостоверение личности для военнослужащих';
      case 'ReturnToCisCertificate':
        return 'Свидетельство на возвращение';
      case 'DiplomaticPassport':
        return 'Дипломатический паспорт';
      case 'ServicePassport':
        return 'Служебный паспорт';
      case 'SailorPassport':
        return 'Удостоверение личности моряка';
      case 'StatelessPersonIdentityCard':
        return 'Удостоверение личности лица без гражданства';
      case 'ResidencePermit':
        return 'Вид на жительство';
      case 'RussianTemporaryIdentityCard':
        return 'Временное удостоверение личности';
      case 'UssrPassport':
        return 'Паспорт СССР';
      case 'MedicalBirthCertificate':
        return 'Медицинское свидетельство о рождении (для детей не старше 1 мес.)';
      case 'LostPassportCertificate':
        return 'Справка об утере паспорта';
      case 'PrisonReleaseCertificate':
        return 'Справка об освобождении из мест лишения свободы';
      case 'RefugeeIdentity':
        return 'Удостоверение беженца';
      case 'RefugeeCertificate':
        return 'Свидетельство о предоставлении временного убежища на территории РФ (ЖД)';
      case 'Other':
        return 'Иные документы, выдаваемые органами МВД';
      case 'ConvictedPersonIdentity':
        return 'Документ, удостоверяющий личность осужденного';
      case 'AcknowledgmentOfIdentityOfAForeignCitizenOrStatelessPerson':
        return 'Заключение об установлении личности иностранного гражданина или лица без гражданства';
      case 'CertificateIssuedForAForeignCitizenGoingToDiplomaticOffice':
        return 'Справка для следования в дипломатическое представительство иностранного государства в Российской Федерации';
      case 'AnotherDocumentOfAForeignCitizenRecognizedInTheRussianFederation':
        return 'Иной документ, удостоверяющий личность иностранного гражданина и (или) лица без гражданства';
    }
  };

  return (
    <Stack sx={{ width: { xs: '100%', md: '360px' } }}>
      <FormControl fullWidth>
        <FormElementTextField
          select
          sx={{ ...formInputStyles, textWrap: 'auto' }}
          label="Документ"
          defaultValue={documentType}
          name={`DocumentType-${formId}`}
          onChange={e => setDocumentTypeHandler(e)}
          MenuProps={{
            PaperProps: {
              sx: {
                '& .MuiMenu-list': {
                  overflowX: 'auto',
                },
              },
            },
          }}
        >
          {category === 'Child' || category === 'BabyWithoutPlace'
            ? carsInfo.forward.AllowedDocumentTypes.ChildTypes.map((documentType, i) => (
                <MenuItem value={documentType} key={i} sx={{ color: 'black !important' }}>
                  {documentTypeToRussian(documentType)}
                </MenuItem>
              ))
            : carsInfo.forward.AllowedDocumentTypes.AdultTypes.map((documentType, i) => (
                <MenuItem value={documentType} key={i} sx={{ color: 'black !important' }}>
                  {documentTypeToRussian(documentType)}
                </MenuItem>
              ))}
        </FormElementTextField>
      </FormControl>
      {documentType === 'ForeignPassport' ||
      documentType === 'ReturnToCisCertificate' ||
      documentType === 'DiplomaticPassport' ||
      documentType === 'ServicePassport' ||
      documentType === 'ResidencePermit' ||
      documentType === 'StatelessPersonIdentityCard' ||
      documentType === 'ConvictedPersonIdentity' ||
      documentType === 'RefugeeCertificate' ||
      documentType === 'RefugeeIdentity' ||
      documentType === 'AcknowledgmentOfIdentityOfAForeignCitizenOrStatelessPerson' ||
      documentType === 'CertificateIssuedForAForeignCitizenGoingToDiplomaticOffice' ||
      documentType === 'AnotherDocumentOfAForeignCitizenRecognizedInTheRussianFederation' ||
      documentType === 'RussianTemporaryLivingCertificate' ? (
        <FormControl sx={{ marginTop: '20px' }}>
          <LiveSearch
            needPlaceholder={true}
            placeholder={'Введите название страны'}
            type={'Countries'}
            labelKey={'NameRu'}
            valueKey={'Alpha2Code'}
            value={citizenshipValue}
            onChange={setCitizenshipValue}
            onSelect={setCitizenshipCodeHandler}
            onSearch={countriesFilter}
            responseData={countries}
          />
        </FormControl>
      ) : (
        ''
      )}
      <FormControl sx={{ marginTop: '20px' }}>
        <FormElementTextField
          required={true}
          error={!documentValidation.IsValid}
          helperText={!documentValidation.isValid ? documentValidation.message : ''}
          name={`DocumentNumber-${formId}`}
          label="Номер документа"
          onChange={e => setDocumentCustomerHandler(e)}
          value={inputValues.DocumentNumber}
          onKeyDown={noSigns}
          maxLength={inputLength}
          sx={{ ...formInputStyles }}
        />
      </FormControl>
    </Stack>
  );
};
