import { CustomModal } from '../../../Components/CustomModal';

export const PrivatePolicyModal = props => (
  <CustomModal {...props}>
    <h2>Политика конфиденциальности</h2>
    <h4>Дата вступления в силу: 24 июля 2023 года</h4>
    <p>
      ООО "Мир" (далее – "Мы", "наша компания") ценит вашу доверительность и стремится обеспечить максимальную защиту
      ваших личных данных. Настоящая Политика конфиденциальности объясняет, как мы собираем, используем и защищаем
      информацию, которую вы предоставляете при использовании нашего приложения "Кокос" (далее – "Приложение").
    </p>
    <h4>Собираемая информация</h4>
    <p>1.1. При использовании Приложения мы можем собирать следующие типы информации:</p>
    <p>
      а) Информация, предоставленная вами: Это может включать ваше имя, адрес электронной почты, номер телефона, данные
      паспорта или иной идентификационный документ, необходимые для покупки железнодорожных билетов.
    </p>
    <p>
      б) Информация об устройстве: Мы можем автоматически собирать информацию об устройстве, которое вы используете для
      доступа к Приложению, такую как тип устройства, уникальные идентификаторы, версия операционной системы и другие
      технические данные.
    </p>
    <p>
      в) Информация о действиях в Приложении: Мы можем собирать информацию о ваших взаимодействиях с Приложением, такие
      как просмотр расписания, выбор маршрутов и совершение покупок.
    </p>
    <p>
      г) Данные локации: Если вы предоставляете разрешение на использование данных о местоположении, мы можем собирать
      информацию о вашем местоположении для предоставления более точной информации о доступных поездах и станциях.
    </p>
    <h4>Использование информации</h4>
    <p>2.1. Мы используем собранную информацию с целью:</p>
    <p>
      а) Осуществления покупки билетов: Ваши личные данные используются для обработки платежей и приобретения
      железнодорожных билетов в соответствии с вашими запросами.
    </p>
    <p>
      б) Улучшения Приложения: Мы анализируем собранные данные для повышения качества и удобства использования
      Приложения, а также для разработки новых функций и услуг.
    </p>
    <p>
      в) Контактов с вами: Мы можем использовать предоставленные вами контактные данные для отправки важных уведомлений,
      связанных с вашими покупками, а также для ответа на ваши запросы и обратной связи.
    </p>
    <p>
      г) Обеспечения безопасности: Мы применяем средства защиты информации для предотвращения несанкционированного
      доступа или использования данных.
    </p>
    <h4>Раскрытие информации третьим лицам</h4>
    <p>
      3.1. Мы не продаем, не арендуем и не раскрываем вашу личную информацию третьим лицам без вашего согласия, за
      исключением следующих случаев:
    </p>
    <p>
      а) Партнерам и поставщикам услуг: Мы можем передавать информацию партнерам и поставщикам услуг, которые помогают
      нам обрабатывать платежи, обеспечивать функционал Приложения и предоставлять техническую поддержку.
    </p>
    <p>
      б) Законные требования: Мы можем раскрывать информацию, если это необходимо в соответствии с применимыми законами,
      судебными решениями или в ответ на запросы правоохранительных органов.
    </p>
    <h4>Сроки хранения информации</h4>
    <p>
      4.1. Мы сохраняем вашу личную информацию только в течение периода, необходимого для достижения целей, указанных в
      настоящей Политике конфиденциальности, если иное не предусмотрено законодательством.
    </p>
    <h4>Ваши права</h4>

    <p>
      5.1. Вы имеете право запросить доступ к своей личной информации, а также внести изменения или удалить ее, если эта
      информация больше не необходима для целей, для которых она была собрана.
    </p>
    <h4>Согласие</h4>
    <p>
      6.1. Используя Приложение "Кокос", вы выражаете свое согласие с условиями настоящей Политики конфиденциальности.
    </p>

    <h4>Связь с нами</h4>
    <p>
      7.1. Если у вас возникли вопросы или запросы относительно нашей Политики конфиденциальности или обработки личных
      данных, пожалуйста, свяжитесь с нами по следующему адресу: zapad-77@mail.ru.
    </p>

    <p>
      Мы гарантируем, что ваша конфиденциальность важна для нас, и мы предпримем все необходимые меры для обеспечения
      безопасности и защиты ваших личных данных.
    </p>
    <p>С уважением,</p>
    <p>Генеральный директор ООО "Мир"</p>
    <p>Кожухов Роман Николаевич</p>
  </CustomModal>
);
