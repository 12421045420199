import { useState } from 'react';
import { Box, Button, Stack, TextField, Typography } from '@mui/material';
import MainContainer from '../../Components-2.0/MainContainer/MainContainer';

const formInputStyles = {
  color: 'black !important',

  '& .MuiInputBase-root': {
    height: '100%',
  },
  '& .MuiInputBase-root:hover': {
    borderColor: 'rgb(255, 117, 23)',
  },
  '& .MuiInputBase-root.Mui-focused': {
    border: '1px solid rgb(255, 117, 23)',
  },
  '& .MuiInputBase-input': {
    color: 'black',
  },
  '& .MuiInputLabel-root': {
    backgroundColor: 'white',
  },
  '& .MuiInputLabel-root.Mui-focused': {
    color: 'rgb(255, 117, 23);',
    backgroundColor: 'white',
  },
};

const buttonStyles = {
  width: { xs: '100%', md: '208px' },
  height: { xs: '45px', md: '60px' },
};

export const RefundPage = () => {
  const [isRefundSuccess, setIsRefundSuccess] = useState(true);

  return (
    <Box padding={'0px 20px'} marginBottom={{ xs: 7, md: 10, lg: 15 }}>
      {/* Форма возврата */}

      <MainContainer direction={'column'}>
        <Typography
          sx={{
            width: '100%',
            marginTop: '20px',
            marginBottom: { xs: '30px', md: '60px' },
            fontSize: { xs: '20px', sm: '35px', md: '50px', lg: '70px' },
            textAlign: { xs: 'center', md: 'left' },
          }}
        >
          <strong>Возврат</strong> билетов
        </Typography>

        {/* Получение билета  */}

        <Stack width={'100%'}>
          <Stack
            direction={{ xs: 'column', md: 'row' }}
            alignItems={'left'}
            spacing={{ xs: '10px', md: '20px' }}
            marginBottom={{ xs: '20px', md: '60px' }}
            width={'100%'}
          >
            <TextField
              required
              type="text"
              label="Номер электронного билета"
              sx={{ ...formInputStyles, width: { xs: '100%', md: '360px' } }}
            />
            <TextField
              required
              type="text"
              label="Номер документа"
              sx={{ ...formInputStyles, width: { xs: '100%', md: '360px' } }}
            />
          </Stack>
          <Stack
            direction={{ xs: 'column', md: 'row' }}
            alignItems={'left'}
            spacing={{ xs: '10px', md: '20px' }}
            width={'100%'}
          >
            <Button variant="outlined" sx={{ ...buttonStyles }}>
              На главную
            </Button>
            <Button variant="contained" sx={{ ...buttonStyles }}>
              Далее
            </Button>
          </Stack>
        </Stack>

        {/* Подтверждение возврата */}

        {/* <Stack width={'100%'}>
          <Stack
            direction={{ xs: 'column', md: 'row' }}
            alignItems={'left'}
            spacing={{ xs: '10px', md: '20px' }}
            marginBottom={{ xs: '20px', md: '60px' }}
            width={'100%'}
          >
            <Stack direction={'column'} spacing={{ xs: '10px', md: '27px' }}>
              <Stack>
                <Typography color="text.secondary" fontSize={{ xs: '12px', md: '16px' }}>
                  Билет
                </Typography>
                <Typography fontSize={{ xs: '16px', md: '20px' }} fontWeight={'bold'}>
                  № 70750962501293
                </Typography>
              </Stack>
              <Stack>
                <Typography color="text.secondary" fontSize={{ xs: '12px', md: '16px' }}>
                  Пассажиры
                </Typography>
                <Typography fontSize={{ xs: '16px', md: '20px' }} fontWeight={'bold'}>
                  Александров Александр Александрович
                </Typography>
              </Stack>
              <Stack>
                <Typography color="text.secondary" fontSize={{ xs: '12px', md: '16px' }}>
                  Общая стоимость на момент покупки
                </Typography>
                <Typography fontSize={{ xs: '16px', md: '20px' }} fontWeight={'bold'}>
                  4 613.2р.
                </Typography>
              </Stack>
              <Stack>
                <Typography color="text.secondary" fontSize={{ xs: '12px', md: '16px' }}>
                  Удержаны
                </Typography>
                <Typography fontSize={{ xs: '16px', md: '20px' }} fontWeight={'bold'}>
                  356.6р.
                </Typography>
              </Stack>
              <Stack>
                <Typography color="text.secondary" fontSize={{ xs: '12px', md: '16px' }}>
                  Сумма возврата
                </Typography>
                <Typography fontSize={{ xs: '16px', md: '20px' }} fontWeight={'bold'}>
                  3 956.6р.
                </Typography>
              </Stack>
              <Stack>
                <Typography color="text.secondary" fontSize={{ xs: '12px', md: '16px' }}>
                  Возврат сервисного сбора
                </Typography>
                <Typography fontSize={{ xs: '16px', md: '20px' }} fontWeight={'bold'}>
                  300р.
                </Typography>
              </Stack>
            </Stack>
          </Stack>
          <Stack
            direction={{ xs: 'column', md: 'row' }}
            alignItems={'left'}
            spacing={{ xs: '10px', md: '20px' }}
            width={'100%'}
          >
            <Button variant="outlined" sx={{ ...buttonStyles }}>
              На главную
            </Button>
            <Button variant="outlined" sx={{ ...buttonStyles }}>
              Изменить данные
            </Button>
            <Button variant="contained" sx={{ ...buttonStyles }}>
              Далее
            </Button>
          </Stack>
        </Stack> */}
      </MainContainer>

      {/* Статус возврата */}
      {/* <MainContainer direction={'column'}>
        <Typography
          sx={{
            width: '100%',
            marginTop: { xs: '20px', md: '160px' },
            fontSize: { xs: '24px', sm: '35px', md: '50px', lg: '70px' },
            textAlign: 'center',
          }}
        >
          {isRefundSuccess ? (
            <>
              Возврат прошел <strong>успешно</strong>
            </>
          ) : (
            <>
              Кажется <strong>что-то пошло не так</strong>
            </>
          )}
        </Typography>
        <Typography
          sx={{
            width: '100%',
            marginTop: '10px',
            marginBottom: { xs: '30px', md: '60px' },
            fontSize: { xs: '16px', md: '20px' },
            textAlign: 'center',
          }}
        >
          {isRefundSuccess ? (
            <>Деньги будут зачислены к Вам на счёт в ближайшее время</>
          ) : (
            <>Проверьте данные или совершите попытку позже</>
          )}
        </Typography>
        <Stack alignItems={'center'} width={'100%'}>
          <Button variant="contained" sx={{ ...buttonStyles }}>
            На главную
          </Button>
        </Stack>
      </MainContainer> */}
    </Box>
  );
};
