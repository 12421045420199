import { useEffect, useState } from 'react';
import { Form } from 'react-bootstrap';
import { FormElement } from './FormElement';
import axios from 'axios';
import { baseUrl } from '../../config';
import {
  $peakedPlacesReturnStore,
  $peakedPlacesToStore,
  $reservationItemsForwardBackwardStore,
  setErrorMessage,
} from '../../Services/trainsService';
import { prepareInfoForReservation } from '../../Services/createReservationService';
import '../../Styles/Pages/PassengerFormPage.scss';
import { useStore } from 'effector-react';
import { useNavigate } from 'react-router-dom';
import { CustomModal } from '../CustomModal/CustomModal';
import { Box, Button, Stack, Typography } from '@mui/material';
import MainContainer from '../../Components-2.0/MainContainer/MainContainer';
import { PuffLoader } from 'react-spinners';
import { FioTransliterationDialog } from './dialogs/FioTransliterationDialog';
import { TransliterationRulesCard } from './TransliterationRulesCard';

const FormComponent = ({ preformInfo, placeReservationType }) => {
  const carsInfo = useStore($reservationItemsForwardBackwardStore);
  const placesTo = useStore($peakedPlacesToStore);
  const placesReturn = useStore($peakedPlacesReturnStore);
  const navigate = useNavigate();
  const [isLoadingSubmit, setIsLoadingSubmit] = useState(false);

  const [countriesList, setCountries] = useState([]);

  const [reservationDto, setReservationDto] = useState({
    Customers: {},
    Passengers: {},
    Buyer: 0,
  });

  const [validationDocumentObjects, setValidationDocumentObject] = useState({});

  const [modal, setModal] = useState(false);
  const [modalMessage, setModalMessage] = useState('');

  const checkAllDocumentsIsValid = () => {
    const documentsArray = Object.keys(validationDocumentObjects).map(key => validationDocumentObjects[key]);

    return documentsArray.some(item => item.IsValid === false);
  };

  //ФУНКЦИЯ ДЛЯ ПОЛУЧЕНИЯ СПИСКА СТРАН
  const getCountries = () => {
    try {
      axios.get(`${baseUrl}/api/info/get_countries`).then(response => {
        setCountries(response.data);
      });
    } catch (error) {
      console.log('Ошибка при получении списка стран', error);
    }
  };

  useEffect(() => {
    getCountries();
  }, []);

  const makeQueryAndGoToNextPage = () => {
    const res = prepareInfoForReservation(reservationDto, placesTo, placesReturn, carsInfo);

    const json = JSON.stringify(res);

    try {
      setIsLoadingSubmit(true);
      axios
        .post(`${baseUrl}/api/Railway/create_reservation`, json, {
          headers: {
            'Content-Type': 'application/json',
          },
        })
        .then(response => {
          setIsLoadingSubmit(false);
          navigate('/reservationPage', {
            state: {
              responseReservation: response.data,
            },
          });
        })
        .catch(error => {
          setIsLoadingSubmit(false);
          setErrorMessage(error);
        });
    } catch (error) {
      throw error;
    }
  };

  const onCloseModal = async () => {
    if (placeReservationType === 'FourPlacesAtOnce' || placeReservationType === 'TwoPlacesAtOnce') {
      setModal(false);
    } else {
      await setModal(false);
      makeQueryAndGoToNextPage();
    }
  };

  function onSubmitHandler(event) {
    event.preventDefault();

    const adultAmountCheck = () => {
      const passengersArray = Object.entries(reservationDto.Passengers).map(([key, value]) => value);
      const checkedPassengersArray = passengersArray.filter(item => item.Category === 'Adult');

      return checkedPassengersArray.length;
    };

    const adultAmount = adultAmountCheck();

    if (adultAmount === 0) {
      setModalMessage('Проезд детей до 10 лет без сопровождения взрослого не допускается');
      setModal(true);
    } else {
      makeQueryAndGoToNextPage();
    }
  }

  //------------------------------------------------------------------------
  const [isTransliterationDialogOpen, setIsTransliterationDialogOpen] = useState(false);

  const openTransliterationDialog = () => setIsTransliterationDialogOpen(true);
  const closeTransliterationDialog = () => setIsTransliterationDialogOpen(false);

  //------------------------------------------------------------------------

  return (
    <Box marginTop={{ xs: 3, md: 9 }} marginBottom={{ xs: 7.5, md: 15 }} sx={{ padding: '0 20px' }}>
      <Box className={isLoadingSubmit ? 'overlayActive' : 'overlayNone'}>
        <Box
          style={{ maxHeight: '100%', minHeight: '100vh' }}
          className="d-flex justify-content-center align-items-center"
        >
          <PuffLoader color={'#000000'} loading={isLoadingSubmit} size={300} />
        </Box>
      </Box>
      <MainContainer direction="column" sx={{ marginBottom: { xs: 2.5, md: 7.5 } }}>
        <Typography fontSize={{ xs: 24, md: 48, lg: 68 }} textAlign={'center'}>
          Заполните <strong>данные пассажиров</strong>
        </Typography>
        <Typography fontSize={{ xs: 16, md: 18, lg: 20 }} textAlign={'center'}>
          Обратите внимание, при транслитерации ФИО на латиницу следует соблюдать{' '}
          <Typography
            onClick={openTransliterationDialog}
            variant="caption"
            sx={{ fontSize: { xs: 16, md: 18, lg: 20 }, color: 'rgb(255, 117, 23)', cursor: 'pointer' }}
          >
            Правила транслитерации.
          </Typography>
          <FioTransliterationDialog isOpen={isTransliterationDialogOpen} onClose={closeTransliterationDialog} />
        </Typography>
        <CustomModal open={modal} onOpen={setModal} onClose={() => onCloseModal()}>
          <h2 style={{ color: 'red' }}>Внимание!</h2>
          <h4>{modalMessage}</h4>
        </CustomModal>
        <Form onSubmit={onSubmitHandler} style={{ width: '100%' }}>
          {preformInfo.map((formElement, i) => {
            let discounts = { forward: null, backward: null };

            if (formElement.forward === null) {
              discounts.forward = placesTo[0].Discounts;

              if (placesReturn.length !== 0) {
                discounts.backward = placesReturn[0].Discounts;
              }
            } else {
              placesTo.forEach((item, index) => {
                if (item.Place === formElement.forward) {
                  discounts.forward = item.Discounts;
                }
              });
              if (placesReturn.length !== 0) {
                placesReturn.forEach((item, index) => {
                  if (item.Place === formElement.backward) {
                    discounts.backward = item.Discounts;
                  }
                });
              }
            }
            return (
              <Box>
                <FormElement
                  validationDocumentObjects={validationDocumentObjects}
                  setValidationDocumentObjects={setValidationDocumentObject}
                  preformInfo={preformInfo}
                  discounts={discounts}
                  countriesList={countriesList}
                  reservationDto={reservationDto}
                  setReservationDto={setReservationDto}
                  formElement={formElement}
                  amountOfElements={preformInfo.length}
                  formId={i}
                  carsInfo={carsInfo}
                  key={i}
                />
              </Box>
            );
          })}
          <Stack direction={'rov'} justifyContent={{ xs: 'center', md: 'left' }} marginTop={{ xs: '30px', md: '40px' }}>
            <Button
              disabled={isLoadingSubmit || checkAllDocumentsIsValid()}
              type="submit"
              variant="contained"
              sx={{
                width: { xs: '100%', sm: '208px' },
                height: { xs: '45px', md: '60px' },
              }}
            >
              Забронировать
            </Button>
          </Stack>
        </Form>
      </MainContainer>
    </Box>
  );
};

export default FormComponent;
