import { Box, Button, Stack, styled, Typography } from '@mui/material';

const InputButton = styled(Button)`
  min-width: 32px;
  width: 32px;
  height: 32px;
  border-radius: 10px;
  background-color: white;
`;

export const DefaultSelection = ({ preFormParam, restSeats, categories, removePassengerRow, addPassengerRow }) => {
  const counts = {
    Adult: preFormParam.filter(item => item.category === 'Adult').length,
    Child: preFormParam.filter(item => item.category === 'Child').length,
    BabyWithoutPlace: preFormParam.filter(item => item.category === 'BabyWithoutPlace').length,
  };

  return (
    <Box width={'100%'}>
      <Typography marginBottom={{ xs: 2.5, md: 7.5 }} textAlign={'center'}>
        Оставшееся количество мест: <strong>{restSeats.forward.length}</strong>
      </Typography>
      <Stack direction={{ xs: 'column', md: 'row' }} width={'100%'} justifyContent={'space-between'} spacing={2}>
        {[
          { key: 'Adult', label: categories['Adult'] },
          { key: 'Child', label: categories['Child'] },
          { key: 'BabyWithoutPlace', label: categories['BabyWithoutPlace'] },
        ].map(({ key, label }) => (
          <Stack
            key={key}
            direction={'row'}
            spacing={3}
            sx={{
              width: { xs: '100%', md: '360px' },
              justifyContent: 'space-between',
              height: { xs: '80px', md: '126px' },
              padding: { xs: '20px', md: '30px' },
              borderRadius: '20px',
              alignItems: 'center',
              backgroundColor: '#FFFAF2',
            }}
          >
            <Typography fontSize={{ xs: 14, md: 16 }}>{label}</Typography>
            <Stack direction={'row'} spacing={1} alignItems={'center'}>
              <InputButton onClick={() => removePassengerRow(key)} disabled={counts[key] === 0}>
                -
              </InputButton>
              <Typography fontSize={16} color="#6E7491">
                {counts[key]}
              </Typography>
              <InputButton
                onClick={() => {
                  if ((key === 'Adult' || key === 'Child') && restSeats.forward.length === 0) return;
                  addPassengerRow(key);
                }}
                disabled={
                  key === 'Adult' || key === 'Child'
                    ? restSeats.forward.length === 0
                    : key === 'BabyWithoutPlace' &&
                      preFormParam.filter(item => item.category === 'BabyWithoutPlace').length >=
                        preFormParam.filter(item => item.category === 'Adult').length
                }
              >
                +
              </InputButton>
            </Stack>
          </Stack>
        ))}
      </Stack>
    </Box>
  );
};
