import { CustomModal } from '../../../Components/CustomModal';

export const RulesModal = props => (
  <CustomModal {...props}>
    <h2>Правила оформления заказа на покупку электронных билетов</h2>
    <p>
      Оформление электронных билетов производится не позднее чем за 1 час до отправления поезда со станции посадки
      пассажира. На поезда дальнего следования, оборудованные устройствами контроля легитимности электронных билетов,
      следующие во внутригосударственном сообщении, включая прицепные и беспересадочные вагоны, и в международном
      сообщении, за исключением перевозок в государства-участников Содружества Независимых Государств, Латвийской
      Республики, Литовской Республики, Эстонской Республики за 15 минут до отправления пассажира со станции его
      посадки.
    </p>
    <p>Продажа электронных билетов осуществляется на поезда, следующие:</p>
    <ul>
      <li>
        во внутреннем сообщении по территории Российской Федерации (формирования ОАО «РЖД», АО «ФПК», ОАО «ПКС», ЗАО ТК
        «Гранд Сервис Экспресс», ООО «Тверской экспресс», ЗАО «ТКС») за исключением поездов, следующих в (из)
        Калининградскую область транзитом через территорию Литовской Республики;
      </li>
      <li>
        в международном сообщении со странами СНГ (Азербайджанская Республика, Республика Казахстан, Кыргызская
        Республика, Республика Беларусь, Украина, Республика Таджикистан, Туркменистан, Республика Узбекистан,
        Республика Молдова), Латвийской Республикой, Литовской Республикой, Эстонской Республикой, Республикой Абхазии;
      </li>
      <li>
        в международном сообщении (дальнее зарубежье) на поезда:
        <ul>
          <li>№9/10 Москва – Варшава – Москва;</li>
          <li>№13/14 Москва – Берлин – Москва;</li>
          <li>№17/18 Москва – Ницца – Москва;</li>
          <li>№21/22 Москва – Прага – Москва;</li>
          <li>№23/24 Москва – Париж – Москва;</li>
          <li>
            №32/31 Москва – Хельсинки – Москва и скоростные поезда "Аллегро" СанктПетербург – Хельсинки –
            Санкт-Петербург.
          </li>
        </ul>
      </li>
    </ul>
    <p>
      При оформлении электронных билетов (ЭБ) в предлагаемые поля последовательно вносятся реквизиты поездки и
      персональные данные каждого пассажира. За правильность указания реквизитов поездки и персональных данных каждого
      пассажира полную ответственность несёт пользователь, оформляющий заказ.
    </p>
    <p>Электронные билеты, при наличии свободных мест в системе АСУ «Экспресс», можно приобрести:</p>
    <ul>
      <li>
        на все поезда и прицепные вагоны, курсирующие во внутригосударственном сообщении, продажа билетов открыта за 90
        суток (на ряд поездов за 60 или 45) до даты отправления поезда;
      </li>
      <li>
        на все поезда и прицепные вагоны, курсирующие в международном сообщении (дальнее зарубежье Германия, Франция,
        Финляндия и т.д.), продажа билетов открыта за 60 суток до даты отправления поезда;
      </li>
      <li>
        в международном сообщении со странами СНГ (Азербайджанская Республика, Республика Казахстан, Кыргызская
        Республика, Республика Беларусь, Украина, Республика Таджикистан, Туркменистан, Республика Узбекистан,
        Республика Молдова, Латвийской Республикой, Литовской Республикой, Эстонской Республикой, Республикой Абхазии за
        45 суток (на ряд поездов за 60) до даты отправления поезда (за 30 суток на поезда из Молдовы, Украины).
      </li>
    </ul>
    <p>В настоящем документе представлены:</p>
    <ul>
      <li>
        список поездов формирования Украинских железных дорог, продажа билетов на которые осуществляется за 45 суток до
        отправления (Таблица 1);
      </li>
      <li>
        список поездов в сообщении с Молдавией, на которые продажа билетов осуществляется за 45 суток до отправления
        (Таблица 2);
      </li>
      <li>
        список поездов в сообщении со странами СНГ и Балтии продажа билетов, на которые осуществляется за 60 суток до
        отправления (Таблица 3);
      </li>
      <li>
        Время открытия продажи билетов. Продажа начинается в то же время, что и в кассах региона отправления поезда
        (Таблица 4).
      </li>
    </ul>
    <p>
      Таблица 1 – Список поездов формирования Украинских железных дорог, продажа билетов на которые осуществляется за 45
      суток до отправления
    </p>
    <table>
      <thead>
        <tr>
          <th>№№ поездов</th>
          <th>Маршрут</th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td>6/5</td>
          <td>Киев – Москва</td>
        </tr>
        <tr>
          <td>20/19</td>
          <td>Харьков – Москва</td>
        </tr>
        <tr>
          <td>23/24</td>
          <td>Одесса – Москва</td>
        </tr>
        <tr>
          <td>54/144 – 143/53</td>
          <td>Киев, Харьков – Санкт-Петербург</td>
        </tr>
        <tr>
          <td>56/90 – 89/55</td>
          <td>Хмельницкий, Жмеринка – Москва</td>
        </tr>
        <tr>
          <td>61/62 </td>
          <td>Николаев – Москва</td>
        </tr>
        <tr>
          <td>74/78 – 77/73</td>
          <td>Львов, Ковель – Москва</td>
        </tr>
        <tr>
          <td>74/106 – 105/73 </td>
          <td>Кривой Рог, Днепропетровск – Москва</td>
        </tr>
        <tr>
          <td>370/369 </td>
          <td>Баку – Харьков</td>
        </tr>
      </tbody>
    </table>
    <p>
      Таблица 2 – Список поездов в сообщении с Молдавией, на которые продажа билетов осуществляется за 45 суток до
      отправления
    </p>
    <table>
      <thead>
        <tr>
          <th>№№ поездов</th>
          <th>Маршрут</th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td>47/48</td>
          <td>Кишинев – Москва</td>
        </tr>
        <tr>
          <td>65/66</td>
          <td>Москва – Кишинев</td>
        </tr>
        <tr>
          <td>61/62 </td>
          <td>Кишинев – Санкт-Петербург</td>
        </tr>
        <tr>
          <td>341/342 </td>
          <td>Кишинев – Москва</td>
        </tr>
      </tbody>
    </table>
    <p>
      Таблица 3 – Список поездов в сообщении со странами СНГ и Балтии продажа билетов, на которые осуществляется за 60
      суток до отправления:
    </p>
    <table>
      <thead>
        <tr>
          <th>№№ поездов</th>
          <th>Маршрут</th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td>26/25</td>
          <td> Минск – Москва</td>
        </tr>
        <tr>
          <td>28/27</td>
          <td> Брест – Москва</td>
        </tr>
        <tr>
          <td>50/49</td>
          <td> Брест – Санкт-Петербург</td>
        </tr>
        <tr>
          <td>64/63 </td>
          <td>Минск – Новосибирск</td>
        </tr>
        <tr>
          <td>66/65</td>
          <td> Минск – Мурманск</td>
        </tr>
        <tr>
          <td>68/67</td>
          <td> Брест – Саратов</td>
        </tr>
        <tr>
          <td>78/77</td>
          <td> Гродно – Москва</td>
        </tr>
        <tr>
          <td>96/95</td>
          <td> Брест – Москва</td>
        </tr>
        <tr>
          <td>104/103</td>
          <td> Брест – Новосибирск</td>
        </tr>
        <tr>
          <td>132/131</td>
          <td> Брест – Москва</td>
        </tr>
        <tr>
          <td>134/133</td>
          <td> Минск – Архангельск</td>
        </tr>
        <tr>
          <td>1/2</td>
          <td> Москва – Минск</td>
        </tr>
        <tr>
          <td>3/4</td>
          <td> Москва – Брест</td>
        </tr>
        <tr>
          <td>7/8</td>
          <td> Москва – Брест</td>
        </tr>
        <tr>
          <td>29/30</td>
          <td> (прицепная группа) Москва – Вильнюс</td>
        </tr>
        <tr>
          <td>33/34</td>
          <td> Москва – Таллин</td>
        </tr>
        <tr>
          <td>39/40</td>
          <td> Москва – Полоцк</td>
        </tr>
        <tr>
          <td>51/52</td>
          <td> Минск – Санкт-Петербург</td>
        </tr>
        <tr>
          <td>55/56</td>
          <td> Москва – Гомель</td>
        </tr>
        <tr>
          <td>2/1; 38/37</td>
          <td> Рига – Москва – Санкт-Петербург</td>
        </tr>
        <tr>
          <td>83/84</td>
          <td> Гомель – Санкт-Петербург</td>
        </tr>
        <tr>
          <td>306/305</td>
          <td> Москва – Сухум (с даты продажи билетов 05.06.2017)</td>
        </tr>
        <tr>
          <td>479/480</td>
          <td>Санкт-Петербург – Сухум (с даты продажи билетов 05.06.2017)</td>
        </tr>
        <tr>
          <td>487/488</td>
          <td> Самара – Сухум (с даты продажи билетов 05.06.2017)</td>
        </tr>
        <tr>
          <td>547/548</td>
          <td> Москва – Сухум (с даты продажи билетов 05.06.2017)</td>
        </tr>
        <tr>
          <td>579/580</td>
          <td>Белгород – Сухум (с даты продажи билетов 05.06.2017)</td>
        </tr>
      </tbody>
    </table>
    <p>
      Таблица 4 – Время открытия продажи билетов. Продажа начинается в то же время, что и в кассах региона отправления
      поезда.
    </p>
    <table>
      <thead>
        <tr>
          <th>Регион отправления поезда (Ж.Д. дороги)</th>
          <th>Открытие предварит. продажи на сайте (время моск.)</th>
          <th>Открытие предварит. продажи на сайте (время местное )</th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td>Московская ж.д.</td>
          <td> 08:00</td>
          <td> 08:00</td>
        </tr>
        <tr>
          <td>Самарская область</td>
          <td> 08:00</td>
          <td> 09:00</td>
        </tr>
        <tr>
          <td>Ульяновская область </td>
          <td>08:00</td>
          <td> 09:00</td>
        </tr>
        <tr>
          <td>Башкирия (республика) </td>
          <td>08:00</td>
          <td> 10:00</td>
        </tr>
        <tr>
          <td>Горьковская ж.д </td>
          <td>08:00</td>
          <td> 08:00</td>
        </tr>
        <tr>
          <td>Удмуртская республика </td>
          <td>08:00</td>
          <td> 09:00</td>
        </tr>
        <tr>
          <td>Приволжская ж.д. </td>
          <td>08:00 </td>
          <td>08:00</td>
        </tr>
        <tr>
          <td>Саратовская область </td>
          <td>08:00 </td>
          <td>09:00</td>
        </tr>
        <tr>
          <td>Астраханская обл. </td>
          <td>08:00</td>
          <td> 09:00</td>
        </tr>
        <tr>
          <td>Северо-Кавказская ж.д. </td>
          <td>08:00</td>
          <td> 08:00</td>
        </tr>
        <tr>
          <td>Юго-Восточная ж.д. </td>
          <td>08:00</td>
          <td> 08:00</td>
        </tr>
        <tr>
          <td>Октябрьская ж.д. </td>
          <td>08:00 </td>
          <td>08:00</td>
        </tr>
        <tr>
          <td>Калининградская ж.д. </td>
          <td>08:00</td>
          <td>07:00</td>
        </tr>
        <tr>
          <td>Северная </td>
          <td>08:00</td>
          <td> 08:00</td>
        </tr>
        <tr>
          <td>Харп и Лабытнанги</td>
          <td> 08:00</td>
          <td>10:00</td>
        </tr>
        <tr>
          <td>Свердловская ж.д </td>
          <td>06:00</td>
          <td> 08:00</td>
        </tr>
        <tr>
          <td>Оренбургская обл. </td>
          <td>06:00</td>
          <td> 08:00</td>
        </tr>
        <tr>
          <td>Челябинская обл. </td>
          <td>06:00</td>
          <td> 08:00</td>
        </tr>
        <tr>
          <td>Южно-Уральская ж.д.</td>
          <td> 06:00</td>
          <td> 08:00</td>
        </tr>
        <tr>
          <td>Восточно-Сибирская ж.д. </td>
          <td>04:00 </td>
          <td>09:00</td>
        </tr>
        <tr>
          <td>Новосибирская обл. </td>
          <td>04:00</td>
          <td> 08:00</td>
        </tr>
        <tr>
          <td>Омская обл.</td>
          <td> 04:00</td>
          <td> 07:00</td>
        </tr>
        <tr>
          <td>Томская обл. </td>
          <td>04:00</td>
          <td> 08:00</td>
        </tr>
        <tr>
          <td>Алтайский край </td>
          <td>04:00</td>
          <td> 08:00</td>
        </tr>
        <tr>
          <td>Республика Алтай </td>
          <td>04:00</td>
          <td> 08:00</td>
        </tr>
        <tr>
          <td>Кемеровская обл. </td>
          <td>04:00</td>
          <td> 08:00</td>
        </tr>
        <tr>
          <td>Красноярская ж.д. </td>
          <td>04:00</td>
          <td> 08:00</td>
        </tr>
        <tr>
          <td>Забайкальская ж.д.</td>
          <td> 04:00</td>
          <td> 11:00</td>
        </tr>
        <tr>
          <td>Дальневосточная ж.д.</td>
          <td> 04:00</td>
          <td> 11:00</td>
        </tr>
        <tr>
          <td>Тындинский регион </td>
          <td>04:00</td>
          <td> 10:00</td>
        </tr>
        <tr>
          <td>Сахалинская ж.д.</td>
          <td> 04:00</td>
          <td> 12:00</td>
        </tr>
      </tbody>
    </table>
    <p>
      Продажа мест на поезда, курсирующие из Финляндии в Россию, открывается в системе резервирования железных дорог
      Финляндии в 04:00 по московскому времени.
    </p>
    <p>
      Продажа билетов на поезда из Белоруссии, Литвы, Латвии, Эстонии, Таджикистана, Узбекистана, Украины, Молдовы
      начинается в 08:00 по московскому времени.
    </p>
    <p>
      После перехода Европы на зимнее время продажа билетов на поезда из Литвы, Латвии, Эстонии, Украины, Молдовы будет
      открываться в 09:00 по московскому времени.
    </p>
    <p>
      Продажа со станций Казахстана (кроме Петропавловска) и Киргизии начинается в 07:00 по местному или в 04:00 по
      московскому времени.
    </p>
    <p>Продажа билетов на поезда из Абхазии начинается в 08:00 по московскому времени.</p>
    <p>
      Электронные билеты (ЭБ) оформляются по полному, детскому тарифам на перевозку взрослых, детей, а также безденежные
      для проезда детей без занятия отдельного места в возрасте, указанном в разделе 5.
    </p>
    <p>
      В одном заказе может быть оформлено не более 4 электронных билетов с указанием персональных данных 4-х пассажиров.
    </p>
    <p>
      Во избежание недоразумений и предотвращения опоздания на поезд рекомендуем, при оформлении заказов в поезда по
      маршруту с пересадками, выбирать интервал между прибытием и отправлением не менее 3 часов.
    </p>
    <p>
      Оформление электронных билетов не производится в период технологического перерыва системы АСУ «Экспресс-3» с 3 ч.
      30 мин. до 4 ч. 00 мин. по московскому времени.
    </p>

    <h2>ОСОБЕННОСТИ ОФОРМЛЕНИЯ ЗАКАЗА НА ПОКУПКУ ЭЛЕКТРОННЫХ БИЛЕТОВ</h2>
    <p>
      Места в разных типах вагонов оформляются разными заказами, в том числе с указанием мужских и женских мест в
      купейных вагонах и вагонах СВ (в одном заказе может быть указан только один из вариантов  мужские, женские или
      смешанные). При наличии мест только в одном типе купе («мужском», «женском» или «смешанном») выбор мест необходимо
      производить в соответствующем типе купе. Во избежание конфликтных ситуаций в поезде, выбор мест необходимо
      производить в купе, признак которого соответствует полу пассажира («мужское» или «женское»).
    </p>
    <p>
      При оформлении заказа на проезд в VIP – вагоне (категория «ЛЮКС» с классом обслуживания «1А», «1М», «1И» и
      категории СВ с классом обслуживания «1Е») электронный билет выдаётся с оплатой целого купе, в котором могут
      проехать один или два пассажира. В случае оформления проезда в одном купе двух пассажиров должны быть указаны
      персональные данные каждого пассажира. В вагоне категории «ЛЮКС» класс обслуживания «1А», «1М», «1И» тариф
      «Детский» не применяется. Дополнительно разрешено оформление безденежного проездного документа ребенку до 10 лет
      без занятия отдельного спального места (третий в купе).
    </p>

    <h2>Порядок проезда в вагонах «Люкс» в поездах формирования АО «ФПК»</h2>

    <p>Порядок проезда в вагонах «Люкс» в поездах формирования АО «ФПК» включает в себя:</p>

    <ul>
      <li>
        В купе могут следовать один или два пассажира, при этом одним из пассажиров может быть ребёнок от 5 до 10 лет;
      </li>
      <li>
        В купе могут следовать один пассажир и ребёнок в возрасте не старше 5 лет, на ребёнка оформляется безденежный
        электронный билет. По желанию пассажира на ребёнка может быть оформлен электронный билет с занятием места;
      </li>
      <li>
        В купе могут следовать два пассажира и один или два ребёнка в возрасте не старше 5 лет без занятия места
        бесплатно;
      </li>
      <li>
        В купе могут следовать два пассажира, ребёнок до 5 лет и ребёнок в возрасте от 5 и до 10 лет (на детей
        оформляются безденежные электронные билеты);
      </li>
      <li>
        В купе могут следовать один пассажир, ребенок до 5 лет и ребенок в возрасте от 5 до 10 лет (на детей оформляются
        безденежные электронные билеты). По желанию пассажира на ребенка от 5 до 10 лет может быть оформлен электронный
        билет с занятием места.
      </li>
    </ul>

    <p>Установленные платы и сборы во всех случаях взыскиваются полностью за купе (за два места).</p>

    <p>В международном сообщение по глобальным ценам при проезде в поездах:</p>

    <ul>
      <li>№13/14 Москва – Берлин – Москва;</li>
      <li>№17/18 Москва – Ницца – Москва;</li>
      <li>№23/24 Москва – Париж – Москва;</li>
      <li>№21/22 Москва – Прага.</li>
    </ul>

    <p>
      Оформление проезда в вагоне класса «Люкс» производится одновременно на все места в купе категории Double
      (международный класс обслуживания 1/2) – двум совместно следующим пассажирам, в купе категории Single
      (международный класс обслуживания 1/1) – одному пассажиру. Оформление проезда в вагоне класса «Люкс» (бизнес)
      категории Double одиночно следующему пассажиру не осуществляется.
    </p>

    <h2>Провоз детей в вагоне класса «Люкс» (бизнес) категории Single</h2>

    <p>
      Пассажир, оформивший проезд в вагоне класса «Люкс» (бизнес) категории Single по тарифу «Взрослый-Single», имеет
      право провезти с собой бесплатно не более двух детей в возрасте до 12 лет (до дня достижения данного возраста) (в
      вагонах поезда №13/14 Москва-Берлин «Стриж» - не более одного ребенка до 12 лет), на детей оформляются безденежные
      проездные документы.
    </p>

    <p>Если пассажир следует по какому-либо другому тарифу, то применяются общие правила провоза детей.</p>

    <p>В купе категории Single не производится оформление проезда по тарифу «Взрослый Семейный» и «Детский».</p>

    <h2>Провоз детей в вагоне класса «Люкс» категории Double</h2>

    <p>
      Оформление проезда в вагоне класса «Люкс» категории Double производится одновременно на все места в купе по
      специальному тарифу «Взрослый «Семейный» для двух совместно следующих взрослых пассажиров, которые имеют право
      провезти с собой бесплатно не более двух детей в возрасте до 4 лет или одного ребенка в возрасте до 12 лет (до дня
      достижения данного возраста) (в вагонах поезда №13/14 Москва-Берлин «Стриж» - не более двух детей в возрасте до 4
      лет). На каждого ребенка, провозимого бесплатно, необходимо оформить безденежный проездной документ.
    </p>

    <p>Если пассажир следует по какому-либо другому тарифу, то применяются общие правила провоза детей.</p>

    <p>
      В купе категории Double в вагоне класса «Люкс» разрешается оформление проезда по тарифам «Взрослый Senior»,
      «Молодежный Junior», «Взрослый Праздничный», «Взрослый Свадебный» и детскому.
    </p>

    <p>В международном сообщении при проезде поездом:</p>
    <ul>
      <li>№032 Москва – Хельсинки «Лев Толстой»;</li>
      <li>№009 Москва – Варшава «Полонез».</li>
    </ul>

    <p>
      Оформление проезда в вагоне класса «Люкс» производится одновременно на все места в купе категории Double
      (международный класс обслуживания 1/2) – двум совместно следующим пассажирам, в купе категории Single
      (международный класс обслуживания 1/1) – одному пассажиру. Оформление проезда в вагоне класса «Люкс» (бизнес)
      категории Double одиночно следующему пассажиру не осуществляется.
    </p>

    <p>
      Пассажир, оформивший проезд в вагоне класса «Люкс» (бизнес) по тарифу «Взрослый-Single», имеет право провезти с
      собой бесплатно не более двух детей в возрасте до 6 лет в поезде №032 «Лев Толстой» и до 4 лет в поезде №009
      «Полонез», на детей оформляются безденежные проездные документы.
    </p>

    <p>
      Оформление проезда в вагоне класса «Люкс» категории Double производится одновременно на все места в купе для двух
      совместно следующих взрослых пассажиров, которые имеют право провезти с собой бесплатно одного ребёнка в возрасте
      до 6 лет в поезде №032 «Лев Толстой» и до 4 лет в поезде №009 «Полонез». На ребёнка, провозимого бесплатно,
      необходимо оформить безденежный проездной документ.
    </p>

    <p>
      При оформлении заказа в салон-переговорную поезда «Сапсан» (вагон №01, места с 27 по 30, с классом обслуживания
      1Р) электронный билет выдается с оплатой целого купе, в котором могут проехать от одного до четырех пассажиров. В
      случае оформления проезда в одном купе более одного пассажира, должны быть указаны персональные данные каждого
      пассажира.
    </p>
    <p>Стоимость проезда в поездах международного сообщения определяется следующим образом:</p>

    <ul>
      <li>
        Со странами СНГ, Латвийской Республикой, Литовской Республикой, Эстонской Республикой, Республикой Абхазии
        определяется в швейцарских франках;
      </li>
      <li>Со странами Европы определяется в евро.</li>
    </ul>

    <p>
      Оплата проезда на сайте осуществляется в рублях по официальному курсу ЦБ РФ на день оформления электронного
      билета.
    </p>

    <p>
      На поезда отправлением из Украины стоимость проезда не определяется на этапе получения справки о наличии мест,
      стоимость проезда становится доступной для пассажира только перед оплатой электронного билета.
    </p>

    <h2>Действия, которые не производятся</h2>

    <p>В следующем списке перечислены действия, которые не производятся:</p>

    <ul>
      <li>Резервирование мест без оплаты стоимости проезда;</li>
      <li>Оформление электронных билетов для льготных категорий граждан;</li>
      <li>Оформление мест, предусмотренных для пассажиров с ограниченной возможностью передвижения;</li>
      <li>
        Оформление безденежных электронных билетов для проезда детей в возрасте до 5 лет без занятия отдельного места в
        сопровождении взрослых со станций, расположенных на территории Украины;
      </li>
      <li>Переоформление электронных билетов;</li>
      <li>
        Оформление электронных билетов в поезда, следующие в (из) Калининградскую область транзитом через территорию
        Литовской Республики;
      </li>
      <li>
        Оформление электронных билетов в международном сообщении со странами дальнего зарубежья (за исключением поездов:
        №17/18 Москва - Ницца – Москва; №23/24 Москва - Париж – Москва; №21/22 Москва - Прага – Москва, включая
        беспересадочные вагоны: Москва - Вена - Москва, Москва - Карловы Вары - Хеб - Москва, Санкт-Петербург - Прага -
        Санкт-Петербург, №32 Москва - Хельсинки и скоростные поезда «Аллегро» отправлением из России в Финляндию);
      </li>
      <li>Оформление электронных билетов в поезда и вагоны ОАО АК «ЖЕЛЕЗНЫЕ ДОРОГИ ЯКУТИИ»;</li>
    </ul>

    <h2>Правила проезда несовершеннолетних граждан при пересечении государственной границы Российской Федерации</h2>
    <p>
      При проезде в международном и межгосударственном сообщениях пассажир обязан иметь оригинал документа, дающий право
      на пересечение государственной границы.
    </p>
    <p>Поездка несовершеннолетних детей осуществляется только при наличии подлинника свидетельства о рождении.</p>
    <p>
      При пересечении государственной границы несовершеннолетний гражданин Российской Федерации, следующий совместно
      хотя бы с одним из родителей может выезжать из Российской Федерации по своему заграничному паспорту или будучи
      вписанным, в заграничный паспорт родителя, при этом для детей в возрасте с 7 лет обязательно фотокарточка ребенка.
    </p>
    <p>
      При следовании несовершеннолетнего гражданина Российской Федерации через государственную границу совместно с одним
      из родителей, согласие второго не требуется, если от него в пограничные органы не поступало заявление о своем
      несогласии на выезд из Российской Федерации своих детей.
    </p>
    <p>
      Если несовершеннолетний гражданин Российской Федерации выезжает из Российской Федерации без сопровождения
      родителей, усыновителей, опекунов или попечителей, он должен иметь при себе кроме паспорта (если иное не
      предусмотрено международным договором) нотариально оформленное согласие родителей, опекунов или попечителей на
      выезд несовершеннолетнего гражданина Российской Федерации с указанием срока выезда и государства (государств),
      которое (которые), он намерен посетить. При этом достаточно согласия одного из родителей, если от второго не
      поступало заявления о несогласии на выезд детей.
    </p>
    <p>
      Детям, находящимся на полном государственном попечении в воспитательных учреждениях, учреждениях социальной защиты
      населения и других аналогичных учреждениях, опекуны (попечители) не назначаются. Выполнение их обязанностей
      возлагается на администрацию этих учреждений.
    </p>
    <p>
      При выезде групп таких детей за границу, кроме документов, удостоверяющих личность предусмотренных
      соответствующими Соглашениями, необходимо нотариально заверенное согласие от администрации детского учреждения на
      имя ответственного лица (старшего группы). Такие согласия могут выдаваться на каждого ребенка отдельно или с
      приложением списка выезжающих детей подписанного должностным лицом администрации детского учреждения. Оформление
      проездных документов производится только после письменного подтверждения организаторами наличия на момент поездки
      у членов групп документов, дающих право на пересечение государственной границы.
    </p>
    <p>
      Выезд в государства – участники Содружества Независимых Государств (Украина*, Белоруссия, Казахстан, Таджикистан,
      Кыргызстан) с которыми Российская Федерация имеет соответствующие межправительственные соглашения о взаимных
      поездках - дети до 14 лет могут выезжать по оригиналу свидетельства о рождении при наличии в нем отметки,
      подтверждающей Российское гражданство, обязательно. Дети старше 14 лет по паспорту гражданина Российской
      Федерации. Если пассажиры не являются родителями, то необходимо наличие доверенности на вывоз детей, заверенной
      нотариально.
    </p>
    <p>
      При отсутствии документов, дающих право на пересечение границы, пассажир не допускается на территорию сопредельных
      государств и высаживается из поезда.
    </p>
    <p>
      При пересечении государственной границы Российской Федерации соблюдайте паспортноадминистративные, таможенные и
      другие правила как в отношении себя, так и в отношении досмотра ручной клади и багажа, пассажир должен лично
      присутствовать при таможенном досмотре и в случае необходимости оплачивать установленные сборы.
    </p>
    <p>Железная дорога не несет ответственности за невыполнения этих правил пассажиром.</p>
    <p>
      Более полную информацию о документах, устанавливающие порядок пересечения границы, Вы можете получить в
      управлениях пограничного контроля или в органах таможенного контроля.
    </p>
    <p> * Не действует с 1 марта 2015г.</p>
  </CustomModal>
);
