import { useEffect, useState } from 'react';
import { Box, Button, Stack, Typography } from '@mui/material';
import { TrainCard } from '../../Components-2.0/TrainCard/TrainCard';
import { RailwayForm } from '../../Components-2.0/RailwayForm/RailwayForm2';
import MainContainer from '../../Components-2.0/MainContainer/MainContainer';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { useStore } from 'effector-react';
import {
  $modalErrorTogglerStore,
  $isReturnPageStore,
  dateReturnStoreSetter,
  resetLocalTrainDataReturn,
  setErrorMessage,
  setIsStartPage,
  setLocalTrainDataReturn,
  setLocalTrainData,
} from '../../Services/trainsService';
import axios from 'axios';
import { baseUrl } from '../../config';
import { SceletonTrainData } from '../../Components-2.0/Sceletons/SceletonTrainData';

export const TrainsPage = () => {
  const params = useParams();
  const navigate = useNavigate();
  const { state } = useLocation();
  const { cityFrom, cityFromCode, cityTo, cityToCode, dateReturn, dateTo } = state;
  const [responseTrains, setResponseTrains] = useState();
  const [isLoaded, setIsLoaded] = useState(false);
  const departureDate = new Date(dateTo);
  const errorModal = useStore($modalErrorTogglerStore);
  const isReturnPage = useStore($isReturnPageStore);

  //ФУНКЦИЯ ЗАПРОСА ДЛЯ ЗАГРУЗКИ ПОЕЗДОВ

  const getTrains = () => {
    const json = JSON.stringify(params);
    setIsLoaded(false);
    try {
      axios
        .post(`${baseUrl}/api/Railway/get_trains`, json, {
          headers: {
            'Content-Type': 'application/json',
          },
        })
        .then(response => {
          setResponseTrains(response.data);
          setIsLoaded(true);
        })
        .catch(error => {
          setErrorMessage(error);
          navigate('/');
        });
    } catch (error) {
      throw error;
    }
  };

  //ФУНКЦИЯ ПЕРЕХОДА НА СТРАНИЦУ ВАГОНОВ
  const handleSubmit = (trainData, CarType) => {
    if (isReturnPage) {
      setLocalTrainDataReturn(trainData);
    } else {
      resetLocalTrainDataReturn();
      setLocalTrainData(trainData);
    }
    navigate(
      `/cars/${trainData.OriginStationCode}/${trainData.DestinationStationCode}/${trainData.DepartureDateTime}/${trainData.TrainNumber}/${CarType}/${trainData.Provider}/0`,
      {
        state: {
          destination: params.Destination,
          origin: params.Origin,
          cityFrom: state.cityFrom,
          cityTo: state.cityTo,
          dateTo: state.dateTo,
          dateReturn: state.dateReturn,
        },
      },
    );
  };

  useEffect(() => {
    if (!errorModal) {
      getTrains(params);
    }
  }, [params]);

  useEffect(() => {
    setIsStartPage(false);
    dateReturnStoreSetter(dateReturn);
  }, []);

  // ---------------------------------------------------
  const carTypeName = CarType => {
    switch (CarType) {
      case 'СВ':
        return 'СВ';
      case 'ЛЮКС':
        return 'Люкс';
      case 'КУПЕ':
        return 'Купе';
      case 'ПЛАЦ':
        return 'Плацкарт';
      case 'СИД':
        return 'Сидячие';
      case 'ОБЩ':
        return 'Общий';
    }
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  // ---------------------------------------------------

  return (
    <Box>
      <Box marginBottom={5} marginTop={1.25} sx={{ padding: '0 20px' }}>
        <RailwayForm
          cityFrom={cityFrom}
          cityFromCode={cityFromCode}
          cityTo={cityTo}
          cityToCode={cityToCode}
          dateTo={dateTo}
          dateReturn={dateReturn}
          isReturnPage={isReturnPage}
        />
      </Box>
      {isLoaded ? (
        <>
          <Box padding={'0px 20px'}>
            <MainContainer sx={{ marginBottom: '40px', flexDirection: 'column', alignItems: 'left' }}>
              <Typography variant="h1" fontSize={32} sx={{ marginBottom: '20px' }}>
                Список поездов по выбранному направлению {cityFrom} — {cityTo} на{' '}
                {departureDate.toLocaleDateString('ru-ru')}
              </Typography>
              <Typography>Вариантов: {responseTrains.Trains.length}</Typography>
            </MainContainer>
          </Box>
          {responseTrains.Trains.map((trainData, i) => (
            <Box key={i} padding={'0px 20px'}>
              <TrainCard trainData={trainData} key={i}>
                <Box width={{ xs: 'auto', md: '350px', lg: '420px' }}>
                  <Typography fontSize={{ xs: 16, lg: 20 }} display={{ xs: 'none', md: 'block' }}>
                    Выберите тип вагона:
                  </Typography>
                  <Stack spacing={1.75} mt={2.5}>
                    {trainData.CarGroups.map((car, i) => (
                      <Button
                        onClick={() => handleSubmit(trainData, car.CarType)}
                        key={i}
                        variant="text"
                        sx={{
                          justifyContent: 'space-between',
                          height: { xs: '65px', md: '45px' },
                          marginBottom: '14px',
                          backgroundColor: '#FBFAFA',
                        }}
                        fullWidth
                      >
                        <Stack direction={{ xs: 'column', md: 'row' }} width={'55%'} justifyContent={'space-between'}>
                          <Box width={'20%'} textAlign={'left'}>
                            <Typography color="black">{carTypeName(car.CarTypeName)}</Typography>
                          </Box>
                          <Box width={'30%'} textAlign={'left'}>
                            <Typography color="black" fontWeight={'bold'}>
                              {car.TotalPlaceQuantity} мест
                            </Typography>
                          </Box>
                        </Stack>
                        <Box width={'40%'} textAlign={'right'}>
                          <Typography color="primary" fontWeight={'bold'}>
                            от {car.MinPrice} ₽
                          </Typography>
                        </Box>
                      </Button>
                    ))}
                  </Stack>
                </Box>
              </TrainCard>
            </Box>
          ))}
        </>
      ) : (
        <Box padding={'0px 20px'}>
          <MainContainer direction="column">
            <SceletonTrainData />
            <SceletonTrainData />
            <SceletonTrainData />
          </MainContainer>
        </Box>
      )}
    </Box>
  );
};
