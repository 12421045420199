import MainContainer from '../MainContainer/MainContainer';
import { Box, Button, CardContent, Stack, TextField, Typography } from '@mui/material';

export const Subscription = () => {
  return (
    <Box marginBottom={{ xs: 7, md: 10, lg: 15 }} padding={'0px 20px'}>
      <MainContainer
        direction={'column'}
        sx={{
          alignItems: 'flex-start',
          padding: { xs: '20px', sm: '35px', md: '50px' },
          backgroundColor: '#fffaf2',
          borderRadius: '20px',
        }}
      >
        <Typography
          variant="h5"
          component="h2"
          color="#000000"
          sx={{
            marginBottom: { xs: '25px', sm: '35px', md: '50px' },
            fontSize: { xs: '24px', sm: '34px', md: '42px' },
          }}
        >
          <strong>Подпишись на нас</strong>, чтобы быть всегда в курсе новостей, выгодных акций и скидок.
        </Typography>
        <Stack direction={{ xs: 'column', sm: 'row' }} width={'100%'} spacing={{ xs: 1.5, md: 2.5 }}>
          <TextField
            variant="outlined"
            placeholder="Ваша почта"
            sx={{
              width: { xs: '100%', sm: '260px' },
              backgroundColor: 'white',
              borderRadius: 1,
              input: { color: 'black' },
            }}
          />
          <Button
            variant="contained"
            size="large"
            color="primary"
            sx={{ width: { xs: '100%', sm: '180px' }, height: '56px' }}
          >
            Отправить
          </Button>
        </Stack>
      </MainContainer>
    </Box>
  );
};
