export default function DatePicker(theme) {
  return {
    MuiPickersDay: {
      styleOverrides: {
        root: {
          color: '#000',
          '&.Mui-selected': {
            color: '#fff',
            backgroundColor: theme.palette.primary.main,
          },
        },
      },
    },

    // Стили для заголовка календаря и дней недели
    MuiPickersCalendarHeader: {
      styleOverrides: {
        root: {
          color: '#000',
        },
        dayLabel: {
          color: '#000',
        },
      },
    },

    // Стили для выбора месяца
    MuiPickersMonth: {
      styleOverrides: {
        root: {
          backgroundColor: 'red',
          color: '#fff',
        },
        monthButton: {
          '&&.Mui-selected': {
            backgroundColor: theme.palette.secondary.main,
            color: '#fff',
            '&:hover': {
              backgroundColor: theme.palette.secondary.dark,
            },
          },
        },
      },
    },

    // Стили для выбора года
    MuiPickersYear: {
      styleOverrides: {
        root: {
          color: '#000',
          '&.Mui-selected': {
            color: '#fff',
            backgroundColor: theme.palette.primary.main,
          },
        },
        yearButton: {
          '&&.Mui-selected': {
            backgroundColor: theme.palette.primary.main,
            color: '#fff',
            '&:hover': {
              backgroundColor: theme.palette.secondary.main,
            },
          },
        },
      },
    },
  };
}
