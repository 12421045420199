import { alpha } from '@mui/material/styles';

const GREY = {
  0: '#FFFFFF',
  100: '#F9FAFB',
  200: '#F4F6F8',
  300: '#DFE3E8',
  400: '#C4CDD5',
  500: '#919EAB',
  600: '#637381',
  700: '#454F5B',
  800: '#212B36',
  900: '#161C24',
  1000: '#1D2433CC',
};

const NEUTRAL = {
  900: '#1B232C',
  800: '#4E5A65',
  700: '#637381',
  600: '#7D8D9B',
  500: '#9DA7B2',
  400: '#C5CCD3',
  300: '#E8EBEE',
  200: '#F6F7F8',
  100: '#FFFFFF',
};

const PRIMARY = {
  '-1': 'rgb(230, 103, 18)',
  main: 'rgb(255, 117, 23)',
  '+1': 'rgb(239, 120, 40)',
  contrastText: '#FFFFFF',
};

const SECONDARY = {
  main: '#9EA9B7',
};

const INFO = {
  light: PRIMARY['+1'],
  main: PRIMARY.main,
  dark: PRIMARY['-1'],
  contrastText: '#FFFFFF',
};

const SUCCESS = {
  light: '#86E8AB',
  main: '#36B37E',
  dark: '#1B806A',
  contrastText: '#FFFFFF',
};

const WARNING = {
  '-1': '#E86C00',
  '+1': '#FCDFA7',
  '+2': '#FEEFC9',
  light: '#FCDFA7',
  main: '#FD8900',
  dark: '#E86C00',
  contrastText: '#1B232C',
};

const ERROR = {
  '-1': '#CC2600',
  '+1': '#FFD3C9',
  light: '#FFD3C9',
  main: '#FF3000',
  dark: '#B71D18',
  contrastText: '#FFFFFF',
};

const COMMON = {
  primary: PRIMARY,
  secondary: SECONDARY,
  neutral: NEUTRAL,
  success: SUCCESS,
  common: { black: '#000000', white: '#FFFFFF' },
  warning: WARNING,
  info: INFO,
  error: ERROR,
  grey: GREY,
  divider: NEUTRAL[300],
  action: {
    hover: alpha(GREY[500], 0.08),
    selected: alpha(GREY[500], 0.16),
    disabled: alpha(GREY[500], 0.8),
    disabledBackground: alpha(GREY[500], 0.24),
    focus: alpha(GREY[500], 0.24),
    hoverOpacity: 0.08,
    disabledOpacity: 0.48,
  },
};

const light = {
  ...COMMON,
  mode: 'light',
  text: {
    primary: NEUTRAL[100],
    secondary: GREY[600],
    disabled: GREY[500],
  },
  background: {
    paper: NEUTRAL[100],
    default: NEUTRAL[100],
    neutral: GREY[200],
  },
  action: {
    ...COMMON.action,
    active: GREY[600],
  },
};

export const getPalette = () => light;
