import { CustomModal } from '../../../Components/CustomModal';

export const PublicOfferModal = props => (
  <CustomModal {...props}>
    <h2>ПУБЛИЧНАЯ ОФЕРТА О ЗАКЛЮЧЕНИИ ДОГОВОРА НА ОКАЗАНИЕ УСЛУГ</h2>
    <p>
      <strong>г. Москва</strong> <span>27.05.2024</span>
    </p>
    <p>
      Начало использования интернет-сайта <a href="https://www.kokoc.ru/">https://www.kokoc.ru/</a> означает Ваше полное
      согласие со всеми условиями настоящего Договора. Настоящая публичная оферта (далее — «Оферта») в соответствии с п.
      2 ст. 437 Гражданского кодекса Российской Федерации (далее — «ГК РФ») является официальным предложением общества с
      ограниченной ответственностью «МИР» (сокращенное наименование — ООО «МИР») в лице генерального директора Кожухова
      Романа Николаевича, действующего на основании Устава (далее по тексту — «Исполнитель»), заключить с дееспособным
      физическим лицом (далее — «Заказчик») договор на оказание услуг (далее — «Договор») на условиях, предусмотренных
      данной Офертой.
    </p>
    <p>
      Настоящий документ в соответствии со ст. 435 и ч. 2 ст. 437 ГК РФ является публичной офертой. Заказчик и
      Исполнитель далее совместно именуются «Стороны», а по отдельности — «Сторона».
    </p>
    <p>
      Оферта вступает в силу с момента ее размещения на сайте <a href="https://www.kokoc.ru/">https://www.kokoc.ru/</a>{' '}
      в сети Интернет (далее — «Сайт») по адресу <a href="https://www.kokoc.ru/">https://www.kokoc.ru/</a>. Исполнитель
      оставляет за собой право внести изменения в условия Оферты и/или отозвать Оферту в любой момент по своему
      усмотрению без какого-либо специального уведомления Заказчика. Изменения, внесенные Исполнителем в Оферту,
      вступают в силу с момента размещения изменений на <a href="https://www.kokoc.ru/">https://www.kokoc.ru/</a>, если
      иной срок вступления изменений в силу не определен дополнительно при таком размещении. Приложение к Оферте
      является ее неотъемлемой частью.
    </p>
    <h4>1. ПОРЯДОК ЗАКЛЮЧЕНИЯ ДОГОВОРА</h4>
    <p>
      1.1. Согласно ст. 434, 435, 438 ГК РФ Договор заключается путем акцепта Оферты Заказчиком, то есть выражением
      Заказчиком полного и безоговорочного принятия условий Оферты.
    </p>
    <p>
      1.2. В соответствии со ст. 438 ГК РФ акцептом Оферты является выполнение Заказчиком следующего действия: нажатие
      Заказчиком на этапе оформления заказа на Сайте или в приложении, установленном на мобильном устройстве (далее —
      «Мобильное приложение»), соответствующей кнопки перехода на следующую страницу оформления заказа или к оплате или
      бронированию на Сайте или в Мобильном приложении.
    </p>
    <p>
      1.3. Заказчик обязан ознакомиться со всеми условиями настоящей Оферты до совершения акцепта Оферты. Заказчик,
      совершивший акцепт Оферты, считается ознакомившимся и согласным со всеми условиями Оферты в том виде, в каком они
      изложены в тексте Оферты, и в соответствии с ГК РФ рассматривается как лицо, вступившее с Исполнителем в
      договорные отношения. При этом Договор в соответствии со ст. 434, 435, 438 ГК РФ считается заключенным в
      письменной форме на условиях настоящей Оферты и является равносильным Договору, подписанному двумя Сторонами.
      Принимая условия Оферты, Заказчик полностью подтверждает свои право- и дееспособность для заключения Договора,
      финансовую состоятельность, а также осознает ответственность за обязательства, возложенные на него в результате
      заключения Договора.
    </p>
    <p>
      1.4. Моментом заключения Договора считается момент получения Исполнителем акцепта Оферты Заказчиком. Местом
      заключения Договора считается город Москва.
    </p>
    <h4>2. ТЕРМИНЫ И ОПРЕДЕЛЕНИЯ</h4>
    <ul>
      <li>
        <strong>2.1. Заказ</strong> — должным образом оформленный и сформированный посредством Сайта или Мобильного
        приложения запрос Заказчика на получение услуг, выбранных Заказчиком на Сайте или в Мобильном приложении и
        определенных в соответствующих Приложениях к Оферте.
      </li>
      <li>
        <strong>2.2. Заказчик</strong> — дееспособное физическое лицо, подтверждающее свои право- и дееспособность для
        заключения Договора, финансовую состоятельность, заключившее Договор и разместившее Заказ на получение указанных
        на Сайте или в Мобильном приложении услуг, согласованных Сторонами в соответствующих Приложениях к Оферте, и
        приобретающее их на свое имя или на имя третьих лиц (далее — «Третьи лица») для личных, семейных, домашних и
        иных нужд, не связанных с осуществлением предпринимательской деятельности.
      </li>
      <li>
        <strong>2.3. Личный кабинет</strong> — индивидуальный раздел Заказчика на Сайте или в Мобильном приложении,
        защищенный параметрами авторизации, доступ к которому осуществляется путем ввода идентифицирующих данных (логин
        и пароль или логин и код) или путем авторизации с помощью аккаунта в социальной сети «ВКонтакте» или аккаунта в
        социальной сети «Одноклассники», в котором содержится информация по Заказу. Личный кабинет регистрируется на
        адрес электронной почты Заказчика автоматически после совершения Заказа на Сайте или в Мобильном приложении или
        после прохождения регистрации пользователя на Сайте или в Мобильном приложении.
      </li>
      <li>
        <strong>2.4. Логин</strong> — адрес электронной почты Заказчика или номер мобильного телефона Заказчика.
      </li>
      <li>
        <strong>2.5. Пароль или код</strong> — наборы латинских букв и/или цифр, необходимые для доступа в Личный
        кабинет, которые в сочетании с Логином служат идентифицирующими данными Заказчика.
      </li>
      <li>
        <strong>2.6. Мобильное приложение</strong> — предназначенная для установки и использования на мобильном
        устройстве (планшет, мобильный телефон, смартфон, коммуникатор, иное устройство, позволяющее использовать
        Мобильное приложение по его функциональному назначению) программа для ЭВМ, позволяющая Заказчику получить доступ
        к Сайту и совершить через Сайт предусмотренные настоящей Офертой действия.
      </li>
      <li>
        <strong>2.7. Поставщик услуг или Поставщики услуг</strong> — перевозчики, фрахтователи, организации,
        предоставляющие услуги по временному размещению и проживанию.
      </li>
      <li>
        <strong>2.8. Сайт</strong> — <a href="https://www.kokoc.ru/">https://www.kokoc.ru/</a> (далее — «Сайт») —
        используемый самостоятельный составной мультимедийный продукт в значении ст. 1260 ГК РФ, модули и
        кросс-модульные компоненты которого используются Исполнителем на законных основаниях для оказания услуг,
        предусмотренных целями Оферты, а также лицензионными и техническими ограничениями. Сайт представляет собой
        совокупность связанных между собой текстов, графических элементов, фото- и видеоматериалов, программ для ЭВМ, в
        том числе кроссплатформенных клиент-серверных приложений, программных модулей, баз данных, веб-страниц,
        кросс-модульных компонентов и иных элементов, алгоритмически объединенных по тематическому, техническому и
        функциональному признаку и предназначенных для доведения информации до всеобщего сведения, получения информации,
        обмена и осуществления иного функционала в сети Интернет через обращение к доменам или поддоменам kokoc.ru. В
        целях Оферты под доменом (доменным именем) понимается уникальный адрес в сети Интернет, используемый
        Исполнителем на законных основаниях и служащий для доступа Заказчика к Сайту и программным модулям
        соответственно.
      </li>
      <li>2.9. Администратором доменного имени kokoc.ru является ООО «МИР».</li>
    </ul>
    <h4>3. ПРЕДМЕТ ОФЕРТЫ</h4>
    <p>
      <strong>3.1.</strong> Все договоры перевозок пассажиров заключаются Заказчиком и/или пассажиром непосредственно с
      перевозчиками, оказывающими соответствующие пассажирские перевозки. Услуги по временному размещению и проживанию
      оказываются соответствующими Поставщиками услуг. Исполнитель при обработке Заказа не выступает ни как продавец
      электронных билетов, ни как туроператор или турагент, не несет ответственность за какие-либо иные компоненты
      путешествия Заказчика или Третьего лица, такие как получение виз и иных разрешений на выезд, проверку
      действительности, получение и наличие документов, подтверждающих личность Заказчика или Третьего лица, страховых
      полисов, трансфер, проживание в гостинице, перелеты по билетам, приобретенным на рейсы разных перевозчиков и
      стыковки между ними и иными обстоятельствами.
    </p>
    <p>
      <strong>3.2.</strong> Исполнитель обязуется оказать Заказчику в зависимости от параметров Заказа с помощью Сайта
      или Мобильного приложения следующие услуги:
    </p>
    <ul>
      <li>
        <p>
          по предоставлению Заказчику по предоставленным им данным и параметрам произведенного Заказчиком поиска и
          примененным им при поисковом запросе фильтрам в режиме онлайн технической возможности использовать размещенные
          на Сайте или в Мобильном приложении сервисы с целью:
        </p>
        <ul>
          <li>
            формирования Заказа и его оплаты, бронирования выбранного электронного билета на различные виды транспорта
            и/или места в отеле или ином объекте размещения;
          </li>
          <li>
            оформления возврата или обмена электронных билетов, мест в отелях или в иных средствах размещения, или
            опционального приобретения дополнительных услуг как в едином Заказе, так и отдельно на конкретный вид
            транспорта.
          </li>
        </ul>
      </li>
      <li>
        <p>
          по предоставлению клиентской поддержки в виде услуг, оказываемых через контактный центр с помощью различных
          каналов связи: по телефонам, номера которых указаны в соответствующих Приложениях к Оферте, sms-сообщения,
          мессенджеры, социальные сети, форму обратной связи, e-mail-канал, веб-чат, включающих в себя:
        </p>
        <ul>
          <li>
            внесение изменений в Заказ по запросу Заказчика и в соответствии с условиями, согласованными Сторонами в
            соответствующих Приложениях к Оферте (оформление возврата, обмена билетов, мест в отелях или в иных
            средствах размещения, включение/исключение допуслуг) а также иные услуги, согласованные Сторонами в
            соответствующих Приложениях к Оферте в соответствии с Заказом Заказчика, а Заказчик обязуется принять и
            оплатить оказанные Исполнителем услуги. Исполнитель вправе привлекать к оказанию услуг, указанных в
            настоящем пункте Оферты, третьих лиц. Исполнитель отвечает за действия таких третьих лиц как за свои
            собственные. Заказчик самостоятельно определяет параметры Заказа.
          </li>
        </ul>
      </li>
    </ul>
    <p>
      <strong>3.2.1.</strong> Исполнитель взимает с Заказчика стоимость услуг Исполнителя за оказание Исполнителем
      Заказчику услуг, указанных в п. 2.1. Оферты (далее - «Сервисный сбор»). Сервисный сбор не подлежит возврату за
      исключением случаев, указанных в соответствующих Приложениях к Оферте.
    </p>
    <p>
      <strong>3.2.1.1.</strong> Акцептуя Оферту, Заказчик понимает и соглашается с услугами, которые Исполнитель
      оказывает Заказчику в соответствии с п. 2.1. Оферты. Производя оплату Заказа или при бронировании, если такая
      возможность предусмотрена Сайтом или Мобильным приложением, Заказчик соглашается со стоимостью Сервисного сбора,
      которая указана на Сайте или в Мобильном приложении при оформлении Заказа. В случае если Заказчик не согласен с
      услугами, которые Исполнитель оказывает Заказчику в соответствии с п. 2.1. Оферты и со стоимостью Сервисного
      сбора, то Заказчик имеет право отказаться от оплаты Сервисного сбора и оформить билет или забронировать место в
      отеле или ином средстве размещения напрямую у Поставщиков услуг без использования Сайта / Мобильного приложения.
    </p>
    <p>
      <strong>3.3.</strong> Виды подлежащих оказанию Исполнителем Заказчику на основании Заказа услуг, их стоимость,
      сроки и иные существенные условия оказания Исполнителем Заказчику услуг приведены в соответствующих Приложениях к
      Оферте, являющихся неотъемлемой частью Оферты (далее - «Приложения» или «Приложение»).
    </p>
    <h4>4. ОБЯЗАННОСТИ И ПРАВА СТОРОН</h4>
    <p>4.1. Исполнитель обязан:</p>

    <p>
      4.1.1. Своевременно и надлежащим образом оказать Заказчику услуги указанные в п. 3.1. Оферты в точном соответствии
      с Заказом оформляемым Заказчиком посредством Сайта или Мобильного приложения и оформить все необходимые документы
      в соответствии с действующим законодательством Российской Федерации и правилами установленными соответствующими
      Поставщиками услуг (далее — «Документ» или «Документы»);
    </p>
    <p>
      4.1.2. С помощью Сайта или Мобильного приложения предоставлять необходимую информацию и техническую возможность
      Заказчику использовать Сайт или Мобильное приложение и размещенные на них сервисы с целью получения услуг
      указанных в соответствующих Приложениях к Оферте;
    </p>
    <p>
      4.1.3. В процессе оформления Заказа предоставить Заказчику путем размещения на Сайте или в Мобильном приложении
      информацию в том виде в котором она была предоставлена от Поставщиков услуг об условиях перевозки или временного
      размещения и проживания информацию касающуюся условий заключения/изменения договора перевозки или временного
      размещения и проживания правилах применения тарифов Поставщиков соответствующих услуг и иную необходимую
      информацию;
    </p>
    <p>4.1.4. Предоставить Заказчику доступ в Личный кабинет.</p>

    <p>4.2. Исполнитель вправе:</p>

    <p>
      4.2.1. Взимать в соответствии с разделом 5 Оферты Сервисный сбор за оказание Исполнителем Заказчику услуг
      указанных в п. 2.1. Оферты. Сервисный сбор не подлежит возврату за исключением случаев указанных в соответствующих
      Приложениях к Оферте;
    </p>
    <p>
      4.2.2. Отказать в оформлении Заказа аннулировать Заказ при наличии достаточных к тому оснований в том числе в
      случае отсутствия необходимых данных для оказания услуг указанных в п. 2.1. Оферты наличия признаков мошеннических
      операций с платежными картами не завершения Заказчиком процедуры Заказа и/или оплаты Заказа отмены бронирования
      соответствующими Поставщиками услуг и в иных случаях;
    </p>
    <p>
      4.2.3. Лишить Заказчика доступа к Сайту и/или Мобильному приложению в любое время по причине нарушения законных
      требований Поставщиков услуг или совершения иных противоправных действий. В этом случае Исполнитель не несет
      какой-либо ответственности за возможные последствия такого решения;
    </p>
    <p>
      4.2.4. Вносить любые изменения на Сайт или в Мобильное приложение приостанавливать их работу при обнаружении
      существенных неисправностей ошибок и сбоев а также в целях проведения профилактических работ и предотвращения
      случаев несанкционированного доступа к Сайту или Мобильному приложению.
    </p>
    <p>
      4.2.5. Оказывать содействие Заказчику через службу поддержки по телефонам номера которых указаны в соответствующих
      Приложениях к Оферте.
    </p>

    <p>
      <strong>4.3. Заказчик обязан:</strong>
    </p>

    <p>
      4.3.1. Оплатить стоимость Заказа Сервисный сбор оговоренный в соответствующих Приложениях к Оферте в размере
      порядке и сроки установленные в разделе 5 Оферты;
    </p>
    <p>4.3.2. Оформлять Заказ на Сайте или в Мобильном приложении в соответствии с разделом 4 Оферты;</p>
    <p>
      4.3.3. Своевременно информировать Исполнителя о необходимости отмены Заказа или внесения в него изменений в
      порядке указанном в соответствующих Приложениях к Оферте;
    </p>
    <p>
      4.3.4. Проверять полученные по соответствующему Заказу на адрес электронной почты указанный Заказчиком при
      оформлении Заказа Документы на предмет возможных ошибок сразу после их направления Исполнителем и незамедлительно
      информировать Исполнителя о выявленных ошибках;
    </p>
    <p>
      4.3.5. Указать достоверные личные данные при оформлении Заказа как собственные так и Третьих лиц. В случае
      предоставления Заказчиком недостоверной информации при оформлении Заказа риск отказа в предоставлении услуг
      Поставщиками услуг лежит на Заказчике;
    </p>
    <p>
      4.3.6. Самостоятельно ознакомиться с условиями Оферты, требованиями таможенного, паспортного, визового и иного
      контроля стран на выбранном маршруте, в том числе касающихся сроков действия документов, удостоверяющих личность,
      и правилами провоза багажа, груза и ценностей, с правилами бронирования и тарифами Поставщиков услуг, в том числе
      условиями обмена/возврата услуг в Заказе и применимыми штрафными санкциями, установленными соответствующими
      Поставщиками услуг, об условиях договора, заключенного с Поставщиком услуг, и прочими условиями, а также Заказчик
      обязан самостоятельно довести указанную в настоящем пункте Оферты информацию до сведения всех Третьих лиц, для
      которых бронируются, оформляются и приобретаются услуги, выбранные Заказчиком на Сайте или в Мобильном приложении;
    </p>
    <p>
      4.3.7. Не сообщать третьим лицам Логин и пароль или Логин и код или данные необходимые для авторизации аккаунта в
      социальной сети «ВКонтакте» или аккаунта в социальной сети «Одноклассники», необходимые для доступа в Личный
      кабинет, и предпринимать меры для сохранения в тайне данной информации. В случае возникновения у Заказчика
      подозрений относительно безопасности его Логина и пароля или Логина и кода или данных необходимых для авторизации
      аккаунта в социальной сети «ВКонтакте» или аккаунта в социальной сети «Одноклассники» или возможности их
      несанкционированного использования третьими лицами, Заказчик незамедлительно изменяет свой пароль, используя
      Личный кабинет, или изменяет свой пароль в социальной сети «ВКонтакте» или аккаунта в социальной сети
      «Одноклассники».
    </p>

    <h2>4.4. Заказчик вправе:</h2>

    <p>
      4.4.1. Получить необходимую информацию о Заказе в службе поддержки Исполнителя по телефонам, номера которых
      указаны в соответствующих Приложениях к Оферте.
    </p>

    <h4>5. ПОРЯДОК РАСЧЕТОВ</h4>
    <p>
      5.1. Стоимость Заказа определяется при оформлении Заказа и может включать в себя Сервисный сбор. Окончательная
      стоимость Заказа отображается в российских рублях. Сервисный сбор, взимаемый Исполнителем за оказание Заказчику
      услуг в соответствии с Офертой, включает в себя НДС согласно ставке, установленной в Налоговом кодексе Российской
      Федерации.
    </p>
    <p>
      5.2. Заказ должен быть оплачен одним из способов, предложенных на Сайте или в Мобильном приложении. Прием платежей
      осуществляется с использованием платежных систем, которые вправе отказать в проведении платежа. В случае, если при
      оплате Заказа Заказчик использует единый подарочный сертификат, то Заказчик, осуществляя акцепт Оферты, выражает
      свое волеизъявление зачесть аванс за услуги Исполнителя, указанные в п. 2.1. Оферты, в счет оплаты стоимости
      Заказа. При этом Заказчик, осуществляя акцепт Оферты, подтверждает, что ознакомлен и согласен с Правилами
      приобретения и использования сертификатов, которые размещены на Сайте по адресу:{' '}
      <a href="https://www.kokoc.ru/">https://www.kokoc.ru/</a>.
    </p>
    <p>5.2.1. Особенности оплаты Заказа с помощью банковских карт:</p>
    <ul>
      <li>
        Оплата Заказа осуществляется с помощью банковских карт Visa, Visa Electron, MasterCard, Maestro платежных систем
        Visa, MasterCard и банковской карты национальной платежной системы Мир.
      </li>
      <li>
        Оплата по банковской карте производится после проверки и подтверждения Заказа с переадресацией на платежный
        шлюз.
      </li>
      <li>
        Заказчик гарантирует, что является держателем банковской карты, с использованием которой производится оплата
        Заказа, и несет полную ответственность перед Исполнителем за нарушение указанной гарантии. Не допускается оплата
        Заказа Заказчиком с помощью банковской карты, если он не является ее держателем.
      </li>
      <li>
        При оплате Заказа Заказчик — держатель банковской карты вводит следующие реквизиты банковской карты: номер
        банковской карты (без пробелов), имя и фамилию держателя банковской карты, срок действия банковской карты,
        верификационный номер карты (CVV2 для Visa или CVC2 для MasterCard или код безопасности «MirAccept» для МИР).
      </li>
      <li>
        Передача этих сведений производится с соблюдением всех необходимых мер безопасности. Платежный шлюз
        соответствует международным стандартам безопасности систем Visa и MasterCard, требованиям безопасности системы
        Мир и стандарту повышенной надежности PCI DSS 3.1.
      </li>
      <li>
        Конфиденциальность указанных Заказчиком реквизитов и платежей гарантируется защищенным режимом передачи данных
        (SSL).
      </li>
      <li>
        Если банк-эмитент Заказчика поддерживает технологию 3D Secure, то в соответствии с требованиями технологии 3D
        Secure при оплате Заказа банк-эмитент может потребовать от Заказчика — держателя банковской карты пройти
        дополнительную идентификацию. Необходимость проведения дополнительной идентификации и ее способ определяется
        банком-эмитентом или платежным шлюзом.
      </li>
      <li>
        В случае если валюта банковской карты Заказчика отлична от российских рублей, банк-эмитент карты Заказчика
        конвертирует указанную сумму в валюту РФ (рубли) по своему внутреннему курсу, таким образом, сумма в валюте
        карты может отличаться от окончательной стоимости Заказа. Своей оплатой Заказчик подтверждает, что до момента
        оплаты получил информацию о внутреннем курсе банка-эмитента карты и размере комиссии банка за конвертацию и
        ознакомлен с окончательной стоимостью Заказа в валюте карты.
      </li>
      <li>Оплата считается произведенной после авторизации банком-эмитентом банковской карты Заказчика.</li>
    </ul>
    <p>
      5.3. Исполнитель оставляет за собой право запросить дополнительные документы, удостоверяющие личность Заказчика, с
      целью подтверждения правомочности использования банковской карты и в иных случаях.
    </p>
    <p>
      5.4. Производя оплату Заказа или при бронировании, если такая возможность предусмотрена Сайтом или Мобильным
      приложением, Заказчик подтверждает свое согласие с Офертой, подтверждает правильность внесения своих данных, а
      также данных Третьих лиц, ознакомление с информацией, размещенной на Сайте или в Мобильном приложении, выражает
      свое согласие с окончательной стоимостью Заказа, которая может включать Сервисный сбор, со стоимостью Сервисного
      сбора, условиями возврата или обмена билетов либо специальными условиями возврата, а также подтверждает свое
      согласие со всеми реквизитами поездки, в том числе датами поездки, временем поездки, или условиями временного
      размещения и проживания, в том числе датами предполагаемых заезда и выезда, информацией о категории номера,
      конфигурации кроватей, информацией о предоплате (при её наличии), условиями о возможности и сроках отмены
      бронирования услуг по временному размещению и проживанию, последствиях несвоевременной отмены бронирования и
      незаезда пользователя услуг временного размещения и проживания.
    </p>
    <p>
      5.5. Информацию о подтверждении оплаты Заказа Заказчик получает в виде смс-сообщения на номер телефона, который
      указан Заказчиком при оформлении Заказа.
    </p>
    <p>
      5.6. Датой исполнения обязательств Заказчика по оплате окончательной стоимости Заказа является дата зачисления
      денежных средств на расчетный счет Исполнителя.
    </p>
    <h4>6. ОБЩИЙ ПОРЯДОК ОФОРМЛЕНИЯ, ВНЕСЕНИЯ ИЗМЕНЕНИЙ И ОТМЕНЫ ЗАКАЗА</h4>
    <p>
      6.1. Со всеми условиями Заказа Заказчик знакомится в процессе его оформления на Сайте или в Мобильном приложении.
      В случае если Заказчику не понятны какие-либо условия Заказа, в том числе условия возврата, Заказчик обязан перед
      оформлением Заказа уточнить необходимую ему информацию у Исполнителя по телефонам, номера которых указаны в
      Приложениях к Оферте, либо через форму обратной связи на Сайте или в Мобильном приложении.
    </p>
    <p>
      6.2. Оформление Заказа осуществляется Заказчиком самостоятельно на Сайте или в Мобильном приложении путем выбора
      необходимого ему типа услуг.
    </p>
    <p>
      6.3. Ознакомившись с условиями услуг, Заказчик должен корректно заполнить все поля, указанные на Сайте или в
      Мобильном приложении как «обязательные» для заполнения. При этом Заказчик несет ответственность за точность,
      полноту и достоверность указанных данных.
    </p>
    <p>
      6.4. Заказы, оформленные на Сайте или в Мобильном приложении, носят окончательный характер и подлежат оплате. Все
      условия Заказа, в том числе внесение любых изменений в оформленные Заказы, отмена Заказа, равно как и другие
      условия Заказа, регламентируются соответствующими Приложениями к Оферте, правилами применения тарифов Поставщиков
      услуг, действующим законодательством РФ, а также применимыми международными нормами.
    </p>
    <p>
      6.5. В случае изменения/отмены Заказа после его оформления в каждом конкретном случае применяются соответствующие
      правила Поставщиков услуг, Заказчик обязан оплатить все штрафные санкции, предусмотренные правилами Поставщиков
      услуг, Сервисный сбор Исполнителя.
    </p>
    <p>
      6.6. В случае расхождения информации, указанной в направленном Исполнителем на адрес электронной почты Заказчика,
      указанный при оформлении Заказа, Документе с информацией, указанной в письмах или смс-сообщениях, направляемых
      Исполнителем Заказчику на адрес электронной почты и номер телефона, указанные при оформлении Заказа,
      соответственно, Заказчик обязан руководствоваться информацией, указанной в Документе, направленном Исполнителем на
      адрес электронной почты, указанный Заказчиком при оформлении Заказа или на сайте соответствующего Поставщика
      услуг.
    </p>
    <h2>7. Ответственность</h2>
    <p>
      7.1. Исполнитель может лишить Заказчика доступа к Сайту и/или к Мобильному приложению в любое время без объяснения
      причин. В этом случае Исполнитель не несет какой-либо ответственности за возможные последствия такого решения.
    </p>
    <p>7.2. Исполнитель не несет ответственность за невозможность оформления и/или оплаты Заказа, вызванную:</p>

    <p>7.2.1. ошибками, неточностями при предоставлении Заказчиком необходимых данных;</p>
    <p>7.2.2. сбоями Сайта или Мобильного приложения, в том числе в системе бронирования;</p>
    <p>7.2.3. нарушениями в системе компьютерной защиты Заказчика.</p>

    <p>
      7.3. Исполнитель не несет ответственность за нарушение Заказчиком и/или Третьим лицом требований воздушного,
      таможенного, пограничного законодательства, правил паспортного, визового и иного контроля стран на выбранном
      маршруте и др.
    </p>
    <p>
      7.4. Исполнитель не несет ответственность за негативные последствия и убытки, возникшие у Заказчика и/или Третьего
      лица в результате событий и обстоятельств, находящихся вне сферы его компетенции, а также за действия
      (бездействие) третьих лиц.
    </p>
    <ul>
      <li>
        в случае невозможности выполнения принятых на себя обязательств, вследствие недостоверности, недостаточности и
        несвоевременности сведений и документов, предоставленных Заказчиком, или нарушения Заказчиком условий Оферты или
        требований к документам;
      </li>
      <li>
        за действия Поставщиков услуг (изменение, отмена, перенос, задержка отправления транспортного средства /
        воздушного судна, качество обслуживания), за сохранность, потерю или повреждение багажа, груза, ценностей и
        документов Заказчика и/или Третьего лица в течение всего срока их поездки или временного размещения и
        проживания. В этих случаях ответственность перед Заказчиком и/или Третьим лицом несут Поставщики услуг в
        соответствии международными правилами и действующим законодательством РФ. Претензии Третьих лиц рассматриваются
        Поставщиками услуг на основе законодательства РФ и правил международных перевозок;
      </li>
      <li>за действия таможенных и иммиграционных властей.</li>
      <li>
        вследствие ограничения права Заказчика и/или Третьих лиц на выезд из РФ компетентными органами или иного пункта
        отправления компетентными органами соответствующей страны
      </li>
      <li>
        за действия консульств иностранных государств, в том числе за задержку, отказ или изменение сроков выдачи
        въездных виз;
      </li>
      <li>за отсутствие у Заказчика и/или Третьих лиц проездных документов;</li>
      <li>
        за неявку или опоздание Заказчика и/или Третьих лиц к месту отправления транспортного средства / воздушного
        судна;
      </li>
      <li>
        за несоблюдение Заказчиком и/или Третьими лицами установленных соответствующим Поставщиком услуг правил
        поведения в транспортном средстве / на воздушном судне, отеле или ином объекте временного размещения и
        проживания;
      </li>
      <li>
        за отсутствие у Заказчика и/или Третьих лиц оформленных заграничных паспортов к моменту начала поездки (при
        необходимости), соответствующих документов, регулирующих вопросы выезда несовершеннолетних детей. Исполнитель не
        несет ответственности за сбор и подготовку Заказчиком и/или Третьим лицом всех документов, необходимых для
        пользования услугами Поставщиков услуг. Заказчик и/или Третье лицо принимает на себя обязательства и всю
        ответственность по самостоятельному сбору и подготовке всех необходимых для поездки документов, таких как виза
        или действительный паспорт, а также документов, необходимых для выезда несовершеннолетних детей. Исполнитель не
        несет ответственности за незнание или несоблюдение Третьим лицом данных требований;
      </li>
      <li>за подлинность и правильность оформления документов (достоверность содержащихся в них сведений).</li>
    </ul>
    <p>
      7.5. Поставщики услуг несут ответственность перед пассажирами или пользователями услуг по временному размещению и
      проживанию в порядке, установленном законодательством Российской Федерации, международными нормами, договором
      перевозки или договором оказания услуг по временному размещению и проживанию.
    </p>
    <p>
      7.6. Стороны несут ответственность за неисполнение или ненадлежащее исполнение обязательств, предусмотренных
      Офертой, в соответствии с действующим законодательством Российской Федерации.
    </p>
    <p>
      7.7. Исполнитель не несет ответственности за действия третьих лиц, повлекшие кражу/утерю Логина или пароля или
      кода от Личного кабинета или данных необходимых для авторизации аккаунта в социальной сети «ВКонтакте» или
      аккаунта в социальной сети «Одноклассники» для доступа в Личный кабинет, а также любые расходы, ущерб и/или
      упущенную выгоду, возникшие у Заказчика в связи с несанкционированным доступом третьих лиц в Личный кабинет.
    </p>
    <p>
      7.8. За правильность указания реквизитов поездки и персональных данных Заказчика и/или Третьего лица полную
      ответственность несет Заказчик, оформляющий Заказ.
    </p>
    <p>
      7.9. Исполнитель не может гарантировать полное отсутствие неточностей в информации, предоставляемой Исполнителю
      Поставщиками услуг, в связи с чем не несет перед Заказчиком или Третьим лицом ответственности за любые ошибочные
      данные в информации, указанной в п. 3.1.3. Оферты, равно как и за причиненный Заказчику или Третьему лицу вред или
      убытки из-за наличия в размещаемой на Сайте или в Мобильном приложении информации.
    </p>
    <p>
      7.10. Исполнитель не несет ответственности за несоблюдение Поставщиками услуг или их представителями условий
      предоставления услуг по перевозке, услуг по временному размещению и проживанию, правил обслуживания и
      пунктуальности, так как это находится в исключительном ведении Поставщиков услуг. Поставщики услуг, представленные
      на Сайте и/или в Мобильном приложении, являются независимыми контрагентами и не являются агентами или сотрудниками
      Исполнителя.
    </p>
    <p>
      7.11. Исполнитель не отвечает за действия, ошибки, нарушения правил, гарантий или халатность Поставщика услуг, или
      любой из компаний, предоставляющих услуги по перевозке или временному размещению и проживанию, а также за
      возникшие в результате этих факторов вред здоровью, смерть, ущерб имуществу или другого рода моральный и
      материальный ущерб или затраты.
    </p>
    <p>
      7.12. Исполнитель не несет ответственность и не возмещает расходы в случае задержек или отмен рейсов, незаселения,
      забастовок, форс-мажорных обстоятельств и по любым другим поводам, не находящимся под непосредственным контролем
      Исполнителя. Также Исполнитель не несет ответственности за любые дополнительные расходы, недомолвки, задержки,
      изменения маршрутов, действия/бездействия органов государственной власти или иных уполномоченных служб и ведомств.
    </p>
    <p>
      7.13. Исполнитель не несет ответственности за качество и защищенность используемых Заказчиком каналов связи при
      использовании Сайта и/или Мобильного приложения, равно как и за любой ущерб, причиненный Заказчику в результате
      использования им некачественных или незащищенных каналов связи.
    </p>
    <h4>8. ФОРС-МАЖОР</h4>
    <p>
      8.1. Стороны освобождаются от ответственности за ненадлежащее исполнение или неисполнение обязательств в случае
      наступления обстоятельств непреодолимой силы, к которым относятся, но не ограничиваются перечисленным: пожар,
      наводнение, землетрясение и иные стихийные бедствия, военные действия, забастовка, террористический акт, акты
      государственных органов и иные обстоятельства, за которые Сторона не отвечает и которые невозможно предотвратить.
    </p>
    <p>
      8.2. При возникновении обстоятельств непреодолимой силы Сторона, ссылающаяся на такие обстоятельства, обязана
      письменно известить другую Сторону о наступлении указанных обстоятельств путем письменного уведомления в 3-дневный
      срок и доказать действие обстоятельств непреодолимой силы достоверными документами, выданными торгово-промышленной
      палатой или иным компетентным органом. В противном случае Сторона лишается права ссылаться на указанные
      обстоятельства как на основание освобождения ее от ответственности за неисполнение обязательств по Договору.
    </p>
    <p>
      8.3. При наступлении таких обстоятельств срок исполнения обязательств отодвигается соразмерно времени, в течение
      которого будут действовать такие обстоятельства.
    </p>
    <h4>9. РАЗРЕШЕНИЕ СПОРОВ</h4>
    <p>
      9.1. В случае возникновения претензий со стороны Заказчика он должен обратиться в службу поддержки по телефону,
      номер которого указан в соответствующем Приложении к Оферте, или предъявить претензию в письменной форме
      Исполнителю.
    </p>
    <p>
      9.2. Все споры Сторон по настоящему Договору подлежат рассмотрению в суде — по выбору истца по месту: нахождения
      Исполнителя; жительства или пребывания Заказчика; заключения или исполнения Договора.
    </p>
    <p>
      9.3. Признание судом недействительности какого-либо положения настоящего Договора не влечет за собой
      недействительность остальных его положений.
    </p>
    <h4>10. ЗАКЛЮЧИТЕЛЬНЫЕ ПОЛОЖЕНИЯ</h4>
    <p>
      10.1. Договор вступает в силу с момента акцепта Оферты в соответствии с разделом «Порядок заключения Договора»
      Оферты и действует в течение 1 (одного) года.
    </p>
    <p>10.2. К отношениям между Исполнителем и Заказчиком применяется законодательство Российской Федерации.</p>
    <p>
      10.3. Исполнитель и Заказчик пришли к соглашению, что Исполнитель не предоставляет Заказчику счета-фактуры на
      сумму Сервисного сбора Исполнителя.
    </p>
    <p>
      <strong>
        Осуществляя акцепт Оферты, Заказчик соглашается со всеми условиями Оферты. Производя оплату Заказа или при
        бронировании, если такая возможность предусмотрена Сайтом или Мобильным приложением, Заказчик соглашается с
        итоговой суммой к оплате, включая все налоги и сборы.
      </strong>
    </p>
    <h4>11. РЕКВИЗИТЫ ИСПОЛНИТЕЛЯ</h4>
    <p>
      <strong>Полное фирменное наименование:</strong>
    </p>
    <p>Общество с ограниченной ответственностью «МИР»</p>
    <p>
      <strong>Сокращенное фирменное наименование:</strong> ООО «МИР»
    </p>
    <p>
      <strong>Юридический адрес:</strong> 121108, г. Москва, ул. Герасима Курина, д. 10, к. 2, помещ. 1, ком. 20
      (кабинет 101)
    </p>
    <p>
      <strong>Почтовый адрес:</strong> 121108, г. Москва, ул. Герасима Курина, д. 10, к. 2, помещ. 1, ком. 20 (кабинет
      101)
    </p>
    <p>
      <strong>ОГРН:</strong> 1227700753466
    </p>
    <p>
      <strong>ИНН:</strong> 9731103632
    </p>
    <p>
      <strong>КПП:</strong> 772401001
    </p>
    <p>
      <strong>Банковские реквизиты: </strong>
      Банк: АО «Райффайзенбанк» р/с 40702810500000270339
    </p>
    <p>к/с 30101810200000000700</p>
    <p>БИК: 44525700</p>
    <p>КПП: 770401001</p>
    <p>ИНН: 7744000302</p>
    <a href="https://www.tutu.ru/2read/legal_information/general_oferta/#2">
      • Приложение № 1 (условия оформления заказов на электронный проездной документ на железнодорожный транспорт)
    </a>
    <h2>УСЛОВИЯ ОФОРМЛЕНИЯ ЗАКАЗОВ НА ЭЛЕКТРОННЫЙ ПРОЕЗДНОЙ ДОКУМЕНТ НА ЖЕЛЕЗНОДОРОЖНЫЙ ТРАНСПОРТ</h2>
    <h4>1.ТЕРМИНЫ И ОПРЕДЕЛЕНИЯ</h4>
    <p>
      Терминология и определения, использованные в настоящем Приложении № 1 к Оферте, применяются только в отношении
      настоящего Приложения № 1 к Оферте.
    </p>
    <p>
      <strong>Контрольный купон электронного железнодорожного билета (далее — «Контрольный купон»)</strong> — выписка из
      автоматизированной системы управления пассажирскими перевозками на железнодорожном транспорте, являющаяся
      документом строгой отчетности и применяющаяся для осуществления наличных денежных расчетов и (или) расчетов с
      использованием платежных карт без применения контрольно-кассовой техники.
    </p>
    <p>
      <strong>Пассажир</strong> — физическое лицо, в пользу которого заключен договор перевозки.
    </p>
    <p>
      <strong>Перевозчик</strong> — юридическое лицо или индивидуальный предприниматель, принявшие на себя по договору
      перевозки железнодорожным транспортом общего пользования обязанность доставить Заказчика и/или Пассажира из пункта
      отправления в пункт назначения.
    </p>
    <p>
      <strong>Пригородные экспрессы</strong> — скорые пригородные поезда, билеты на которые оформляются с
      предоставлением посадочного места или без предоставления посадочного места, в т.ч. возможна «гибридная» система
      оформления билетов на один поезд (т.е. возможно оформление билетов, в которых указано посадочное место и билетов
      без указания посадочного места). Для осуществления поездки на скором пригородном поезде прохождение регистрации,
      предусмотренной п. 3.13 настоящего Приложения № 1 к Оферте, не требуется.
    </p>
    <p>
      <strong>Электронный проездной документ на железнодорожный транспорт (далее — «Электронный билет») </strong> —
      документ, используемый для удостоверения договора перевозки пассажира в дальнем следовании или в пригородном
      сообщении, в котором информация о железнодорожной перевозке пассажира представлена в электронно-цифровой форме и
      содержится в автоматизированной системе управления пассажирскими перевозками на железнодорожном транспорте.
    </p>
    <h4>2. ОБЩИЕ ПОЛОЖЕНИЯ.</h4>
    <p>
      2.1. Исполнитель в соответствии с соглашениями с Перевозчиками или соглашениями с агентами Перевозчиков с помощью
      Сайта и/или Мобильного приложения оказывает Заказчику следующие услуги:
    </p>
    <ul>
      <li>
        предоставление, полученной от Перевозчиков / агентов Перевозчиков информации, указанной в п. 2.5. настоящего
        Приложения № 1 к Оферте;
      </li>
      <li>
        оформление Исполнителем Электронного билета в соответствии с заданными Заказчиком параметрами (дате, времени,
        маршруте, количеству пассажиров и иных условиях перевозки);
      </li>
      <li>прием платежа за Электронный билет;</li>
      <li>
        оформление процедуры возврата Электронного билета, если возврат Электронного билета предусмотрен условиями
        тарифа.
      </li>
    </ul>
    <p>
      2.2. Правоотношения, возникающие при оформлении (возврате) Электронного билета, регулируются положениями ГК РФ,
      положениями Приказа Минтранса РФ от 21 августа 2012 г. N 322 «Об установлении форм электронных проездных
      документов (билетов) на железнодорожном транспорте», Приказа Министерства транспорта РФ от 5 сентября 2022 г. N
      352 «Об утверждении Правил перевозок пассажиров, багажа, грузобагажа железнодорожным транспортом», а также иными
      правовыми актами РФ, регулирующими перевозку пассажиров железнодорожным транспортом.
    </p>
    <p>
      2.3. Принимая условия Оферты, Заказчик подтверждает свое понимание, что Исполнитель не является Перевозчиком, и
      все договоры о предоставлении услуги Перевозчика Заказчик и/или Пассажир заключает напрямую с поставщиками услуг
      перевозки — Перевозчиками, и всю ответственность за предоставление услуги Перевозчика перед Заказчиком и/или
      Пассажиром в том числе, но, не ограничиваясь: ответственность за задержку, отмену и/или перенос отправления
      поезда, за уровень обслуживания в поездах, несет Перевозчик. Договор перевозки, заключаемый Заказчиком и/или
      Пассажиром с Перевозчиком, удостоверяется Электронным билетом.
    </p>
    <p>
      2.4. Исполнитель вправе взимать Сервисный сбор за оказание Заказчику услуг, указанных в п. 2.1. Оферты. Сервисный
      сбор включается в окончательную стоимость Заказа и не подлежит возврату Заказчику ни при каких обстоятельствах, в
      том числе при последующем возврате Электронного билета вне зависимости от причин возврата, если иное не
      предусмотрено маркетинговыми акциями или не предусмотрено действующим законодательством Российской Федерации.
      Исполнитель имеет безусловное право менять величину Сервисного сбора.
    </p>
    <p>
      Сервисный сбор за предоставление Исполнителем Заказчику технической возможности оплатить позднее стоимость Заказа
      на Сайте и/или в Мобильном приложении не включается в общую стоимость Заказа, возврату не подлежит, при этом его
      размер отображается при выборе Заказчиком варианта оплаты Заказа на Сайте или в Мобильном приложении в режиме
      онлайн.
    </p>
    <p>
      При возврате электронного проездного документа на железнодорожном транспорте ранее удержанные Сервисные сборы
      Исполнителя возврату не подлежат (за исключением случая, предусмотренного п. 4.5 настоящего Приложения № 1 к
      Оферте). Сервисный сбор включает в себя НДС согласно ставке, установленной в Налоговом кодексе Российской
      Федерации.
    </p>
    <p>
      2.5. Информация о расписании поездов, наличии мест, номерах мест и иная информация об условиях предоставления
      услуги Перевозчика на Сайте и/или в Мобильном приложении представлена в том виде, в котором она была предоставлена
      от поставщиков услуг — Перевозчиков / агентов Перевозчиков.
    </p>
    <h4>3. ОФОРМЛЕНИЕ, ОПЛАТА И ПОДТВЕРЖДЕНИЕ ЗАКАЗА.</h4>
    <p>
      3.1. При оформлении Заказа Заказчик должен заполнить все поля, указанные как «обязательные» для заполнения на
      Сайте или в Мобильном приложении.
    </p>
    <p>
      3.2. Заказчик самостоятельно определяет параметры (даты, маршрут, класс, места и иные условия) заказываемых
      Электронных билетов, вносит личные данные свои или Пассажира, проверяет корректность внесенной информации.
      Заказчик самостоятельно несет все риски, связанные с неуказанием и/или ненадлежащим указанием персональных данных
      своих или Пассажира, а также с ненадлежащим уведомлением Пассажиров о заключении и/или изменении условий договора
      перевозки.
    </p>
    <p>
      3.3. Персональные данные Заказчика или Пассажира должны соответствовать данным, указанным в документе,
      удостоверяющем его личность. Если персональные данные в документе указаны на двух языках (русском и латинском),
      информация в Заказе заполняется на русском языке при проезде во внутрироссийском сообщении, в сообщении со
      странами СНГ, Латвии, Литвы, Эстонии, Абхазии. Информация в Заказе заполняется на латинском языке при проезде в
      международном сообщении (дальнее зарубежье).
    </p>
    <p>
      При оформлении Электронных билетов свидетельство о рождении используется только для граждан Российской Федерации в
      возрасте до 14 лет.
    </p>
    <p>
      В случае обнаружения ошибки в персональных данных Заказчика или Пассажира, или реквизитах документов,
      удостоверяющих их личность, Заказчик должен до момента оплаты Заказа ввести исправленную информацию.
    </p>
    <p>
      3.4. Заказчик подтверждает достоверность своих персональных данных, а также данных Пассажира, указанных в Заказе,
      и принимает на себя всю ответственность за их точность, полноту и достоверность. Заказчик принимает на себя все
      возможные риски (оформление нового заказа, изменение тарифа, возврат денег и проч.), связанные с его виновными
      действиями по допущению ошибок, неточностей в предоставлении личных данных и/или данных Пассажира, а также с
      ненадлежащим уведомлением Пассажира о заключении и/или изменении условий перевозки.
    </p>
    <p>
      3.5. Заказчик обязан ознакомиться с условиями настоящей Оферты, условиями возврата Электронного билета, условиями
      тарифа, в том числе со специальными условиями возврата, до перехода на Сайте или в Мобильном приложении к шагу
      оплаты.
    </p>
    <p>
      3.6. Если Заказчик считает, что на Сайте или в Мобильном приложении при оформлении Заказа предоставленной
      информации недостаточно, он обязан связаться со службой поддержки по телефону: +7 926 737 7777.
    </p>
    <p>
      3.7. Для завершения оформления Заказа Заказчику необходимо определить варианты и способы оплаты стоимости своего
      Заказа, предложенные на Сайте или в Мобильном приложении.При выборе Заказчиком варианта оплаты «Оплата позднее»
      Заказчик оплачивает Сервисный сбор за предоставление Исполнителем Заказчику технической возможности оплаты
      стоимости Заказа позднее. В течение 24 (двадцати четырех) часов после оплаты Заказчиком Сервисного сбора за
      предоставление Исполнителем технической возможности оплаты Заказа позднее, Исполнитель направляет на адрес
      электронной почты Заказчика, указанный при оформлении Заказа, письмо с информацией о необходимости оплатить
      стоимость Заказа с указанием даты и времени срока оплаты стоимости Заказа, суммы денежных средств, которую
      необходимо оплатить (далее – «Письмо»).
    </p>
    <p>
      Если стоимость Заказа не оплачена Заказчиком в срок, указанный в Письме, Заказ отменяется. Сервисный сбор за
      предоставление Исполнителем Заказчику технической возможности оплаты стоимости Заказа позднее возврату не
      подлежит.
    </p>
    <p>
      3.8. Общая стоимость Заказа отображается при оформлении Заказа на Сайте или в Мобильном приложении в режиме онлайн
      и включает в себя стоимость Электронного билета и Сервисный сбор (возможно отсутствие такого сбора). При оплате
      Заказчиком Заказа Заказчик и/или Пассажир вступают в гражданско-правовые отношения, вытекающие из договора
      перевозки, с Перевозчиком.
    </p>
    <p>
      3.9. Производя оплату Заказа или оплату Сервисного сбора за предоставление Исполнителем Заказчику технической
      возможности оплаты стоимости Заказа позднее, Заказчик подтверждает правильность внесения своих персональных
      данных, а также данных Пассажира, собственное ознакомление с информацией, размещенной на Сайте и/или в Мобильном
      приложении, выражает согласие с окончательной стоимостью Заказа, включающей Сервисный сбор, условиями Оферты,
      условиями возврата Электронного билета, условиями тарифа, с условиями договора перевозки.
    </p>
    <p>
      3.10. В течение двух часов после оплаты Заказчиком Заказа, Исполнитель направляет на адрес электронной почты,
      указанный при оформлении Заказа, Контрольный купон.
    </p>
    <p>
      3.11. После совершения оплаты Заказчиком Сервисного сбора за предоставление Исполнителем Заказчику технической
      возможности оплаты стоимости Заказа позднее или оплаты Заказчиком Заказа с помощью банковской карты, возвратного
      сертификата, зачетом аванса в счет оплаты стоимости Заказа с использованием единого подарочного сертификата
      Исполнитель направляет кассовый чек в электронной форме Заказчику на предоставленный им до момента расчета адрес
      электронной почты или сведения в электронной форме, идентифицирующие кассовый чек, и информацию об адресе
      информационного ресурса, который размещен в сети Интернет и по которому кассовый чек может быть бесплатно получен
      Заказчиком.
    </p>
    <p>
      3.11.1. Отчетные документы на имя Заказчика в форме универсального передаточного документа со статусом 2 —
      передаточный документ (акт) (по форме приложения 1 к постановлению Правительства РФ от 26.12.2011 г. № 1137)
      Исполнитель направляет на адрес электронной почты, указанный Заказчиком при оформлении Заказа, после совершения
      Заказчиком оплаты Сервисного сбора.
    </p>
    <p>
      3.12. Договор перевозки считается заключенным, а обязанности Исполнителя по Договору — выполненными, с момента
      оформления Электронного билета и отправления Исполнителем на адрес электронной почты, указанный Заказчиком при
      оформлении Заказа, Контрольного купона.
    </p>
    <p>
      3.13. Для осуществления проезда на железнодорожном транспорте и/или провозки багажа, Заказчик и/или Пассажир
      обязаны пройти регистрацию в кассах Перевозчика либо на Сайте или в Мобильном приложении. После прохождения
      процедуры электронной регистрации на Сайте или в Мобильном приложении Заказчик получает на адрес электронной
      почты, указанный Заказчиком при оформлении Заказа, посадочный купон Электронного билета. При оформлении
      Электронного билета на Пригородные экспрессы процедура электронной регистрации производится автоматически.
    </p>
    <h4>4. ПОРЯДОК ВОЗВРАТА ЭЛЕКТРОННЫХ БИЛЕТОВ.</h4>
    <p>
      4.1. Изменение и обмен Электронного билета не осуществляются. Если возникла такая необходимость, то Заказчик
      должен осуществить возврат Электронного билета, если возврат Электронного билета предусмотрен условиями тарифа, и
      создать новый Заказ.
    </p>
    <p>
      4.2. Заказчик или Пассажир осуществляют оформление возврата Электронных билетов, заказанных и оплаченных с помощью
      Сайта или Мобильного приложения в соответствии с условиями тарифа, правилами возврата проездных документов ОАО
      «РЖД», Правилами перевозок пассажиров, багажа, грузобагажа железнодорожным транспортом (утв. приказом Министерства
      транспорта РФ от 05 сентября 2022 г. № 352), а также другими законодательными актами Российской Федерации.
    </p>
    <p>
      4.3. Возврат денежных средств за неиспользованные Электронные билеты и/или возврат Электронного билета,
      оплаченного возвратным сертификатом или зачетом аванса в счет оплаты стоимости Заказа при использовании единого
      подарочного сертификата осуществляются при обращении Заказчика или Пассажира в железнодорожную билетную кассу на
      территории Российской Федерации или Заказчиком с помощью сервиса возврата Электронных билетов на Сайте или в
      Мобильном приложении, если возврат Электронных билетов предусмотрен условиями тарифа.
    </p>
    <p>
      4.4. Оформление возврата Электронных билетов на Сайте или в Мобильном приложении возможно, только если Заказ был
      оплачен банковской картой, возвратным сертификатом, зачетом аванса в счет оплаты стоимости Заказа с использованием
      единого подарочного сертификата или промокодом. В дополнение к сборам, установленным условиями ОАО «РЖД»,
      Исполнитель взимает Сервисный сбор за оформление возврата Электронного билета, который удерживается из сумм,
      подлежащих возврату.
    </p>
    <p>
      4.4.1. Стороны пришли к соглашению, что отмена Заказа может быть произведена посредством выдачи Исполнителем
      Заказчику возвратного сертификата на оплату электронных железнодорожных или электронных авиабилетов или
      электронных билетов на автобус и услуг Исполнителя, указанных в п. 2.1. Оферты. С правилами использования
      возвратных сертификатов можно ознакомиться по ссылке: <a href="https://www.kokoc.ru/">https://www.kokoc.ru/</a>.
    </p>
    <p>
      4.5. Возврат Электронного билета производится на Сайте или в Мобильном приложении только до момента получения
      Заказчиком и/или Пассажиром проездных документов, оформленных на бланке ОАО «РЖД». После получения посадочного
      купона на бланке проездного документа в железнодорожной билетной кассе или терминале самообслуживания возврат
      Электронного билета на Сайте или в Мобильном приложении невозможен. Для оформления такого возврата следует
      обратиться в железнодорожную билетную кассу.
    </p>
    <p>
      4.6. Чтобы сдать Электронный билет на Сайте или в Мобильном приложении, Заказчику необходимо зайти в Личный
      кабинет, выбрать способ возврата (на банковскую карту или посредством выдачи Исполнителем возвратного сертификата
      на оплату электронных железнодорожных или электронных авиабилетов, а также электронных билетов на автобус и услуг
      Исполнителя, указанных в п. 2.1. Оферты) и нажать на кнопку «Оформить возврат». С правилами использования
      возвратных сертификатов можно ознакомиться по ссылке: <a href="https://www.kokoc.ru/">https://www.kokoc.ru/</a>.
    </p>
    <p>
      4.6.1. При оплате Заказчиком Электронного билета банковской картой, деньги вернутся на банковскую карту, с которой
      была произведена оплата, в течение 30 (тридцати) календарных дней.
    </p>
    <p>
      4.6.2. При оплате Заказчиком Электронного билета зачетом аванса в счет оплаты стоимости Заказа с использованием
      единого подарочного сертификата будет оформлен новый единый подарочный сертификат, при этом из номинальной
      стоимости единого подарочного сертификата удерживаются сервисные сборы Исполнителя и сборы, установленные
      условиями ОАО «РЖД». Если часть стоимости Электронного билета была оплачена зачетом аванса в счет оплаты стоимости
      Заказа с использованием единого подарочного сертификата, а часть — банковской картой, доплаченные деньги вернутся
      на банковскую карту, а часть, оплаченная зачетом аванса в счет оплаты стоимости Заказа с использованием единого
      подарочного сертификата, будет возмещена единым подарочным сертификатом. В этом случае сборы, установленные
      условиями ОАО «РЖД», и сервисные сборы Исполнителя удерживаются из номинальной стоимости единого подарочного
      сертификата. Таким образом новый единый подарочный сертификат будет оформлен номинальной стоимостью, уменьшенной
      на сумму сборов, установленных условиями ОАО «РЖД», и сервисных сборов Исполнителя. В случае если номинальной
      стоимости единого подарочного сертификата недостаточно для удержания сборов, установленных условиями ОАО «РЖД», и
      сервисных сборов Исполнителя, то соответствующие суммы удерживаются из сумм, подлежащих возврату на банковскую
      карту.
    </p>
    <p>
      4.6.3. При оплате Заказчиком Электронного билета с использованием возвратного сертификата будет оформлен новый
      возвратный сертификат, при этом из номинальной стоимости возвратного сертификата удерживаются сервисные сборы
      Исполнителя и сборы, установленные условиями ОАО «РЖД». Если часть стоимости Электронного билета была оплачена с
      использованием возвратного сертификата, а часть — банковской картой, доплаченные деньги вернутся на банковскую
      карту, а часть, оплаченная с использованием возвратного сертификата, будет возмещена возвратным сертификатом. В
      этом случае сборы, установленные условиями ОАО «РЖД», и сервисные сборы Исполнителя удерживаются из номинальной
      стоимости возвратного сертификата. Таким образом новый возвратный сертификат будет оформлен номинальной
      стоимостью, уменьшенной на сумму сборов, установленных условиями ОАО «РЖД», и сервисных сборов Исполнителя. В
      случае если номинальной стоимости возвратного сертификата недостаточно для удержания сборов, установленных
      условиями ОАО «РЖД», и сервисных сборов Исполнителя, то соответствующие суммы удерживаются из сумм, подлежащих
      возврату на банковскую карту.
    </p>
    <p>
      4.6.4. При возврате Заказчиком Электронного билета, оплаченного промокодом, новый промокод не выдается,
      использованный промокод сгорает. Если часть стоимости Электронного билета была оплачена промокодом, а часть —
      банковской картой, доплаченные деньги вернутся на банковскую карту, а часть, оплаченная промокодом, возвращена не
      будет, при этом сборы, установленные условиями ОАО «РЖД», и сервисные сборы Исполнителя удерживаются из сумм,
      подлежащих возврату на банковскую карту.
    </p>
    <p>
      4.6.5. Если в одном Заказе оформлено несколько Электронных билетов, Заказчик может вернуть их все вместе или
      только некоторые из них.
    </p>
    <p>
      4.6.6. Если электронная регистрация Заказчиком и/или Пассажиром не пройдена — оформить возврат Электронного билета
      на Сайте или в Мобильном приложении можно не позже отправления поезда от станции посадки Заказчика и/или
      Пассажира.
    </p>
    <p>
      4.6.7. Если Заказчиком и/или Пассажиром пройдена электронная регистрация — оформить возврат Электронного билета на
      Сайте или в Мобильном приложении можно не позднее времени, указанного в Контрольном купоне Электронного билета,
      либо оформить возврат Электронного билета можно в кассе ОАО «РЖД».
    </p>
    <p>
      4.7. Возврат Электронного билета в железнодорожную билетную кассу на территории Российской Федерации оформляется
      Пассажиром, на кого был оформлен Электронный билет, при этом возврат денежных средств за неиспользованный
      Электронный билет, оформленный по безналичному расчету или с использованием платежной карты, производится на
      банковский счет физического лица, оплатившего Электронный билет.
    </p>
    <p>
      4.8. Если при оформлении Заказа Заказчик выбрал дополнительную услугу 100%-ный возврат, предоставляющую Заказчику
      специальные условия возврата, то при Возврате Электронного билета, оформленного Заказчиком с услугой 100%-ный
      возврат, Заказчику вернется уплаченная им стоимость за такой Электронный билет в 100%-ном размере (включая
      Сервисный сбор Исполнителя) за вычетом суммы, уплаченной за саму дополнительную услугу. Специальные условия
      возврата применяются только в случае выбора Заказчиком такой опции при оформлении Заказа. Заказчик вправе оформить
      возврат Электронного билета в порядке, предусмотренном п. 4.4. настоящего Приложения № 1 к Оферте. Специальные
      условия возврата не применяются в случаях, когда поездка не состоялась из-за ограничений, введенных органами
      государственной власти.
    </p>
    <h4>5. ПЕРСОНАЛЬНЫЕ ДАННЫЕ.</h4>
    <p>
      5.1. При осуществлении Заказчиком совокупности действий на Сайте или в Мобильном приложении:
      <ul>
        <li>
          оформления Заказа на Сайте или в Мобильном приложении на получение услуг, определенных в настоящем Приложении
          № 2 к Оферте;
        </li>
        <li>совершение акцепта Оферты Заказчиком, будут обрабатываться следующие персональные данные субъекта:</li>
        <li>Фамилия, Имя, Отчество;</li>
        <li>Пол;</li>
        <li>Дата рождения;</li>
        <li>Реквизиты документа, удостоверяющего личность (вид документа, государство выдачи, серия и номер);</li>
        <li>Электронная почта;</li>
        <li>Номер телефона;</li>
        <li>Место работы;</li>
        <li>Сведения о поездке.</li>
      </ul>
    </p>
    <p>
      5.2. При выполнении условий Договора Исполнителем будет осуществляться передача персональных данных субъекта
      третьим лицам, в целях:
      <ul>
        <li>
          заключение договора (Оферты) и сопровождение заключенного договора (Оферты), оказание Исполнителем Заказчику
          услуг, указанных в Оферте;
        </li>
        <li>рассылка информационных сообщений;</li>
        <li>сопровождение и администрирование Сайта или Мобильного приложения;</li>
        <li>сопровождение и администрирование информационных систем;</li>
        <li>
          обработка входящих и исходящих обращений Заказчика (звонки, письма и мессенджеры) для консультирования
          Заказчика;
        </li>
        <li>внесение изменений в Заказ Заказчика (возврат билетов);</li>
        <li>предоставление оригиналов отчетных документов Заказчику;</li>
        <li>сбор информации и/или информирование Заказчика об изменениях в Заказе;</li>
        <li>хранение информации для повторных заказов;</li>
        <li>предоставление доступа к Личному кабинету;</li>
        <li>обработка претензий и исков.</li>
      </ul>
      Заказчик вправе пройти опрос после поездки, если Заказчик воспользуется данным правом, то Исполнителем будет
      осуществляться передача персональных данных Заказчика третьим лицам в целях прохождение опроса и публикация
      отзывов на Сайте.
    </p>
    <p>
      Заказчик вправе пройти интервьюирование, для исполнения этой цели Исполнителем будет осуществляться передача
      персональных данных Заказчика третьим лицам в целях организации интервьюирования.
    </p>
    <p>
      5.3. Исполнитель обязуется не разглашать полученные от Заказчика персональные данные, за исключением случаев
      передачи персональных данных третьим лицам в целях, указанных в п. 5.2. Приложения № 1 к Оферте.
    </p>
    <h4>6. ИНЫЕ УСЛОВИЯ.</h4>
    <p>
      6.1. В случае возникновения претензий со стороны Заказчика он должен обратиться в службу поддержки по телефону: +7
      926 737 7777 предъявить претензию в письменной форме Исполнителю в соответствии с разделом 8 Оферты.
    </p>
    <p>
      6.2. При осуществлении Заказчиком совокупности действий на Сайте или в Мобильном приложении:
      <ul>
        <li>
          оформления Заказа на Сайте или в Мобильном приложении на получение услуг, определенных в настоящем Приложении
          № 1 к Оферте;
        </li>
        <li>совершение акцепта Оферты Заказчиком,</li>
      </ul>
      Заказчик подтверждает, что ознакомлен с правилами электронной регистрации, правилами возврата Электронных билетов
      при электронной регистрации, размещенных на Сайте в разделе «Справочная» по адресу:{' '}
      <a href="https://www.kokoc.ru/">https://www.kokoc.ru/</a> , с правилами возврата Электронных билетов, размещенных
      на Сайте в разделе «Справочная» по адресу: <a href="https://www.kokoc.ru/">https://www.kokoc.ru/</a> , и довел
      указанную информацию до Пассажира.
    </p>
  </CustomModal>
);
