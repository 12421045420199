import axios from 'axios';
import { baseUrl } from '../config';

export const CounriesApi = {
  fetchCities: async params => {
    const { data } = await axios.get(`${baseUrl}/api/info/get_cities?`, {
      params: {
        city_name: params.city_name,
      },
      headers: {
        'ngrok-skip-browser-warning': '69420',
      },
    });

    return data;
  },
};
