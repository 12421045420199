import { Link } from 'react-router-dom';
import logo from '../../Assets/logo.png';
import '../../Styles/Components/Header.scss';
import { baseUrl } from '../../config';
import { Box, Button, Stack } from '@mui/material';
import MainContainer from '../MainContainer/MainContainer';

export const Header = () => {
  return (
    <Box
      component="header"
      paddingTop={'24px'}
      padding={'24px 16px 24px 16px'}
      borderBottom={{ xs: '1px solid rgba(0,0,0, 0.1)', md: 'none' }}
    >
      <MainContainer>
        <Link to={'/'}>
          <img src={logo} alt="logo" width={'116px'} />
        </Link>
        <Button variant="outlined" size="large" color="primary" href={`${baseUrl}/pay/Railway/return_request`}>
          Возврат билетов
        </Button>
      </MainContainer>
    </Box>
  );
};
