import { useEffect, useRef } from 'react';
import '../../Styles/Components/LiveSearchInput.scss';

export const LiveSearchDropdown = ({
  responseData, //Список элементов
  showDropdown, // Показать/Скрыть dropdown (состояние)
  onItemClickHandler, // Функция срабатывающая при нажатии на элемент списка
  onClickOutside, // Показать/Скрыть dropdown (функция)
  labelKey,
  valueKey,
}) => {
  const ref = useRef(null);

  //Добавление event-listener'а для закрытия Dropdown городов
  useEffect(() => {
    const handleClickOutside = event => {
      if (ref.current && !ref.current.contains(event.target)) {
        onClickOutside && onClickOutside(false);
      }
    };
    document.addEventListener('click', handleClickOutside, true);
    return () => {
      document.removeEventListener('click', handleClickOutside, true);
    };
  }, [onClickOutside]);

  return (
    JSON.stringify(responseData) && (
      <div ref={ref} className={'responseDropDown ' + (showDropdown ? 'open' : 'close')}>
        <div className={responseData.length > 5 ? 'overflowed' : 'full'}>
          {responseData.map((responseItem, i) => (
            <div
              onClick={() => onItemClickHandler(responseItem)}
              className="responseItem col-12 justify-content-start"
              key={i}
            >
              {responseItem[labelKey]}
            </div>
          ))}
        </div>
      </div>
    )
  );
};
