import { useEffect, useLayoutEffect, useState } from 'react';
import { Box, Button, Grid2, Skeleton, Stack, Tooltip, Typography } from '@mui/material';
import { TrainCard } from '../../Components-2.0/TrainCard/TrainCard';
import { Link, Outlet, useLocation, useNavigate, useParams } from 'react-router-dom';
import {
  $localTrainInfoFromStore,
  $localTrainInfoReturnFromStore,
  $localCarPagePlaceStore,
  $placeReservationTypeStore,
  $compartmentsForReservation,
  $isReturnPageStore,
  setCarInfoIfReturn,
  setErrorMessage,
  setIsReturnPage,
  resetValidationForm,
  setReservationItemsForward,
  setReservationItemsBackward,
} from '../../Services/trainsService';
import { useStore } from 'effector-react';
import { baseUrl } from '../../config';
import axios from 'axios';
import MainContainer from '../../Components-2.0/MainContainer/MainContainer';
import styled from 'styled-components';
import Bedclothes from '../../Assets/serviceIcons/Bedclothes.png';
import InfotainmentService from '../../Assets/serviceIcons/InfotainmentService.png';
import Meal from '../../Assets/serviceIcons/Meal.png';
import Pets from '../../Assets/serviceIcons/Pets.png';
import RestaurantCarOrBuffet from '../../Assets/serviceIcons/RestaurantCarOrBuffet.png';

const CarsButton = styled(Button)`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  background-color: #fffaf2;
  border-radius: 20px;
  &:hover {
    border: 1px solid #ff7517;
  }
`;

export const CarsPage = () => {
  const [isLoaded, setIsLoaded] = useState(false);
  const [responseCars, setResponseCars] = useState();
  const [totalPrice, setTotalPrice] = useState(0);
  const [isBedClothes, setIsBedClothes] = useState(true);
  const params = useParams();
  const navigate = useNavigate();
  const { state } = useLocation();
  const { cityFrom, cityTo, dateReturn, dateTo, destination, origin } = state;
  const [currentCar, setCurrentCar] = useState(Number(params.CarId));
  const trainData = useStore($localTrainInfoFromStore);
  const trainReturnData = useStore($localTrainInfoReturnFromStore);
  const localPagePlaces = useStore($localCarPagePlaceStore);
  const compartmentsStore = useStore($compartmentsForReservation);
  const reservationType = useStore($placeReservationTypeStore);
  const isReturnPage = useStore($isReturnPageStore);

  const getCars = () => {
    const json = JSON.stringify(params);
    try {
      axios
        .post(`${baseUrl}/api/Railway/get_cars`, json, {
          headers: {
            'Content-Type': 'application/json',
          },
        })
        .then(response => {
          setResponseCars(response.data);
          setIsLoaded(true);
        })
        .catch(error => {
          setErrorMessage(error);
          navigate(-1);
        });
    } catch (error) {
      throw error;
    }
  };

  const handleSubmit = () => {
    resetValidationForm();
    if (dateReturn !== null) {
      if (isReturnPage === false) {
        setIsReturnPage(true);
        setReservationItemsForward({
          Origin: params.Origin,
          Destination: params.Destination,
          DepartureDate: params.DepartureDate,
          Provider: params.Provider,
          AllowedDocumentTypes: responseCars.AllowedDocumentTypes,
          Car: { ...responseCars.Cars[currentCar], isBedClothes: isBedClothes },
        });

        setCarInfoIfReturn(responseCars.Cars[currentCar]);
        navigate(`/trains/from/${destination}/to/${origin}/date/${dateReturn}`, {
          state: {
            cityFrom: cityTo,
            cityTo: cityFrom,
            cityFromCode: destination,
            cityToCode: origin,
            dateTo: dateReturn,
            dateReturn: dateTo,
          },
        });
      } else {
        setReservationItemsBackward({
          Origin: params.Origin,
          Destination: params.Destination,
          DepartureDate: params.DepartureDate,
          Provider: params.Provider,
          AllowedDocumentTypes: responseCars.AllowedDocumentTypes,
          Car: { ...responseCars.Cars[currentCar], isBedClothes: isBedClothes },
        });

        navigate('/passengerForm', {
          state: {
            CarParams: params,
            CarInfo: responseCars.Cars[currentCar],
            Compartments: compartmentsStore,
            PlaceReservationType: reservationType,
            totalPrice: totalPrice,
            Discounts: responseCars.Cars[currentCar].Discounts,
            HasElectronicRegistration: responseCars.Cars[currentCar].HasElectronicRegistration,
          },
        });
      }
    } else {
      setReservationItemsForward({
        Origin: params.Origin,
        Destination: params.Destination,
        DepartureDate: params.DepartureDate,
        Provider: params.Provider,
        AllowedDocumentTypes: responseCars.AllowedDocumentTypes,
        Car: { ...responseCars.Cars[currentCar], isBedClothes: isBedClothes },
      });
      navigate('/passengerForm', {
        state: {
          CarParams: params,
          CarInfo: responseCars.Cars[currentCar],
          Compartments: compartmentsStore,
          PlaceReservationType: reservationType,
          totalPrice: totalPrice,
        },
      });
    }
  };

  useEffect(() => {
    getCars();
  }, []);

  useEffect(() => {
    let sum = 0;

    if (reservationType === 'TwoPlacesAtOnce' || reservationType === 'FourPlacesAtOnce') {
      if (localPagePlaces.length > 0) {
        sum += localPagePlaces[0].MinPrice + localPagePlaces[0].ServiceCost;
      }
    } else {
      localPagePlaces.map(page => {
        sum += page.MinPrice + page.ServiceCost;
      });
    }

    setTotalPrice(sum.toFixed(2));
  }, [localPagePlaces.length]);

  useLayoutEffect(() => {
    if (responseCars) {
      setIsBedClothes(responseCars.Cars[currentCar].IsBeddingSelectionPossible ? true : null);
    }
  }, [responseCars]);

  useEffect(() => {
    setCurrentCar(Number(params.CarId));
  }, [params.CarId]);

  const carTypeName = CarType => {
    switch (CarType) {
      case 'СВ':
        return 'СВ';
      case 'ЛЮКС':
        return 'Люкс';
      case 'КУПЕ':
        return 'Купе';
      case 'ПЛАЦ':
        return 'Плацкарт';
      case 'СИД':
        return 'Сидячие';
      case 'ОБЩ':
        return 'Общий';
    }
  };

  const getService = description => {
    switch (description) {
      case 'Meal':
        return { src: Meal, description: 'Питание' };
      case 'RestaurantCarOrBuffet':
        return { src: RestaurantCarOrBuffet, description: 'Вагон-ресторан или буфет' };
      case 'InfotainmentService':
        return { src: InfotainmentService, description: 'Развлекательные услуги' };
      case 'Bedclothes':
        return { src: Bedclothes, description: 'Постельное белье' };
      case 'Pets':
        return { src: Pets, description: 'Место рядом с питомцем' };
      default:
        return { src: '', description: '' };
    }
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <Stack direction={'column'} mb={{ xs: 7.5, md: 15 }}>
      <Box marginTop={1.25} marginBottom={{ xs: 4, md: 15 }} sx={{ padding: '0 20px' }}>
        <TrainCard trainData={isReturnPage ? trainReturnData : trainData} />
      </Box>
      {isLoaded ? (
        <>
          <Box marginBottom={{ xs: 3.75, md: 7.5 }} sx={{ padding: '0 20px' }}>
            <MainContainer justifyContent="center">
              <Typography fontSize={{ xs: 24, md: 48, lg: 70 }}>
                Выберите <strong>вагон</strong>
              </Typography>
            </MainContainer>
          </Box>
          <Box marginBottom={{ xs: 3.75, md: 7.5 }} sx={{ padding: '0 20px' }}>
            <MainContainer>
              <Grid2 container spacing={{ xs: 2, md: 5 }} columns={{ xs: 4, sm: 12, md: 16 }} width={'100%'}>
                {responseCars.Cars.sort((a, b) => (Number(a.CarNumber) > Number(b.CarNumber) ? 1 : -1)).map(
                  (car, index) => (
                    <Grid2 size={{ xs: 2, sm: 4, md: 5, lg: 4 }} key={index} display={'flex'} justifyContent={'center'}>
                      <Link to={`${index}`} state={state} style={{ textDecoration: 'none', color: 'inherit' }}>
                        <CarsButton
                          variant="text"
                          sx={{
                            width: { xs: '151px', md: '260px' },
                            height: { xs: '90px', md: '126px' },
                            paddingLeft: { xs: '20px', md: '40px' },
                            color: 'black',
                            border: currentCar === index ? '1px solid #ff7517' : 'none',
                          }}
                          onClick={() => setCurrentCar(index)}
                        >
                          <Typography fontSize={16}>Вагон: {car.CarNumber}</Typography>
                          <Typography fontSize={20} fontWeight={'bold'}>
                            Мест {car.PlaceQuantity}
                          </Typography>
                        </CarsButton>
                      </Link>
                    </Grid2>
                  ),
                )}
              </Grid2>
            </MainContainer>
          </Box>
          <Box marginBottom={{ xs: 3, md: 7 }} sx={{ padding: '0 20px' }}>
            <MainContainer justifyContent="center">
              <Typography fontSize={{ xs: 24, md: 48, lg: 70 }}>
                Выберите <strong>места</strong>
              </Typography>
            </MainContainer>
          </Box>
          <Box sx={{ padding: '0 20px' }}>
            <MainContainer direction="column" alignItems="start">
              <Grid2 container marginBottom={5} spacing={2} justifyContent={'space-between'} width={'100%'}>
                <Grid2 item container spacing={{ xs: 3, md: 10, lg: 15 }}>
                  <Grid2 item>
                    <Typography fontSize={{ xs: 12, md: 16 }}>Вагон</Typography>
                    <Typography fontSize={{ xs: 16, md: 20 }} fontWeight={'bold'}>
                      {responseCars.Cars[currentCar].CarNumber}
                    </Typography>
                  </Grid2>
                  <Grid2 item display={'flex'} alignItems={'flex-end'}>
                    <Typography fontSize={{ xs: 16, md: 20 }} fontWeight={'bold'}>
                      {responseCars.Cars[currentCar].Carrier}
                    </Typography>
                  </Grid2>
                  <Grid2 item>
                    <Typography fontSize={{ xs: 12, md: 16 }}>Тип вагона</Typography>
                    <Typography fontSize={{ xs: 16, md: 20 }} fontWeight={'bold'}>
                      {carTypeName(responseCars.Cars[currentCar].CarTypeName)}
                    </Typography>
                  </Grid2>
                  <Grid2 item>
                    <Typography fontSize={{ xs: 12, md: 16 }}>Класс обслуживания</Typography>
                    <Typography fontSize={{ xs: 16, md: 20 }} fontWeight={'bold'}>
                      {responseCars.Cars[currentCar].ServiceClass}
                    </Typography>
                  </Grid2>
                  <Grid2 item display={'flex'} alignItems={'flex-end'}>
                    <Tooltip title="Электронная регистрация">
                      <Typography fontSize={{ xs: 16, md: 20 }} fontWeight={'bold'}>
                        {responseCars.Cars[currentCar].HasElectronicRegistration ? 'ЭР' : 'Нет ЭР'}
                      </Typography>
                    </Tooltip>
                  </Grid2>
                </Grid2>
                <Grid2 item>
                  <Typography fontSize={{ xs: 12, md: 16 }}>Цена</Typography>
                  <Typography fontSize={{ xs: 16, md: 20 }} fontWeight={'bold'} textAlign={'right'}>
                    от {responseCars.Cars[currentCar].PriceRange.MinPrice + responseCars.Cars[currentCar].ServiceCost}₽
                  </Typography>
                </Grid2>
              </Grid2>
              <Box width={'100%'} overflow={{ xs: 'scroll', md: 'auto' }} marginBottom={{ xs: 3, md: 5 }}>
                {responseCars.Cars[currentCar].AvailabilityIndication === 'Available' ? (
                  <Outlet context={[responseCars.Cars, state]} />
                ) : (
                  <></>
                )}
              </Box>
              {(responseCars.Cars[currentCar].Services.length > 0 ||
                responseCars.Cars[currentCar].HasPlacesNearPets) && (
                <Stack direction={'row'} marginBottom={{ xs: 3, md: 5 }} spacing={4}>
                  <Typography fontSize={{ xs: 16, md: 20 }}>Услуги в вагоне</Typography>
                  <Stack direction="row" spacing={1} alignItems={'center'}>
                    {responseCars.Cars[currentCar].Services.map((item, i) => (
                      <Tooltip title={getService(item).description} key={i}>
                        <img src={getService(item).src} />
                      </Tooltip>
                    ))}
                    {responseCars.Cars[currentCar].HasPlacesNearPets && (
                      <Tooltip title="Место рядом с питомцем">
                        <img src={Pets} alt="Pets" />
                      </Tooltip>
                    )}
                  </Stack>
                </Stack>
              )}
              <Stack direction={'row'} justifyContent={'center'} width={'100%'}>
                <Button
                  onClick={() => handleSubmit()}
                  variant="contained"
                  sx={{ width: { xs: '100px', md: '208px' }, height: { xs: '40px', md: '60px' } }}
                >
                  Далее
                </Button>
              </Stack>
            </MainContainer>
          </Box>
        </>
      ) : (
        <>
          <Box marginBottom={{ xs: 3.75, md: 7.5 }} sx={{ padding: '0 20px' }}>
            <MainContainer justifyContent="center">
              <Typography fontSize={{ xs: 24, md: 48, lg: 70 }}>
                Выберите <strong>вагон</strong>
              </Typography>
            </MainContainer>
          </Box>
          <Box>
            <MainContainer>
              <Grid2 container spacing={{ xs: 2, md: 5 }} columns={{ xs: 4, sm: 12, md: 16 }} width={'100%'}>
                {[...Array(4)].map((_, index) => (
                  <Grid2 size={{ xs: 2, sm: 4, md: 5, lg: 4 }} key={index} display={'flex'} justifyContent={'center'}>
                    <CarsButton
                      variant="text"
                      sx={{
                        width: { xs: '151px', md: '260px' },
                        height: { xs: '90px', md: '126px' },
                        paddingLeft: { xs: '20px', md: '40px' },
                        color: 'black',
                      }}
                    >
                      <Skeleton type="text" width={100} />
                      <Skeleton type="text" width={100} />
                    </CarsButton>
                  </Grid2>
                ))}
              </Grid2>
            </MainContainer>
          </Box>
        </>
      )}
    </Stack>
  );
};
