import { alpha } from '@mui/material/styles';

// ----------------------------------------------------------------------

const COLORS = ['primary', 'secondary', 'info', 'success', 'warning', 'error'];

export default function Button(theme) {
  const rootStyle = (ownerState, isIconButton = false) => {
    const inheritColor = ownerState.color === 'inherit';
    const containedVariant = ownerState.variant === 'contained';
    const outlinedVariant = ownerState.variant === 'outlined';
    const textVariant = ownerState.variant === 'text';
    const mediumSize = ownerState.size === 'medium';
    const largeSize = ownerState.size === 'large';

    const defaultStyle = {
      textTransform: 'none',
      borderRadius: '10px',

      ...(inheritColor && {
        // CONTAINED
        ...(containedVariant && {
          color: theme.palette.primary.contrastText,
          '&:hover': {
            backgroundColor: theme.palette.grey[400],
          },
        }),
        // OUTLINED
        ...(outlinedVariant && {
          borderColor: theme.palette.info.main,
          '&:hover': {
            color: theme.palette.primary.contrastText,
            backgroundColor: theme.palette.primary.main,
          },
        }),
        // TEXT
        ...(textVariant && {
          '&:hover': {
            backgroundColor: theme.palette.action.hover,
          },
        }),
      }),
    };

    const colorStyle = COLORS.map(color => ({
      ...(ownerState.color === color && {
        // CONTAINED
        ...(containedVariant && {
          '&:hover': {
            boxShadow: 'none',
          },
        }),
        // SOFT
      }),
    }));

    const size = {
      ...(mediumSize && {
        height: 32,
        ...(isIconButton && {
          width: 32,
          padding: theme.spacing(1),
        }),
      }),
      ...(largeSize && {
        height: 40,
        ...(isIconButton && {
          width: 40,
          padding: theme.spacing(1),
        }),
      }),
    };

    return [...colorStyle, defaultStyle, size];
  };

  return {
    MuiButton: {
      defaultProps: {
        disableElevation: true,
      },

      styleOverrides: {
        root: ({ ownerState }) => rootStyle(ownerState),
      },
    },

    MuiIconButton: {
      styleOverrides: {
        root: ({ ownerState }) => rootStyle(ownerState, true),
      },
    },
  };
}
