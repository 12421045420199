import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import { StartPage } from './Pages-2.0/StartPage/StartPage';
import { Route, Routes } from 'react-router-dom';
import { BrowserRouter } from 'react-router-dom';
import { TrainsPage } from './Pages-2.0/TrainsPage/TrainsPage';
import { CarsPage } from './Pages-2.0/CarsPage/CarsPage';
import { SvgCarScheme } from './Components/SvgCarScheme/SvgCarScheme';
import { PassengerFormPage } from './Pages-2.0/PassengerFormPage/PassengerFormPage';
import { BookingPage } from './Pages-2.0/BookingPage/BookingPage';
import { RefundPage } from './Pages-2.0/RefundPage/RefundPage';
import { ConfirmRefundPage } from './Pages/ConfirmRefundPage';
import React from 'react';
import { Header } from './Components-2.0/Header/Header';
import { Footer } from './Components-2.0/Footer/Footer';
import { ErrorDialog } from './Components/dialogs/ErrorDialog';
import { ThemeProvider } from './shared/theme';
import { Socials } from './Components-2.0/Socials/Socials';
import { Subscription } from './Components-2.0/Subscription/Subscription';
import { ReactQueryProvider } from './providers/ReactQueryProvider';

function Router() {
  return (
    <BrowserRouter>
      <ThemeProvider>
        <ReactQueryProvider>
          <div className="App">
            <Header />
            <ErrorDialog />

            <Routes>
              <Route path="/" element={<StartPage />} />
              <Route path="/trains/from/:Origin/to/:Destination/date/:DepartureDate" element={<TrainsPage />} />
              <Route
                path="/cars/:Origin/:Destination/:DepartureDate/:TrainNumber/:CarType/:Provider"
                element={<CarsPage />}
              >
                <Route path=":CarId" element={<SvgCarScheme />} />
              </Route>
              <Route path="/passengerForm" element={<PassengerFormPage />} />
              <Route path="/reservationPage" element={<BookingPage />} />
              <Route path="/refundTickets" element={<RefundPage />} />
              <Route path="/confirmRefund" element={<ConfirmRefundPage />} />
            </Routes>
            <Subscription />
            <Socials />
            <Footer />
          </div>
        </ReactQueryProvider>
      </ThemeProvider>
    </BrowserRouter>
  );
}

export default Router;
