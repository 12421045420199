import { Grid2, Stack, Tooltip, Typography } from '@mui/material';

export const BookingTable = ({ customer, passenger, reservationResult, BookingContainer }) => {
  const gendersList = [
    {
      key: 'Male',
      name: 'М',
    },
    {
      key: 'Female',
      name: 'Ж',
    },
  ];

  const cabinGenderKind = [
    {
      key: 'Male',
      name: 'Гендерный признак купе - Мужское',
    },
    {
      key: 'Female',
      name: 'Гендерный признак купе - Женское',
    },
    {
      key: 'Whole',
      name: 'Гендерный признак купе - Целое',
    },
    {
      key: 'Mixed',
      name: 'Гендерный признак купе - Смешанное',
    },
    {
      key: '',
      name: '',
    },
    {
      key: 'NoValue',
      name: '',
    },
  ];

  const documentTypeKind = [
    { key: 'RussianPassport', name: 'Паспорт РФ' },
    { key: 'RussianForeignPassport', name: 'Заграничный паспорт РФ' },
    { key: 'BirthCertificate', name: 'Свидетельство о рождении' },
    { key: 'ForeignPassport', name: 'Иностранный документ' },
    { key: 'MilitaryCard', name: 'Военный билет' },
    {
      key: 'MilitaryOfficerCard',
      name: 'Удостоверение личности для военнослужащих',
    },
    { key: 'ReturnToCisCertificate', name: 'Свидетельство на возвращение' },
    { key: 'DiplomaticPassport', name: 'Дипломатический паспорт' },
    { key: 'ServicePassport', name: 'Служебный паспорт' },
    { key: 'SailorPassport', name: 'Удостоверение личности моряка' },
    {
      key: 'StatelessPersonIdentityCard',
      name: 'Удостоверение личности лица без гражданства',
    },
    { key: 'ResidencePermit', name: 'Вид на жительство' },
    {
      key: 'RussianTemporaryIdentityCard',
      name: 'Временное удостоверение личности',
    },
    { key: 'UssrPassport', name: 'Паспорт СССР' },
    {
      key: 'MedicalBirthCertificate',
      name: 'Медицинское свидетельство о рождении',
    },
    { key: 'LostPassportCertificate', name: 'Справка об утере паспорта' },
    {
      key: 'PrisonReleaseCertificate',
      name: 'Справка об освобождении из мест лишения свободы',
    },
    { key: 'RefugeeIdentity', name: 'Удостоверение беженца' },
    {
      key: 'RefugeeCertificate',
      name: 'Свидетельство о предоставлении временного убежища на территории РФ (ЖД)',
    },
    {
      key: 'ConvictedPersonIdentity',
      name: 'Документ, удостоверяющий личность осужденного',
    },
    {
      key: 'AcknowledgmentOfIdentityOfAForeignCitizenOrStatelessPerson',
      name: 'Заключение об установлении личности иностранного гражданина или лица без гражданства',
    },
    {
      key: 'CertificateIssuedForAForeignCitizenGoingToDiplomaticOffice',
      name: 'Справка для следования в дипломатическое представительство иностранного государства в Российской Федерации',
    },
    {
      key: 'AnotherDocumentOfAForeignCitizenRecognizedInTheRussianFederation',
      name: 'Иной документ, установленный федеральным законом или признаваемый в соответствии с международным договором Российской Федерации в качестве документа, удостоверяющего личность иностранного гражданина и (или) лица без гражданства',
    },
  ];

  const placeTypeKind = [
    { key: 'MotherAndBaby', name: 'Место для матери и ребенка' },
    { key: 'WithChild', name: 'Места для пассажиров с детьми' },
    { key: 'WithPets', name: 'Места для пассажиров с животными' },
    { key: 'Upper', name: 'Верхнее' },
    { key: 'Lower', name: 'Нижнее' },
  ];

  const findType = placeType => {
    const type = placeTypeKind.find(place => place.key === placeType.Type);
    return type ? type.name : null;
  };

  return (
    <Stack>
      <Grid2
        display={{ xs: 'none', md: 'flex' }}
        marginTop={{ xs: '40px', md: '80px' }}
        container
        spacing={{ xs: 2, md: 2.5 }}
        columns={{ xs: 4, sm: 12, md: 18 }}
        width="100%"
      >
        <Grid2>
          <Typography color="text.secondary" fontSize={{ xs: '12px', md: '16px' }}>
            Вагон
          </Typography>
          <Typography fontSize={{ xs: '16px', md: '20px' }} fontWeight={'bold'}>
            {reservationResult.CarNumber}
          </Typography>
        </Grid2>
        <Grid2>
          <Typography color="text.secondary" fontSize={{ xs: '12px', md: '16px' }}>
            Место
          </Typography>
          {passenger.PlacesWithType !== null ? (
            <Tooltip
              title={
                passenger.PlacesWithType !== null && passenger.PlacesWithType.map(placeType => findType(placeType))
              }
            >
              <Typography fontSize={{ xs: '16px', md: '20px' }} fontWeight={'bold'}>
                {passenger.Places}
              </Typography>
            </Tooltip>
          ) : (
            <Typography fontSize={{ xs: '16px', md: '20px' }} fontWeight={'bold'}>
              {passenger.Places}
            </Typography>
          )}
        </Grid2>
        <Grid2>
          <Typography color="text.secondary" fontSize={{ xs: '12px', md: '16px' }}>
            ФИО
          </Typography>
          <Typography
            //   width={{ xs: '170px', sm: '303px', md: '170px' }}
            maxWidth={'175px'}
            fontSize={{ xs: '16px', md: '20px' }}
            fontWeight={'bold'}
          >
            {passenger.LastName + ' ' + passenger.FirstName + ' ' + passenger.MiddleName}
          </Typography>
        </Grid2>
        <Grid2>
          <Typography color="text.secondary" fontSize={{ xs: '12px', md: '16px' }}>
            Документ
          </Typography>
          <Tooltip
            title={
              documentTypeKind.find(
                document =>
                  document.key ===
                  customer.find(customer => customer.Index === passenger.OrderCustomerReferenceIndex).DocumentType,
              ).name
            }
          >
            <Typography fontSize={{ xs: '16px', md: '20px' }} fontWeight={'bold'}>
              {customer.find(customer => customer.Index === passenger.OrderCustomerReferenceIndex).DocumentNumber}
            </Typography>
          </Tooltip>
        </Grid2>
        <Grid2>
          <Typography color="text.secondary" fontSize={{ xs: '12px', md: '16px' }}>
            Дата рождения
          </Typography>
          <Typography fontSize={{ xs: '16px', md: '20px' }} fontWeight={'bold'}>
            {passenger.Birthday.split('T')[0].split('-')[2] +
              '.' +
              passenger.Birthday.split('T')[0].split('-')[1] +
              '.' +
              passenger.Birthday.split('T')[0].split('-')[0]}
          </Typography>
        </Grid2>
        <Grid2>
          <Typography color="text.secondary" fontSize={{ xs: '12px', md: '16px' }}>
            Пол
          </Typography>
          <Typography fontSize={{ xs: '16px', md: '20px' }} fontWeight={'bold'}>
            {gendersList.find(gender => gender.key === passenger.Sex).name}
          </Typography>
        </Grid2>
        <Grid2>
          <Typography color="text.secondary" fontSize={{ xs: '12px', md: '16px' }}>
            Цена билета
          </Typography>
          <Tooltip
            title={`С учетом сервисного сбора 
                   ${
                     reservationResult.Blanks.find(blank => blank.OrderItemBlankId === passenger.OrderItemBlankId)
                       .ServicePrice
                   }₽`}
          >
            <Typography fontSize={{ xs: '16px', md: '20px' }} fontWeight={'bold'}>
              {passenger.Amount}₽
            </Typography>
          </Tooltip>
        </Grid2>
        <Grid2>
          <Typography color="text.secondary" fontSize={{ xs: '12px', md: '16px' }}>
            Тариф
          </Typography>
          <Typography fontSize={{ xs: '16px', md: '20px' }} fontWeight={'bold'}>
            {passenger.Category === 'BabyWithoutPlace'
              ? reservationResult.Blanks.find(blank => blank.OrderItemBlankId === passenger.OrderItemBlankId).TariffInfo
                  .TariffName === 'БЕЗДЕНЕЖНЫЙ ДЕТСКИЙ ДЕТ5'
                ? 'ДЕТ5'
                : '—'
              : reservationResult.Blanks.find(blank => blank.OrderItemBlankId === passenger.OrderItemBlankId).TariffInfo
                  .TariffName}
            {reservationResult.Blanks.find(blank => blank.OrderItemBlankId === passenger.OrderItemBlankId)
              .IsNonRefundableTariff &&
              passenger.Category !== 'BabyWithoutPlace' && <p className="additional-info">Невозвратный</p>}
          </Typography>
        </Grid2>
        <Grid2>
          <Typography color="text.secondary" fontSize={{ xs: '12px', md: '16px' }}>
            Класс обслуживания
          </Typography>
          <Tooltip title={cabinGenderKind.find(gender => gender.key === reservationResult.CabinGenderKind).name}>
            <Typography fontSize={{ xs: '16px', md: '20px' }} fontWeight={'bold'}>
              {reservationResult.CarTypeName} ({reservationResult.ServiceClass})
            </Typography>
          </Tooltip>
        </Grid2>
      </Grid2>
      {/* Для мобилки */}
      <Stack
        sx={{
          ...BookingContainer,
          display: { xs: 'flex', md: 'none' },
          flexDirection: 'row',
          justifyContent: 'space-between',
        }}
      >
        <Stack direction={'column'} width={'50%'} spacing={{ xs: '10px', md: '27px' }}>
          <Stack>
            <Typography color="text.secondary" fontSize={{ xs: '12px', md: '16px' }}>
              Вагон
            </Typography>
            <Typography fontSize={{ xs: '16px', md: '20px' }} fontWeight={'bold'}>
              {reservationResult.CarNumber}
            </Typography>
          </Stack>
          <Stack>
            <Typography color="text.secondary" fontSize={{ xs: '12px', md: '16px' }}>
              Место
            </Typography>
            {passenger.PlacesWithType !== null ? (
              <Tooltip
                title={
                  passenger.PlacesWithType !== null && passenger.PlacesWithType.map(placeType => findType(placeType))
                }
              >
                <Typography fontSize={{ xs: '16px', md: '20px' }} fontWeight={'bold'}>
                  {passenger.Places}
                </Typography>
              </Tooltip>
            ) : (
              <Typography fontSize={{ xs: '16px', md: '20px' }} fontWeight={'bold'}>
                {passenger.Places}
              </Typography>
            )}
          </Stack>
          <Stack>
            <Typography color="text.secondary" fontSize={{ xs: '12px', md: '16px' }}>
              ФИО
            </Typography>
            <Typography fontSize={{ xs: '16px', md: '20px' }} fontWeight={'bold'}>
              {passenger.LastName + ' ' + passenger.FirstName + ' ' + passenger.MiddleName}
            </Typography>
          </Stack>
          <Stack>
            <Typography color="text.secondary" fontSize={{ xs: '12px', md: '16px' }}>
              Документ
            </Typography>
            <Tooltip
              title={
                documentTypeKind.find(
                  document =>
                    document.key ===
                    customer.find(customer => customer.Index === passenger.OrderCustomerReferenceIndex).DocumentType,
                ).name
              }
            >
              <Typography fontSize={{ xs: '16px', md: '20px' }} fontWeight={'bold'}>
                {customer.find(customer => customer.Index === passenger.OrderCustomerReferenceIndex).DocumentNumber}
              </Typography>
            </Tooltip>
          </Stack>
          <Stack>
            <Typography color="text.secondary" fontSize={{ xs: '12px', md: '16px' }}>
              Дата рождения
            </Typography>
            <Typography fontSize={{ xs: '16px', md: '20px' }} fontWeight={'bold'}>
              {passenger.Birthday.split('T')[0].split('-')[2] +
                '.' +
                passenger.Birthday.split('T')[0].split('-')[1] +
                '.' +
                passenger.Birthday.split('T')[0].split('-')[0]}
            </Typography>
          </Stack>
        </Stack>
        <Stack container direction={'column'} width={'45%'} spacing={{ xs: '10px', md: '27px' }}>
          <Stack maxWidth={'34px'}>
            <Typography color="text.secondary" fontSize={{ xs: '12px', md: '16px' }}>
              Пол
            </Typography>
            <Typography fontSize={{ xs: '16px', md: '20px' }} fontWeight={'bold'}>
              {gendersList.find(gender => gender.key === passenger.Sex).name}
            </Typography>
          </Stack>
          <Stack>
            <Typography color="text.secondary" fontSize={{ xs: '12px', md: '16px' }}>
              Цена билета
            </Typography>
            <Tooltip
              title={`С учетом сервисного сбора 
             ${
               reservationResult.Blanks.find(blank => blank.OrderItemBlankId === passenger.OrderItemBlankId)
                 .ServicePrice
             }₽`}
            >
              <Typography fontSize={{ xs: '16px', md: '20px' }} fontWeight={'bold'}>
                {passenger.Amount}₽
              </Typography>
            </Tooltip>
          </Stack>
          <Stack>
            <Typography color="text.secondary" fontSize={{ xs: '12px', md: '16px' }}>
              Тариф
            </Typography>
            <Typography fontSize={{ xs: '16px', md: '20px' }} fontWeight={'bold'}>
              {passenger.Category === 'BabyWithoutPlace'
                ? reservationResult.Blanks.find(blank => blank.OrderItemBlankId === passenger.OrderItemBlankId)
                    .TariffInfo.TariffName === 'БЕЗДЕНЕЖНЫЙ ДЕТСКИЙ ДЕТ5'
                  ? 'ДЕТ5'
                  : '—'
                : reservationResult.Blanks.find(blank => blank.OrderItemBlankId === passenger.OrderItemBlankId)
                    .TariffInfo.TariffName}
              {reservationResult.Blanks.find(blank => blank.OrderItemBlankId === passenger.OrderItemBlankId)
                .IsNonRefundableTariff &&
                passenger.Category !== 'BabyWithoutPlace' && <p className="additional-info">Невозвратный</p>}
            </Typography>
          </Stack>
          <Stack maxWidth={'177px'}>
            <Typography color="text.secondary" fontSize={{ xs: '12px', md: '16px' }}>
              Класс обслуживания
            </Typography>
            <Tooltip title={cabinGenderKind.find(gender => gender.key === reservationResult.CabinGenderKind).name}>
              <Typography fontSize={{ xs: '16px', md: '20px' }} fontWeight={'bold'}>
                {reservationResult.CarTypeName} ({reservationResult.ServiceClass})
              </Typography>
            </Tooltip>
          </Stack>
        </Stack>
      </Stack>
    </Stack>
  );
};

{
  /* <Grid2
marginTop={{ xs: '40px', md: '60px' }}
container
sx={{
  gap: { xs: '10px', md: '20px' },
}}
justifySelf={{ xs: 'center', md: 'center', lg: 'flex-start' }}
maxWidth={{ xs: '650px', md: '650px', lg: '100%' }}
>
<Grid2
  container
  direction={{ xs: 'column', md: 'row' }}
  justifyContent={{ xs: 'flex-start', lg: 'space-between' }}
  spacing={{ xs: '10px', md: '27px' }}
>
  <Grid2 maxWidth={'49px'}>
    <Typography color="text.secondary" fontSize={{ xs: '12px', md: '16px' }}>
      Вагон
    </Typography>
    <Typography fontSize={{ xs: '16px', md: '20px' }} fontWeight={'bold'}>
      {reservationResult.CarNumber}
    </Typography>
  </Grid2>
  <Grid2 maxWidth={'51px'}>
    <Typography color="text.secondary" fontSize={{ xs: '12px', md: '16px' }}>
      Место
    </Typography>
    {passenger.PlacesWithType !== null ? (
      <Tooltip
        title={
          passenger.PlacesWithType !== null && passenger.PlacesWithType.map(placeType => findType(placeType))
        }
      >
        <Typography fontSize={{ xs: '16px', md: '20px' }} fontWeight={'bold'}>
          {passenger.Places}
        </Typography>
      </Tooltip>
    ) : (
      <Typography fontSize={{ xs: '16px', md: '20px' }} fontWeight={'bold'}>
        {passenger.Places}
      </Typography>
    )}
  </Grid2>
  <Grid2 width={{ xs: '170px', sm: '303px', md: '170px' }}>
    <Typography color="text.secondary" fontSize={{ xs: '12px', md: '16px' }}>
      ФИО
    </Typography>
    <Typography fontSize={{ xs: '16px', md: '20px' }} fontWeight={'bold'}>
      {passenger.LastName + ' ' + passenger.FirstName + ' ' + passenger.MiddleName}
    </Typography>
  </Grid2>
  <Grid2 maxWidth={'122px'}>
    <Typography color="text.secondary" fontSize={{ xs: '12px', md: '16px' }}>
      Документ
    </Typography>
    <Tooltip
      title={
        documentTypeKind.find(
          document =>
            document.key ===
            customer.find(customer => customer.Index === passenger.OrderCustomerReferenceIndex).DocumentType,
        ).name
      }
    >
      <Typography fontSize={{ xs: '16px', md: '20px' }} fontWeight={'bold'}>
        {customer.find(customer => customer.Index === passenger.OrderCustomerReferenceIndex).DocumentNumber}
      </Typography>
    </Tooltip>
  </Grid2>
  <Grid2 maxWidth={'130px'}>
    <Typography color="text.secondary" fontSize={{ xs: '12px', md: '16px' }}>
      Дата рождения
    </Typography>
    <Typography fontSize={{ xs: '16px', md: '20px' }} fontWeight={'bold'}>
      {passenger.Birthday.split('T')[0].split('-')[2] +
        '.' +
        passenger.Birthday.split('T')[0].split('-')[1] +
        '.' +
        passenger.Birthday.split('T')[0].split('-')[0]}
    </Typography>
  </Grid2>
</Grid2>
<Grid2
  container
  direction={{ xs: 'column', md: 'row' }}
  justifyContent={{ xs: 'flex-start', lg: 'space-between' }}
  spacing={{ xs: '10px', md: '27px' }}
>
  <Grid2 maxWidth={'34px'}>
    <Typography color="text.secondary" fontSize={{ xs: '12px', md: '16px' }}>
      Пол
    </Typography>
    <Typography fontSize={{ xs: '16px', md: '20px' }} fontWeight={'bold'}>
      {gendersList.find(gender => gender.key === passenger.Sex).name}
    </Typography>
  </Grid2>
  <Grid2 maxWidth={'106px'}>
    <Typography color="text.secondary" fontSize={{ xs: '12px', md: '16px' }}>
      Цена билета
    </Typography>
    <Tooltip
      title={`С учетом сервисного сбора 
             ${
               reservationResult.Blanks.find(blank => blank.OrderItemBlankId === passenger.OrderItemBlankId)
                 .ServicePrice
             }₽`}
    >
      <Typography fontSize={{ xs: '16px', md: '20px' }} fontWeight={'bold'}>
        {passenger.Amount}₽
      </Typography>
    </Tooltip>
  </Grid2>
  <Grid2 maxWidth={'103px'}>
    <Typography color="text.secondary" fontSize={{ xs: '12px', md: '16px' }}>
      Тариф
    </Typography>
    <Typography fontSize={{ xs: '16px', md: '20px' }} fontWeight={'bold'}>
      {passenger.Category === 'BabyWithoutPlace'
        ? reservationResult.Blanks.find(blank => blank.OrderItemBlankId === passenger.OrderItemBlankId).TariffInfo
            .TariffName === 'БЕЗДЕНЕЖНЫЙ ДЕТСКИЙ ДЕТ5'
          ? 'ДЕТ5'
          : '—'
        : reservationResult.Blanks.find(blank => blank.OrderItemBlankId === passenger.OrderItemBlankId).TariffInfo
            .TariffName}
      {reservationResult.Blanks.find(blank => blank.OrderItemBlankId === passenger.OrderItemBlankId)
        .IsNonRefundableTariff &&
        passenger.Category !== 'BabyWithoutPlace' && <p className="additional-info">Невозвратный</p>}
    </Typography>
  </Grid2>
  <Grid2 maxWidth={'177px'}>
    <Typography color="text.secondary" fontSize={{ xs: '12px', md: '16px' }}>
      Класс обслуживания
    </Typography>
    <Tooltip title={cabinGenderKind.find(gender => gender.key === reservationResult.CabinGenderKind).name}>
      <Typography fontSize={{ xs: '16px', md: '20px' }} fontWeight={'bold'}>
        {reservationResult.CarTypeName} ({reservationResult.ServiceClass})
      </Typography>
    </Tooltip>
  </Grid2>
</Grid2>
</Grid2> */
}
