import { TextField } from '@mui/material';

export const SearchField = props => {
  const { value, sx, size, ref, ...restOfProps } = props;

  const handleFocus = event => {
    if (restOfProps.onFocus) {
      restOfProps.onFocus(event);
    }
  };

  const handleBlur = event => {
    if (restOfProps.onBlur) {
      restOfProps.onBlur(event);
    }
  };

  return (
    <TextField
      variant="outlined"
      autoComplete="off"
      size={size}
      {...restOfProps}
      popupIcon={null}
      clearIcon={null}
      slotProps={{
        inputLabel: {
          shrink: value ? true : undefined,
          sx: {
            position: 'absolute',
            left: 12,
            top: '50%',
            transform: 'translateY(-50%)',
            transition: 'all 0.3s ease',
            pointerEvents: 'none',
            color: '#637381',
            fontWeight: 600,
            '&.MuiInputLabel-shrink': {
              fontWeight: 400,
              top: '30%',
              transform: 'translateY(-30%)',
              fontSize: '0.85rem',
            },
            '&.MuiFormLabel-root.Mui-focused': {
              color: '#637381 !important',
            },
          },
        },
      }}
      onFocus={handleFocus}
      onBlur={handleBlur}
    />
  );
};
