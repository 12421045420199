import Autocomplete from '@mui/material/Autocomplete';

import { SearchField } from './SearchField';

export const SearchAutocompleteField = props => {
  const { value, options, defaultOptions, sx, onChange, textFieldProps, ...restOfProps } = props;

  const handleChange = (_, value) => {
    onChange(value);
  };

  return (
    <Autocomplete
      fullWidth
      sx={sx}
      value={value}
      options={options || defaultOptions}
      onChange={handleChange}
      {...restOfProps}
      renderInput={params => <SearchField {...params} {...textFieldProps} />}
    />
  );
};
