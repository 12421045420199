import { DatePicker } from '../DatePicker';
import { useState } from 'react';
import { formatDateTime } from './helpers';
import moment from 'moment-timezone';
import { addDays } from 'date-fns';

export const RailwayDatePicker = props => {
  const { label, textFieldProps, onChange, value: dateValue, minDate, ...restOfProps } = props;
  const [isCalendarOpen, setIsCalendarOpen] = useState(false);
  const [value, setValue] = useState(dateValue ? new Date(dateValue) : null);

  const openCalendar = () => setIsCalendarOpen(true);
  const closeCalendar = () => setIsCalendarOpen(false);

  const handleChange = value => {
    const timeTz = moment.tz.guess();
    const time = moment().tz(timeTz).format('THH:mm:ss');
    const formattedDate = formatDateTime(value, 'yyyy-MM-dd');

    const preparedDateTime = formattedDate + time;

    setValue(value);
    onChange(preparedDateTime);
  };

  return (
    <div style={{ cursor: 'pointer', width: '100%' }}>
      <DatePicker
        label={label}
        value={value}
        textFieldProps={{
          onClick: isCalendarOpen ? closeCalendar : openCalendar,
          onBlur: closeCalendar,
        }}
        isCalendarOpen={isCalendarOpen}
        selectedSections={null}
        format="dd MMMM"
        onChange={handleChange}
        minDate={minDate}
        maxDate={addDays(new Date(), 119)}
        onOpen={openCalendar}
        onClose={closeCalendar}
        {...restOfProps}
      />
    </div>
  );
};
