import { Box, Skeleton, Stack, Card, CardContent, Typography } from '@mui/material';
import MainContainer from '../MainContainer/MainContainer';

export const SceletonTrainData = () => {
  return (
    <Box marginBottom={5} width={'100%'}>
      <MainContainer
        direction="column"
        alignItems="start"
        sx={{ padding: '30px 40px', borderRadius: 1.25, boxShadow: '0px 0px 10px rgba(0, 0, 0, 0.05)' }}
      >
        <Card sx={{ width: '100%', boxShadow: 'none', color: 'black' }}>
          <CardContent sx={{ padding: 0, '&:last-child': { padding: 0 } }}>
            <Stack direction={{ xs: 'column', md: 'row' }} justifyContent="space-between" spacing={{ xs: 1, md: 4 }}>
              <Box flex={1}>
                <Skeleton variant="text" width={210} height={40} />
                <Stack direction="column" spacing={1} alignItems="center" mt={1}>
                  <Skeleton width="100%" height={40} sx={{ alignSelf: 'flex-end', marginRight: '30px' }} />
                  <Skeleton width="100%" height={40} sx={{ alignSelf: 'flex-end', marginRight: '30px' }} />
                  <Stack direction="row" width="100%" justifyContent={'space-between'}>
                    <Box>
                      <Skeleton variant="text" width={100} height={20} />
                    </Box>
                    <Box textAlign="right">
                      <Skeleton variant="text" width={100} height={20} />
                    </Box>
                  </Stack>
                </Stack>
                <Skeleton variant="text" width={210} height={40} />
              </Box>
              <Box width={{ xs: 'auto', md: '350px', lg: '420px' }}>
                <Skeleton variant="text" width="100%" height={40} />
                <Stack spacing={{ xs: 0.25, md: 1 }} mt={{ xs: 1, md: 2.5 }}>
                  <Skeleton width="100%" height={45} />
                  <Skeleton width="100%" height={45} />
                </Stack>
              </Box>
            </Stack>
          </CardContent>
        </Card>
      </MainContainer>
    </Box>
  );
};
