export const defaultCities = [
  {
    ExpressCode: '2000000',
    CountryId: 177,
    CityId: 6256,
    NameRu: 'Москва',
    popularityIndex: 257,
    type: 'RailwayStation',
    RegionId: 128,
    NameEn: 'MOSKVA',
    transportnodeid: 16596,
  },
  {
    ExpressCode: '2004001',
    CountryId: 0,
    CityId: 8480,
    NameRu: 'Санкт-Петербург',
    popularityIndex: 255,
    type: 'RailwayStation',
    RegionId: 6592,
    NameEn: 'SANKT-PETERBURG GLAVNY',
    transportnodeid: 16884,
  },
  {
    ExpressCode: '2060615',
    CountryId: 177,
    CityId: 3808,
    NameRu: 'Казань',
    popularityIndex: 203,
    type: 'RailwayStation',
    RegionId: 153,
    NameEn: 'KAZAN',
    transportnodeid: 17285,
  },
  {
    ExpressCode: '2060001',
    CountryId: 0,
    CityId: 6621,
    NameRu: 'Нижний Новгород',
    popularityIndex: 210,
    type: 'RailwayStation',
    RegionId: 131,
    NameEn: 'NIJNII NOVGOROD MOSKOV',
    transportnodeid: 16065,
  },
  {
    ExpressCode: '2064150',
    CountryId: 0,
    CityId: 53050,
    NameRu: 'Адлер',
    popularityIndex: 198,
    type: 'RailwayStation',
    RegionId: 119,
    NameEn: 'ADLER',
    transportnodeid: 15713,
  },
  {
    ExpressCode: '2014000',
    CountryId: 177,
    CityId: 2165,
    NameRu: 'Воронеж',
    popularityIndex: 192,
    type: 'RailwayStation',
    RegionId: 99,
    NameEn: 'VORONEJ',
    transportnodeid: 16000,
  },
  {
    ExpressCode: '2030000',
    CountryId: 0,
    CityId: 3146,
    NameRu: 'Екатеринбург',
    popularityIndex: 150,
    type: 'RailwayStation',
    RegionId: 148,
    NameEn: 'EKATERINBURG-PASSAJIRS',
    transportnodeid: 16898,
  },
  {
    ExpressCode: '2100000',
    CountryId: 24,
    CityId: 6083,
    NameRu: 'Минск',
    popularityIndex: 182,
    type: 'RailwayStation',
    RegionId: 2453,
    NameEn: 'MINSK',
    transportnodeid: 16575,
  },
];
