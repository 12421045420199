import { useEffect, useState } from 'react';
import { Box, Button, Stack, styled, Typography } from '@mui/material';

const InputButton = styled(Button)`
  min-width: 32px;
  width: 32px;
  height: 32px;
  border-radius: 10px;
  background-color: white;
`;

const LuxuryComponent = ({ places, reservationType, formParam, setFormParam, carrier, serviceClass, carType }) => {
  const [totalSeats, setTotalSeats] = useState(places);
  const [adultsAdded, setAdultsAdded] = useState(0);
  const [childrenAdded, setChildrenAdded] = useState(0);
  const [childrenWithoutSeatAdded, setChildrenWithoutSeatAdded] = useState(0);
  const [isChildWithoutPlaceAvailable, setIsChildWithoutPlaceAvailable] = useState(false);
  const [adultsData, setAdultsData] = useState([]);
  const [childrenData, setChildrenData] = useState([]);
  const [forwardNonReturnableTariff, setForwardNonReturnableTariff] = useState(false);
  const [backwardNonReturnableTariff, setBackwardNonReturnableTariff] = useState(false);

  useEffect(() => {
    if (formParam[0]) {
      setForwardNonReturnableTariff(formParam[0].forwardNonReturnableTariff);
      setBackwardNonReturnableTariff(formParam[0].backwardNonReturnableTariff);
    }
    setFormParam([]);
  }, []);

  const generateUniqueId = () => {
    return Math.random().toString(36).substr(2, 9);
  };

  const isSeatAvailable = () => totalSeats > 0;

  const handleAddAdult = () => {
    let updatedParam = [
      ...formParam,
      {
        category: 'Adult',
        forward: null,
        backward: null,
        forwardNonReturnableTariff,
        backwardNonReturnableTariff,
        forwardGender: 'NoValue',
        backwardGender: 'NoValue',
        carType,
      },
    ];
    setFormParam(updatedParam);
    if (isSeatAvailable()) {
      setAdultsAdded(adultsAdded + 1);
      setTotalSeats(totalSeats - 1);
      const newAdult = { id: generateUniqueId(), seats: 1 };
      setAdultsData([...adultsData, newAdult]);
    }
  };

  const handleAddChildWithSeat = () => {
    let updatedParam = [
      ...formParam,
      {
        category: 'Child',
        forward: null,
        backward: null,
        forwardNonReturnableTariff,
        backwardNonReturnableTariff,
        forwardGender: 'NoValue',
        backwardGender: 'NoValue',
        carType,
      },
    ];
    setFormParam(updatedParam);
    if (isSeatAvailable()) {
      setTotalSeats(totalSeats - 1);
      setChildrenAdded(childrenAdded + 1);
      const newChild = { id: generateUniqueId(), seats: 1 };
      setChildrenData([...childrenData, newChild]);
    }
  };

  const handleAddChildWithoutSeat = () => {
    let updatedParam = [
      ...formParam,
      {
        category: 'BabyWithoutPlace',
        forward: null,
        backward: null,
        forwardNonReturnableTariff: false,
        backwardNonReturnableTariff: false,
        forwardGender: 'NoValue',
        backwardGender: 'NoValue',
        carType,
      },
    ];
    // Подсчитываем число взрослых и детей с местом
    const filteredAdultsPassengers = adultsData.filter(passenger => passenger.seats !== 0);
    const filteredChildrenPassengers = childrenData.filter(passenger => passenger.seats !== 0);
    const adults = filteredAdultsPassengers.length;
    const children = filteredChildrenPassengers.length;
    if (adults === 0) {
      return null;
    }
    const key = `${adults},${children}`;
    const casesForFour = {
      '1,0': 1,
      '2,0': 2,
      '1,1': 1,
      '3,0': 3,
      '2,1': 2,
      '1,2': 1,
      '4,0': 4,
      '3,1': 1,
      '2,2': 2,
      '1,3': 1,
    };
    let casesForTwo = {};
    if (['1А', '1М', '1И'].includes(serviceClass)) {
      casesForTwo = { '1,0': 2, '1,1': 1, '2,0': 1 };
      if (carrier !== 'ГРАНД') {
        casesForTwo['2,0'] = 2;
      }
    }
    if (serviceClass === '1E') {
      casesForTwo = { '1,0': 1, '1,1': 1, '2,0': 2 };
    }

    let allowed;
    if (reservationType === 'TwoPlacesAtOnce') {
      allowed = casesForTwo[key] !== undefined ? casesForTwo[key] : adults;
    } else if (reservationType === 'FourPlacesAtOnce') {
      allowed = casesForFour[key] !== undefined ? casesForFour[key] : adults;
    } else {
      allowed = adults;
    }
    // Ограничиваем лимит количеством взрослых
    allowed = Math.min(allowed, adults);

    if (childrenWithoutSeatAdded < allowed) {
      setFormParam(updatedParam);
      setChildrenWithoutSeatAdded(childrenWithoutSeatAdded + 1);
      setIsChildWithoutPlaceAvailable(false);
    } else {
      setChildrenWithoutSeatAdded(allowed);
      setIsChildWithoutPlaceAvailable(true);
    }
  };

  const handleRemoveAdult = () => {
    if (adultsAdded > 0) {
      setAdultsAdded(adultsAdded - 1);
      setTotalSeats(totalSeats + 1);
      if (adultsData.length > 0) {
        let removed = false;
        const updatedAdultsData = adultsData.filter(adult => {
          if (!removed && adult) {
            removed = true;
            return false;
          }
          return true;
        });
        setAdultsData(updatedAdultsData);
        let removedParam = false;
        const updatedParam = formParam.filter(item => {
          if (!removedParam && item.category === 'Adult') {
            removedParam = true;
            return false;
          }
          return true;
        });
        setFormParam(updatedParam);
      }
    }
  };

  const handleRemoveChild = () => {
    if (childrenAdded > 0) {
      setChildrenAdded(childrenAdded - 1);
      setTotalSeats(totalSeats + 1);
      if (childrenData.length > 0) {
        let removed = false;
        const updatedChildrenData = childrenData.filter(child => {
          if (!removed && child) {
            removed = true;
            return false;
          }
          return true;
        });
        setChildrenData(updatedChildrenData);
        let removedParam = false;
        const updatedParam = formParam.filter(item => {
          if (!removedParam && item.category === 'Child') {
            removedParam = true;
            return false;
          }
          return true;
        });
        setFormParam(updatedParam);
      }
    }
  };

  const handleRemoveChildWithoutSeat = () => {
    if (childrenWithoutSeatAdded > 0) {
      setChildrenWithoutSeatAdded(childrenWithoutSeatAdded - 1);
      let removed = false;
      const updatedParam = formParam.filter(item => {
        if (!removed && item.category === 'BabyWithoutPlace') {
          removed = true;
          return false;
        }
        return true;
      });
      setFormParam(updatedParam);
      setIsChildWithoutPlaceAvailable(false);
    }
  };

  return (
    <Box width="100%">
      <Typography marginBottom={{ xs: 2.5, md: 7.5 }} textAlign="center">
        Оставшееся количество мест: <strong>{totalSeats}</strong>
      </Typography>
      <Stack direction={{ xs: 'column', md: 'row' }} width="100%" justifyContent="space-between" spacing={2}>
        <Stack
          direction="row"
          spacing={3}
          sx={{
            width: { xs: '100%', md: '360px' },
            justifyContent: 'space-between',
            height: { xs: '80px', md: '126px' },
            padding: { xs: '20px', md: '30px' },
            borderRadius: '20px',
            alignItems: 'center',
            backgroundColor: '#FFFAF2',
          }}
        >
          <Typography fontSize={{ xs: 14, md: 16 }}>Взрослый пассажир</Typography>
          <Stack direction="row" spacing={1} alignItems="center">
            <InputButton onClick={handleRemoveAdult} disabled={adultsAdded === 0}>
              -
            </InputButton>
            <Typography fontSize={16} color="#6E7491">
              {adultsAdded}
            </Typography>
            <InputButton onClick={handleAddAdult} disabled={!isSeatAvailable()}>
              +
            </InputButton>
          </Stack>
        </Stack>
        <Stack
          direction="row"
          spacing={3}
          sx={{
            width: { xs: '100%', md: '360px' },
            justifyContent: 'space-between',
            height: { xs: '80px', md: '126px' },
            padding: { xs: '20px', md: '30px' },
            borderRadius: '20px',
            alignItems: 'center',
            backgroundColor: '#FFFAF2',
          }}
        >
          <Typography fontSize={{ xs: 14, md: 16 }}>Ребенок, занимающий место</Typography>
          <Stack direction="row" spacing={1} alignItems="center">
            <InputButton onClick={handleRemoveChild} disabled={childrenAdded === 0}>
              -
            </InputButton>
            <Typography fontSize={16} color="#6E7491">
              {childrenAdded}
            </Typography>
            <InputButton onClick={handleAddChildWithSeat} disabled={!isSeatAvailable()}>
              +
            </InputButton>
          </Stack>
        </Stack>
        <Stack
          direction="row"
          spacing={3}
          sx={{
            width: { xs: '100%', md: '360px' },
            justifyContent: 'space-between',
            height: { xs: '80px', md: '126px' },
            padding: { xs: '20px', md: '30px' },
            borderRadius: '20px',
            alignItems: 'center',
            backgroundColor: '#FFFAF2',
          }}
        >
          <Typography fontSize={{ xs: 14, md: 16 }}>Малыши до 5 лет без места</Typography>
          <Stack direction="row" spacing={1} alignItems="center">
            <InputButton onClick={handleRemoveChildWithoutSeat} disabled={childrenWithoutSeatAdded === 0}>
              -
            </InputButton>
            <Typography fontSize={16} color="#6E7491">
              {childrenWithoutSeatAdded}
            </Typography>
            <InputButton onClick={handleAddChildWithoutSeat} disabled={isChildWithoutPlaceAvailable}>
              +
            </InputButton>
          </Stack>
        </Stack>
      </Stack>
    </Box>
  );
};

export default LuxuryComponent;
