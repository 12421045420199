import { Stack } from '@mui/material';

const MainContainer = ({
  children,
  sx,
  direction = 'row',
  justifyContent = 'space-between',
  alignItems = 'center',
}) => {
  return (
    <Stack
      direction={direction}
      justifyContent={justifyContent}
      alignItems={alignItems}
      sx={{ maxWidth: '1160px', margin: '0 auto', ...sx }}
    >
      {children}
    </Stack>
  );
};

export default MainContainer;
