import { Box, Button, Stack, Typography } from '@mui/material';
import MainContainer from '../MainContainer/MainContainer';
import styled from 'styled-components';
import VKIcon from '../../Assets/VK.png';
import TelegramIcon from '../../Assets/Telegram.png';
import WhatsAppIcon from '../../Assets/WhatsApp.png';

const SocialCard = styled(Button)`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  flex: 1;
  min-width: 170px;
  background-color: #fffaf2;
  border-radius: 20px;
  text-align: left;
`;

const socialLinks = [
  { name: 'Telegram', username: '@kokoc', icon: TelegramIcon },
  { name: 'WhatsApp', username: '@kokoc', icon: WhatsAppIcon },
  { name: 'Вконтакте', username: '@kokoc', icon: VKIcon },
];

export const Socials = () => {
  return (
    <Box marginBottom={{ xs: 7, md: 10, lg: 15 }} padding={'0px 20px'}>
      <MainContainer direction={'column'}>
        <Typography
          variant="h6"
          component="h3"
          color="black"
          sx={{
            fontSize: { xs: '20px', sm: '35px', md: '50px', lg: '70px' },
            marginBottom: { xs: '30px', md: '60px' },
            fontWeight: 'regular',
          }}
        >
          Мы в <strong>социальных сетях</strong>
        </Typography>
        <Stack
          direction={'row'}
          justifyContent={'space-between'}
          useFlexGap
          flexWrap={'wrap'}
          spacing={2}
          width={'100%'}
        >
          {socialLinks.map((item, i) => (
            <SocialCard
              key={i}
              href="#"
              sx={{ height: { xs: '70px', md: '100px' }, padding: { xs: '12px', md: '24px' } }}
            >
              <Box sx={{ width: { xs: '36px', md: '52px' } }}>
                <img src={item.icon} alt="Telegram" />
              </Box>
              <Stack marginLeft={2.5} color="black">
                <Typography fontSize={{ xs: '12px', md: '16px' }}>{item.name}</Typography>
                <Typography fontSize={{ xs: '16px', md: '20px' }} fontWeight={'bold'}>
                  {item.username}
                </Typography>
              </Stack>
            </SocialCard>
          ))}
        </Stack>
      </MainContainer>
    </Box>
  );
};
