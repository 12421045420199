import { getPalette } from './palette.theme';

const themePalette = getPalette('light');

export const shape = {
  borderRadius: 8,
  borderRadiusHalf: 4,
  borderRadiusX2: 16,
  divider: `1px solid ${themePalette.divider}`,
  dashedDivider: `1px dashed ${themePalette.divider}`,
};
