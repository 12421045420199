import { Button, Stack, Typography } from '@mui/material';
import { SearchInput } from './SearchInput';
import { useState } from 'react';
import { RailwayDatePicker } from './RailwayDatePicker';
import { useNavigate } from 'react-router-dom';
import MainContainer from '../MainContainer/MainContainer';
import { SwapHoriz, SwapVert } from '@mui/icons-material';

export const RailwayForm = props => {
  const { isReturnPage } = props;
  const [cityFrom, setCityFrom] = useState({ ExpressCode: props.cityFromCode, NameRu: props.cityFrom } || null);
  const [cityTo, setCityTo] = useState({ ExpressCode: props.cityToCode, NameRu: props.cityTo } || null);
  const [dateTo, setDateTo] = useState(props.dateTo || null);
  const [dateReturn, setDateReturn] = useState(props.dateReturn || null);
  const navigate = useNavigate();

  const isFormDataInvalid = !cityFrom || !cityTo || !dateTo;

  const handleProceed = () => {
    if (isFormDataInvalid) {
      return;
    }

    if (isReturnPage) {
      navigate(`/trains/from/${cityFrom.ExpressCode}/to/${cityTo.ExpressCode}/date/${dateTo}`, {
        state: {
          cityFrom: cityFrom.NameRu,
          cityTo: cityTo.NameRu,
          cityFromCode: cityFrom.ExpressCode,
          cityToCode: cityTo.ExpressCode,
          dateTo: dateTo,
          dateReturn: dateTo,
        },
      });
    } else {
      navigate(`/trains/from/${cityFrom.ExpressCode}/to/${cityTo.ExpressCode}/date/${dateTo}`, {
        state: {
          cityFrom: cityFrom.NameRu,
          cityTo: cityTo.NameRu,
          cityFromCode: cityFrom.ExpressCode,
          cityToCode: cityTo.ExpressCode,
          dateTo: dateTo,
          dateReturn: dateReturn,
        },
      });
    }
  };

  const setDefaultCity = (city, direction) => {
    if (city === 'Москва') {
      if (direction === 'Откуда') {
        setCityFrom({
          CityId: 6256,
          CountryId: 177,
          ExpressCode: '2000000',
          NameEn: 'MOSKVA',
          NameRu: 'Москва',
          RegionId: 128,
          popularityIndex: 257,
          transportnodeid: 16596,
          type: 'RailwayStation',
        });
      } else {
        setCityTo({
          CityId: 6256,
          CountryId: 177,
          ExpressCode: '2000000',
          NameEn: 'MOSKVA',
          NameRu: 'Москва',
          RegionId: 128,
          popularityIndex: 257,
          transportnodeid: 16596,
          type: 'RailwayStation',
        });
      }
    } else {
      if (direction === 'Откуда') {
        setCityFrom({
          CityId: 8480,
          CountryId: 0,
          ExpressCode: '2004001',
          NameEn: 'SANKT-PETERBURG GLAVNY',
          NameRu: 'Санкт-Петербург',
          RegionId: 6592,
          popularityIndex: 255,
          transportnodeid: 16884,
          type: 'RailwayStation',
        });
      } else {
        setCityTo({
          CityId: 8480,
          CountryId: 0,
          ExpressCode: '2004001',
          NameEn: 'SANKT-PETERBURG GLAVNY',
          NameRu: 'Санкт-Петербург',
          RegionId: 6592,
          popularityIndex: 255,
          transportnodeid: 16884,
          type: 'RailwayStation',
        });
      }
    }
  };

  const swapCities = () => {
    setCityFrom(prev => ({ ...cityTo }));
    setCityTo(prev => ({ ...cityFrom }));
  };

  return (
    <MainContainer sx={{ padding: '30px', backgroundColor: '#FFFAF2', borderRadius: '20px' }}>
      <Stack direction={{ xs: 'column', md: 'row' }} spacing={0.5} width={'100%'}>
        <Stack
          direction={{ xs: 'column', sm: 'row', md: 'row' }}
          spacing={0.5}
          width={'100%'}
          maxWidth={{ xs: '100%', md: '480px' }}
          position="relative"
        >
          <Stack maxWidth={{ xs: '100%', md: '480px' }} width={'100%'}>
            <SearchInput value={cityFrom} onChange={setCityFrom} label="Откуда" sx={{ width: '100%' }} />
            <Stack direction={'row'} spacing={0.5} sx={{ display: { xs: 'none', md: 'flex' }, marginTop: 0.5 }}>
              <Typography fontSize={11} sx={{ cursor: 'pointer' }} onClick={() => setDefaultCity('Москва', 'Откуда')}>
                Москва,{' '}
              </Typography>
              <Typography
                fontSize={11}
                sx={{ cursor: 'pointer' }}
                onClick={() => setDefaultCity('Санкт-Петербург', 'Откуда')}
              >
                Санкт-Петербург
              </Typography>
            </Stack>
          </Stack>
          <Button
            onClick={swapCities}
            sx={{
              position: 'absolute',
              left: { xs: '90%', sm: '47%', md: '48%' },
              top: '37%',
              transform: { md: 'translate(-37%, -48%)' },
              zIndex: 2,
              display: { xs: 'block', md: 'block' },
              minWidth: '24px',
              height: '24px',
              padding: 0,
              backgroundColor: 'white',
            }}
          >
            <SwapHoriz color="primary" sx={{ display: { xs: 'none', sm: 'block', md: 'block' } }} />
            <SwapVert color="primary" sx={{ display: { xs: 'block', sm: 'none', md: 'none' } }} />
          </Button>
          <Stack maxWidth={{ xs: '100%', md: '480px' }} width={'100%'}>
            <SearchInput value={cityTo} onChange={setCityTo} label="Куда" sx={{ width: '100%' }} />
            <Stack direction={'row'} spacing={0.5} sx={{ display: { xs: 'none', md: 'flex' }, marginTop: 0.5 }}>
              <Typography fontSize={11} sx={{ cursor: 'pointer' }} onClick={() => setDefaultCity('Москва', 'Куда')}>
                Москва,{' '}
              </Typography>
              <Typography
                fontSize={11}
                sx={{ cursor: 'pointer' }}
                onClick={() => setDefaultCity('Санкт-Петербург', 'Куда')}
              >
                Санкт-Петербург
              </Typography>
            </Stack>
          </Stack>
        </Stack>
        <Stack direction="row" spacing={0.5} width={'100%'} maxWidth={{ xs: '100%', md: '400px' }}>
          <RailwayDatePicker
            value={dateTo}
            label="Туда"
            onChange={setDateTo}
            sx={{ width: '100%' }}
            minDate={new Date()}
          />
          <RailwayDatePicker
            disabled={isReturnPage}
            value={isReturnPage ? '' : dateReturn}
            label="Оттуда"
            onChange={setDateReturn}
            sx={{ width: '100%' }}
            minDate={new Date(dateTo)}
          />
        </Stack>
        <Button
          disabled={isFormDataInvalid}
          variant="contained"
          sx={{ height: '60px', width: { xs: '100%', md: '210px' } }}
          onClick={handleProceed}
        >
          Найти билеты
        </Button>
      </Stack>
    </MainContainer>
  );
};
