import { useQuery } from '@tanstack/react-query';
import { CounriesApi } from '../../api/countries.api';

export const useCities = params =>
  useQuery({
    queryKey: ['countries', params],
    queryFn: () => CounriesApi.fetchCities(params),
    staleTime: Infinity,
    enabled: params.city_name.length >= 3,
  });
