import { useEffect, useState } from 'react';
import { useStore } from 'effector-react';
import { useLocation, useNavigate } from 'react-router-dom';
import axios from 'axios';
import { $localTrainInfoFromStore, $localTrainInfoReturnFromStore } from '../../Services/trainsService';
import { baseUrl } from '../../config';
import { Box, Button, Checkbox, FormControlLabel, Stack, styled, Typography } from '@mui/material';
import { TrainCard } from '../../Components-2.0/TrainCard/TrainCard';
import { BookingTable } from '../../Components-2.0/BookingTable/BookingTable';
import { CustomModal } from '../../Components-2.0/CustomModal/CustomModal';
import { Timer } from '../../Components-2.0/Timer/Timer';
import MainContainer from '../../Components-2.0/MainContainer/MainContainer';

const ErrorBox = styled(Box)`
  padding: 30px;
  color: white;
  background-color: #ff7517;
  border-radius: 20px;
`;

const BookingContainer = {
  width: { xs: '100%', sm: '85%', md: '100%' },
  alignSelf: 'center',
  flexDirection: { xs: 'column', md: 'row' },
  marginTop: { xs: '40px', md: '60px' },
  gap: { xs: '10px', md: '20px' },
};

export const BookingPage = () => {
  const location = useLocation();
  const responseReservation = location.state.responseReservation;
  const [modal, setOpenModal] = useState(false);
  const [modalCancel, setModalCancel] = useState(false);
  const navigate = useNavigate();
  const trainData = useStore($localTrainInfoFromStore);
  const [agreement, setAgreement] = useState(false);
  const trainReturnData = useStore($localTrainInfoReturnFromStore);
  const [backPagesAmount, setBackPagesAmount] = useState(-1);

  const onCloseModalHandler = () => {
    setOpenModal(false);
    navigate('/');
  };

  const cancelReservation = () => {
    const json = JSON.stringify({ OrderId: responseReservation.OrderId });
    try {
      axios
        .post(`${baseUrl}/api/Railway/cancel_reservation`, json, {
          headers: {
            'Content-Type': 'application/json',
          },
        })
        .then(() => {
          setOpenModal(true);
        });
    } catch (error) {
      throw error;
    }
  };

  const cancelReservationByButton = () => {
    setModalCancel(true);
    const json = JSON.stringify({ OrderId: responseReservation.OrderId });
    window.removeEventListener('popstate', handleBackButton);
    setBackPagesAmount(-2);

    try {
      axios.post(`${baseUrl}/api/Railway/cancel_reservation`, json, {
        headers: {
          'Content-Type': 'application/json',
        },
      });
    } catch (error) {
      throw error;
    }
  };

  const handleBackButton = event => {
    setModalCancel(true);
    event.preventDefault();
    const json = JSON.stringify({ OrderId: responseReservation.OrderId });

    try {
      axios.post(`${baseUrl}/api/Railway/cancel_reservation`, json, {
        headers: {
          'Content-Type': 'application/json',
        },
      });
    } catch (error) {
      throw error;
    }
  };

  const formatText = str => {
    let result = str.toLowerCase();
    result = result.charAt(0).toUpperCase() + result.slice(1);
    result = result.replace(/\. ?([a-zа-яё])/gi, (_, p1) => '.  ' + p1.toUpperCase());

    return result;
  };

  useEffect(() => {
    window.addEventListener('popstate', handleBackButton);

    // Сохраняем состояние истории браузера перед тем, как покинуть компонент
    window.history.pushState({ source: 'ReservationPage' }, '');

    return () => {
      window.removeEventListener('popstate', handleBackButton);
    };
  }, []);

  return (
    <Box marginBottom={{ xs: '60px', md: '120px' }} sx={{ padding: '0 20px' }}>
      <Stack justifyContent={'center'} marginTop={1.25}>
        <MainContainer direction="column" alignItems="left">
          <Timer confirmTill={responseReservation.ConfirmTill} onTimeIsOver={cancelReservation} />
        </MainContainer>
      </Stack>
      <Box marginTop={4.5} marginBottom={{ xs: 4, md: 15 }}>
        {trainReturnData === '' ? (
          <TrainCard trainData={trainData} />
        ) : (
          <MainContainer>
            <Stack direction={'column'} width={'100%'}>
              <Typography fontSize={16}>Туда:</Typography>
              <TrainCard trainData={trainData} />
              <Typography fontSize={16}>Обратно:</Typography>
              <TrainCard trainData={trainReturnData} />
            </Stack>
          </MainContainer>
        )}
      </Box>
      <Box>
        <MainContainer direction="column" alignItems="left">
          <CustomModal open={modal} onOpen={setOpenModal} onClose={onCloseModalHandler}>
            <h2>Заказ №{responseReservation.OrderId} был отменен!</h2>
            <h4>Срок подтверждения заказа истёк.</h4>
            <h4>Вы будете перенаправлены на главную страницу.</h4>
          </CustomModal>
          <CustomModal
            open={modalCancel}
            onOpen={setModalCancel}
            onClose={() => {
              setModalCancel(false);
              navigate(backPagesAmount);
            }}
          >
            <h2>Заказ №{responseReservation.OrderId} был отменен!</h2>

            <h4>Вы будете перенаправлены на предыдущую страницу.</h4>
          </CustomModal>
          <Typography fontSize={{ xs: 24, md: 48, lg: 68 }} textAlign={'center'}>
            <strong>Подтверждение</strong> данных
          </Typography>
          <Stack>
            {responseReservation.ReservationResults.map(reservationResult =>
              reservationResult.Passengers.map((passenger, i) => (
                <BookingTable
                  reservationResult={reservationResult}
                  passenger={passenger}
                  customer={responseReservation.Customers}
                  BookingContainer={BookingContainer}
                  key={i}
                />
              )),
            )}
            <Stack sx={{ ...BookingContainer }}>
              <Stack direction={'column'} justifyContent={{ xs: 'flex-start', lg: 'space-between' }}>
                <Typography color="text.secondary" fontSize={{ xs: '12px', md: '16px' }}>
                  Мобильный телефон
                </Typography>
                <Typography fontSize={{ xs: '16px', md: '20px' }} fontWeight={'bold'}>
                  {responseReservation.OrderContacts.ContactPhone}
                </Typography>
              </Stack>
              <Stack direction={'column'} justifyContent={{ xs: 'flex-start', lg: 'space-between' }}>
                <Typography color="text.secondary" fontSize={{ xs: '12px', md: '16px' }}>
                  E-mail
                </Typography>
                <Typography fontSize={{ xs: '16px', md: '20px' }} fontWeight={'bold'}>
                  {responseReservation.OrderContacts.ContactEmail}
                </Typography>
              </Stack>
            </Stack>
          </Stack>
          <Stack sx={{ ...BookingContainer, flexDirection: { xs: 'column-reverse', md: 'row' } }}>
            <Stack direction={{ xs: 'column', md: 'row' }} spacing={{ xs: 1.75, md: 2.5 }}>
              <Button
                variant="outlined"
                role="button"
                onClick={() => cancelReservationByButton()}
                sx={{ width: { xs: '100%', md: '250px' }, height: { xs: '45px', md: '60px' } }}
              >
                Отменить бронирование
              </Button>
              <Button
                variant="contained"
                onClick={() => window.location.replace(`${baseUrl}/pay/Railway/${responseReservation.OrderId}`)}
                disabled={!agreement}
                sx={{ width: { xs: '100%', md: '200px' }, height: { xs: '45px', md: '60px' } }}
              >
                Оплатить
              </Button>
            </Stack>
            <Stack direction={'row'} alignContent={'center'}>
              <FormControlLabel
                control={<Checkbox checked={agreement} onClick={() => setAgreement(!agreement)} />}
                label="Даю согласие на обработку персональных данных"
              />
            </Stack>
          </Stack>
          {trainReturnData !== '' && (
            <ErrorBox marginTop={{ xs: '40px', md: '60px' }}>
              <Typography fontSize={{ xs: 24, md: 40 }} mb={1}>
                Обратите внимание!
              </Typography>
              <Typography fontSize={{ xs: 16, md: 20 }}>
                {`Возврат билета по направлению ${trainData.InitialTrainStationName} - ${trainData.DestinationStationName} возможен только после возврата билета ${trainReturnData.InitialTrainStationName} - ${trainReturnData.DestinationStationName}`}
              </Typography>
            </ErrorBox>
          )}
          <ErrorBox marginTop={{ xs: '40px', md: '60px' }}>
            <Typography fontSize={{ xs: 24, md: 40 }} mb={1}>
              Обратите внимание!
            </Typography>
            <Typography fontSize={{ xs: 16, md: 20 }}>
              {formatText(responseReservation.ReservationResults[0].AdditionalInformation)}
            </Typography>
          </ErrorBox>
        </MainContainer>
      </Box>
    </Box>
  );
};
