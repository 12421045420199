import { DatePicker as MuiDatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { ReactComponent as Calendar } from '../Assets/calendar.svg';
import ru from 'date-fns/locale/ru';

const slotPropsOptions = {
  textField: {
    sx: {
      '.MuiOutlinedInput-root': {
        height: '60px',
        backgroundColor: '#FFFFFF',
        width: '100%',
        paddingRight: '20px',
        pointerEvents: 'none', // Отключаем взаимодействие с текстом
      },
      alignItems: 'flex-start',
      '& .MuiOutlinedInput-input': {
        paddingTop: '30px !important',
        paddingBottom: '8px !important',
        lineHeight: 1.2,
      },
    },
    inputProps: {
      style: {
        borderRadius: '10px',
        fontWeight: 600,
        color: '#1D2433CC',
        backgroundColor: '#FFFFFF',
        caretColor: 'transparent',
        cursor: 'pointer',
      },
    },
    InputLabelProps: {
      sx: {
        position: 'absolute',
        left: 12,
        top: '50%',
        transform: 'translateY(-50%)',
        transition: 'all 0.3s ease',
        pointerEvents: 'none',
        color: '#637381',
        fontWeight: 600,
        '&.MuiFormLabel-filled, &.MuiFormLabel-shrink, &.Mui-focused': {
          fontWeight: 400,
          top: '30%',
          transform: 'translateY(-30%)',
          fontSize: '0.85rem',
          color: '#637381 !important',
        },
      },
    },
  },
};

export const DatePicker = props => {
  const { value, label, textFieldProps, placeholder = 'дата', isCalendarOpen, disabled, ...restOfProps } = props;

  return (
    <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={ru}>
      <MuiDatePicker
        {...restOfProps}
        value={value}
        desktopModeMediaQuery="(min-width: 0px)"
        open={isCalendarOpen}
        slotProps={{
          openPickerButton: {
            sx: {
              marginTop: isCalendarOpen || !!value ? '20px !important' : 0,
              transition: 'all 0.3s ease',
            },
          },

          openPickerIcon: {
            component: Calendar,
          },
          textField: {
            ...textFieldProps,
            placeholder,
            focused: isCalendarOpen || !!value,
            label: label,
            sx: {
              ...slotPropsOptions.textField.sx,
              width: '100%',
            },
            inputProps: {
              ...slotPropsOptions.textField.inputProps,
            },
            InputLabelProps: {
              ...slotPropsOptions.textField.InputLabelProps,
            },
          },
        }}
      />
    </LocalizationProvider>
  );
};
