import { CustomTextField } from '../../../Components-2.0/CustomTextField';

export const FormElementTextField = props => {
  const { sx, inputProps } = props;
  return (
    <CustomTextField
      {...props}
      sx={{
        ...sx,
        height: '100%',
      }}
      inputLabelSxProps={{
        '&.MuiInputLabel-shrink': {
          fontWeight: 400,
          top: '10%',
          transform: 'translateY(-10%)',
          fontSize: '0.85rem',
        },
        '&.MuiFormLabel-root.Mui-focused': {
          color: '#637381 !important',
        },
      }}
      inputProps={{
        ...inputProps,
        sx: {
          '& input::-webkit-calendar-picker-indicator': {
            display: 'none',
          },
          color: '#000',
          '.MuiOutlinedInput-input': {
            paddingTop: '32px',
          },
        },
      }}
    />
  );
};
