import { useEffect, useState } from 'react';
import FormComponent from '../../Components-2.0/FormComponents/FormGenerator';
import PreFormComponent from '../../Components-2.0/FormComponents/PreForm/PreFormComponent';
import {
  $buyerId,
  $isReturnPageStore,
  $peakedPlacesToStore,
  $reservationItemsIfReturnStore,
  $localTrainInfoFromStore,
  $localTrainInfoReturnFromStore,
  $peakedPlacesReturnStore,
  $reservationItemsForwardBackwardStore,
} from '../../Services/trainsService';
import { useStore } from 'effector-react';
import { useLocation, useNavigate } from 'react-router-dom';
import { CustomModal } from '../../Components/CustomModal/CustomModal';
import { Box } from '@mui/material';

export const PassengerFormPage = () => {
  const reservationItemsForwardBackward = useStore($reservationItemsForwardBackwardStore);
  const [preFormInfo, setPreFormInfo] = useState(null);
  const [countiesList, setCountries] = useState([]);
  const placesTo = useStore($peakedPlacesToStore);
  const placesReturn = useStore($peakedPlacesReturnStore);
  const allPlaces = [...placesTo, ...placesReturn];

  const isReturnPage = useStore($isReturnPageStore);
  const buyerId = useStore($buyerId);
  const trainData = useStore($localTrainInfoFromStore);
  const trainReturnData = useStore($localTrainInfoReturnFromStore);
  const navigate = useNavigate();
  const location = useLocation();
  const [carPlaceType, setCarPlaceType] = useState('');
  const [modal, setModal] = useState(false);
  const [modalMessage, setModalMessage] = useState('');
  const [totalPrice, setTotalPrice] = useState(0);

  const onCloseModalHandler = () => {
    setModal(false);
  };
  const [reservationDto, setReservationDto] = useState({
    Customers: {},
    ReservationItems: {},
  });

  useEffect(() => {
    window.removeEventListener('popstate', () => {});
    const hasWithChild = allPlaces.some(element => element.CarPlaceType === 'WithChild');
    if (hasWithChild) {
      setCarPlaceType('WithChild');
    }
  }, []);

  const calculateTotalPrice = () => {
    let totalMinPriceAndServiceCost = 0;

    if (
      allPlaces[0].PlaceReservationType === 'TwoPlacesAtOnce' ||
      allPlaces[0].PlaceReservationType === 'FourPlacesAtOnce'
    ) {
      totalMinPriceAndServiceCost += allPlaces[0].MinPrice + allPlaces[0].ServiceCost;
    } else {
      allPlaces.forEach(item => {
        totalMinPriceAndServiceCost += item.MinPrice + item.ServiceCost;
      });
    }

    return totalMinPriceAndServiceCost;
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <Box>
      <CustomModal open={modal} onOpen={setModal} onClose={onCloseModalHandler}>
        <h2 style={{ color: 'red' }}>Ошибка!</h2>
        <h4>{modalMessage}</h4>
      </CustomModal>
      <Box>
        {preFormInfo === null ? (
          <PreFormComponent
            setPreFormInfo={setPreFormInfo}
            forward={placesTo}
            backward={placesReturn}
            carrier={reservationItemsForwardBackward.forward.Car.Carrier}
            carType={reservationItemsForwardBackward.forward.Car.CarType}
            provider={trainData.Provider}
          />
        ) : (
          <FormComponent
            totalPrice={calculateTotalPrice().toFixed(2)}
            preformInfo={preFormInfo}
            placeReservationType={placesTo[0].PlaceReservationType}
          />
        )}
      </Box>
    </Box>
  );
};
