import { useEffect, useState } from 'react';
import { useOutletContext, useParams } from 'react-router-dom';
import axios from 'axios';
import '../../Styles/Components/SvgCarScheme.scss';
import { Svg } from './Svg';
import { baseUrl } from '../../config';
export const SvgCarScheme = () => {
  const params = useParams();
  const [cars, state] = useOutletContext();
  const [svgCarScheme, setSvgCarScheme] = useState([]);
  const [isSchemeLoaded, setSchemeLoaded] = useState(false);
  const [displayWidth, setDisplayWidth] = useState(window.innerWidth);
  const isMobile = displayWidth <= 768;

  //Функция для определения ширины экрана
  function handleWindowSizeChange() {
    setDisplayWidth(window.innerWidth);
  }

  //ФУНКЦИЯ ЗАПРОСА ДЛЯ ЗАГРУЗКИ СХЕМЫ ВАГОНА C 1 этажом
  const getScheme = (schemeId, type) => {
    try {
      axios
        .get(`${baseUrl}/api/Railway/get_scheme/${schemeId}/${type}FirstStorey`)
        .then(response => {
          setSvgCarScheme([response.data]);
          setSchemeLoaded(true);
        })
        .catch(() => setSchemeLoaded(false));
    } catch (error) {
      throw error;
    }
  };

  //ФУНКЦИЯ ЗАПРОСА ДЛЯ ЗАГРУЗКИ СХЕМЫ ВАГОНА C 2 этажами
  const getSchemeTwoStorey = (schemeId, type) => {
    try {
      axios
        .all([
          axios.get(`${baseUrl}/api/Railway/get_scheme/${schemeId}/${type}FirstStorey`),
          axios.get(`${baseUrl}/api/Railway/get_scheme/${schemeId}/${type}SecondStorey`),
        ])
        .then(
          axios.spread((firstResponse, secondResponse) => {
            setSvgCarScheme([firstResponse.data]);
            setSvgCarScheme(state => [...state, secondResponse.data]);
            setSchemeLoaded(true);
          }),
        )
        .catch(() => setSchemeLoaded(false));
    } catch (error) {
      throw error;
    }
  };

  useEffect(() => {
    window.addEventListener('resize', handleWindowSizeChange);
    return () => {
      window.removeEventListener('resize', handleWindowSizeChange);
    };
  }, []);

  useEffect(() => {
    if (isMobile) {
      if (cars[params.CarId].IsTwoStorey === true) {
        return getSchemeTwoStorey(cars[params.CarId].RailwayCarSchemeId, 'Mobile');
      }
      return getScheme(cars[params.CarId].RailwayCarSchemeId, 'Mobile');
    }
    if (cars[params.CarId].IsTwoStorey) {
      return getSchemeTwoStorey(cars[params.CarId].RailwayCarSchemeId, 'Pc');
    }
    getScheme(cars[params.CarId].RailwayCarSchemeId, 'Pc');
  }, [params.CarId]);

  return (
    <Svg
      state={state}
      cars={cars}
      carId={params.CarId}
      isSchemeLoaded={isSchemeLoaded}
      svgCarScheme={svgCarScheme}
      setSchemeLoaded={setSchemeLoaded}
    />
  );
};
