import { useEffect, useState } from 'react';
import '../../Styles/Components/LiveSearchInput.scss';
import { LiveSearchDropdown } from './LiveSearchDropdown';
import { HardcodeDropdown } from './HardcodeDropdown';

const citiesHardcode = [
  {
    ExpressCode: '2000000',
    CountryId: 177,
    CityId: 6256,
    NameRu: 'Москва',
    popularityIndex: 257,
    type: 'RailwayStation',
    RegionId: 128,
    NameEn: 'MOSKVA',
    transportnodeid: 16596,
  },
  {
    ExpressCode: '2004001',
    CountryId: 0,
    CityId: 8480,
    NameRu: 'Санкт-Петербург',
    popularityIndex: 255,
    type: 'RailwayStation',
    RegionId: 6592,
    NameEn: 'SANKT-PETERBURG GLAVNY',
    transportnodeid: 16884,
  },
  {
    ExpressCode: '2060615',
    CountryId: 177,
    CityId: 3808,
    NameRu: 'Казань',
    popularityIndex: 203,
    type: 'RailwayStation',
    RegionId: 153,
    NameEn: 'KAZAN',
    transportnodeid: 17285,
  },
  {
    ExpressCode: '2060001',
    CountryId: 0,
    CityId: 6621,
    NameRu: 'Нижний Новгород',
    popularityIndex: 210,
    type: 'RailwayStation',
    RegionId: 131,
    NameEn: 'NIJNII NOVGOROD MOSKOV',
    transportnodeid: 16065,
  },
  {
    ExpressCode: '2064150',
    CountryId: 0,
    CityId: 53050,
    NameRu: 'Адлер',
    popularityIndex: 198,
    type: 'RailwayStation',
    RegionId: 119,
    NameEn: 'ADLER',
    transportnodeid: 15713,
  },
  {
    ExpressCode: '2014000',
    CountryId: 177,
    CityId: 2165,
    NameRu: 'Воронеж',
    popularityIndex: 192,
    type: 'RailwayStation',
    RegionId: 99,
    NameEn: 'VORONEJ',
    transportnodeid: 16000,
  },
  {
    ExpressCode: '2030000',
    CountryId: 0,
    CityId: 3146,
    NameRu: 'Екатеринбург',
    popularityIndex: 150,
    type: 'RailwayStation',
    RegionId: 148,
    NameEn: 'EKATERINBURG-PASSAJIRS',
    transportnodeid: 16898,
  },
  {
    ExpressCode: '2100000',
    CountryId: 24,
    CityId: 6083,
    NameRu: 'Минск',
    popularityIndex: 182,
    type: 'RailwayStation',
    RegionId: 2453,
    NameEn: 'MINSK',
    transportnodeid: 16575,
  },
];

const countriesHardcode = [
  {
    CountryId: 177,
    Alpha2Code: 'RU',
    NameRu: 'Российская Федерация',
    NameEn: 'Russian Federation',
    IsActive: true,
    Updated: '2022-03-18T14:12:48',
  },
  {
    CountryId: 24,
    Alpha2Code: 'BY',
    NameRu: 'Республика Беларусь',
    NameEn: 'Republic of Belarus',
    IsActive: true,
    Updated: '2023-01-31T12:01:02',
  },

  {
    CountryId: 223,
    Alpha2Code: 'UZ',
    NameRu: 'Республика Узбекистан',
    NameEn: 'Republic of Uzbekistan',
    IsActive: true,
    Updated: '2023-02-02T12:11:26',
  },
  {
    CountryId: 91,
    Alpha2Code: 'KZ',
    NameRu: 'Республика Казахстан',
    NameEn: 'Republic of Kazakhstan',
    IsActive: true,
    Updated: '2023-02-02T12:11:26',
  },
  {
    CountryId: 224,
    Alpha2Code: 'UA',
    NameRu: 'Украина',
    NameEn: 'Ukraine',
    IsActive: true,
    Updated: '2020-10-23T08:15:23',
  },
  {
    CountryId: 17,
    Alpha2Code: 'AM',
    NameRu: 'Республика Армения',
    NameEn: 'Republic of Armenia',
    IsActive: true,
    Updated: '2023-01-31T12:01:02',
  },
];
export const LiveSearch = ({
  labelKey, //*Ключ по которому выводится список элементов массива
  valueKey, //*Ключ по которому будут вносится значения в стейт
  onSelect, //*Функция передачи значения выбранного элемента из списка
  value, //Значени input
  onChange, // Изменить значение input
  responseData, //*Список элементов
  onSearch, //*Функция запроса
  placeholder, // placeholder
  needPlaceholder,
  swaped,
  setSwaped,
  type,
  id,
}) => {
  const [showDropdown, setShowDropDown] = useState(false); //Открывать или закрывать список городов
  const [showDropdownForHardcode, setShowDropDownForHardcode] = useState(false); //Открывать или закрывать список городов

  const [hardcodeData, setHardcodeData] = useState(type === 'Cities' ? citiesHardcode : countriesHardcode);
  const [searchValue, setSearchValue] = useState(value);
  const [isFirstRender, setIsFirtsRender] = useState(true);
  //При нажатии на элемент из списка городов
  const onItemClickHandler = value => {
    onSelect(value[valueKey]);
    onChange(value[labelKey]);
    setShowDropDownForHardcode(false);
    setShowDropDown(false);
  };

  //Функция для конвертации латинских букв
  const convertToRussian = text => {
    const latinToRussian = {
      q: 'й',
      w: 'ц',
      e: 'у',
      r: 'к',
      t: 'е',
      y: 'н',
      u: 'г',
      i: 'ш',
      o: 'щ',
      p: 'з',
      '[': 'х',
      ']': 'ъ',
      a: 'ф',
      s: 'ы',
      d: 'в',
      f: 'а',
      g: 'п',
      h: 'р',
      j: 'о',
      k: 'л',
      l: 'д',
      ';': 'ж',
      "'": 'э',
      z: 'я',
      x: 'ч',
      c: 'с',
      v: 'м',
      b: 'и',
      n: 'т',
      m: 'ь',
      ',': 'б',
      '.': 'ю',
      '/': '.',
      Q: 'Й',
      W: 'Ц',
      E: 'У',
      R: 'К',
      T: 'Е',
      Y: 'Н',
      U: 'Г',
      I: 'Ш',
      O: 'Щ',
      P: 'З',
      '{': 'Х',
      '}': 'Ъ',
      A: 'Ф',
      S: 'Ы',
      D: 'В',
      F: 'А',
      G: 'П',
      H: 'Р',
      J: 'О',
      K: 'Л',
      L: 'Д',
      ':': 'Ж',
      '"': 'Э',
      Z: 'Я',
      X: 'Ч',
      C: 'С',
      V: 'М',
      B: 'И',
      N: 'Т',
      M: 'Ь',
      '<': 'Б',
      '>': 'Ю',
      '?': ',',
    };

    let russianText = '';
    for (let i = 0; i < text.length; i++) {
      if (latinToRussian[text[i]]) {
        russianText += latinToRussian[text[i]];
      } else {
        russianText += text[i];
      }
    }
    return russianText;
  };

  useEffect(() => {
    if (isFirstRender) {
      setIsFirtsRender(false);
      return;
    } else {
      let timerId;
      onChange(convertToRussian(searchValue));
      if (searchValue) {
        setShowDropDownForHardcode(false);

        timerId = setTimeout(() => {
          setShowDropDown(true);
          onSearch(convertToRussian(searchValue));
        }, 500);
      }
      // Очищаем таймер при каждом изменении ввода
      return () => {
        clearTimeout(timerId);
      };
    }
  }, [searchValue]);
  const onChangeHandler = event => {
    const { value } = event.target;
    setSearchValue(value);
  };

  const onBlur = () => {
    if (value.length <= 1) {
      onChange(value);
      onSelect('');
    } else {
      if (responseData[0]) {
        onChange(responseData[0][labelKey]);
        onSelect(responseData[0][valueKey]);
      }
    }
  };

  //Запретить ввод цифр
  const noDigits = event => {
    if ('1234567890'.indexOf(event.key) !== -1) event.preventDefault();
  };

  return (
    <div className="liveSearchInputContainer">
      <input
        required
        name={id ? `liveSearch-${id}` : ''}
        className={needPlaceholder ? 'searchInputNeedPlaceHolder col-12' : 'searchInput col-12'}
        placeholder={placeholder}
        type="text"
        value={value}
        onChange={onChangeHandler}
        onBlur={onBlur}
        onKeyDown={noDigits}
        onClick={() => setShowDropDownForHardcode(true)}
      />

      <LiveSearchDropdown
        responseData={responseData}
        showDropdown={showDropdown}
        onItemClickHandler={onItemClickHandler}
        onClickOutside={setShowDropDown}
        labelKey={labelKey}
        value={valueKey}
      />
      <HardcodeDropdown
        responseData={hardcodeData}
        showDropdown={showDropdownForHardcode}
        onItemClickHandler={onItemClickHandler}
        onClickOutside={setShowDropDownForHardcode}
        labelKey={labelKey}
        value={valueKey}
      />
    </div>
  );
};
