import { useState } from 'react';
import { FormControl, InputLabel, MenuItem, Select } from '@mui/material';
import { FormElementTextField } from '../form-controls/FormElementTextField';

export const GenderSelect = props => {
  const [sex, setSex] = useState('');

  const setSexHandler = e => {
    setSex(e.target.value);
    if (props.onChange) {
      props.onChange(e);
    }
  };

  return (
    <FormControl fullWidth>
      <FormElementTextField
        required
        select
        labelId={`gender-select-label-${props.id}`}
        id={`gender-select-${props.id}`}
        name={`${props.name}-gender`}
        value={sex}
        onChange={setSexHandler}
        label="Пол"
        sx={{ ...props.formInputStyles }}
      >
        <MenuItem value="Male" sx={{ color: 'black' }}>
          Мужской
        </MenuItem>
        <MenuItem value="Female" sx={{ color: 'black' }}>
          Женский
        </MenuItem>
      </FormElementTextField>
    </FormControl>
  );
};
