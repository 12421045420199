import { useEffect } from 'react';
import { resetIsReturnPage, resetPlaces, resetReservationItems, setIsStartPage } from '../../Services/trainsService';
import '../../Styles/Pages/StartPage/StartPage.scss';
import { Box } from '@mui/material';
import backgroundImage from '../../Assets/background.png';
import { RailwayForm } from '../../Components-2.0/RailwayForm/RailwayForm2';

export const StartPage = () => {
  useEffect(() => {
    setIsStartPage(true);
    resetPlaces();
    resetIsReturnPage();
    resetPlaces();
    resetReservationItems();
  }, []);

  return (
    <Box display={'flex'} flexDirection={'column'}>
      <Box marginTop={{ xs: 5, md: 15 }} marginBottom={{ xs: 0, md: -5 }} sx={{ padding: '0 20px' }}>
        <RailwayForm />
      </Box>
      <Box margin={'0 auto'} marginBottom={{ xs: 8, md: 15 }} zIndex={-1}>
        <img src={backgroundImage} />
      </Box>
    </Box>
  );
};
