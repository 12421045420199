import { useEffect, useRef, useState } from 'react';
import '../../Styles/Components/SvgCarScheme.scss';
import { Container } from 'react-bootstrap';
import { PuffLoader } from 'react-spinners';
import {
  $compartmentsForReservation,
  $dateReturnStore,
  $isReturnPageStore,
  $peakedPlacesToStore,
  peakPlaceReturn,
  peakPlaceTo,
  resetCompartments,
  setCompartments,
  setLocalPlace,
  setPlaceReservation,
} from '../../Services/trainsService';
import { useStore } from 'effector-react';
import { CustomModal } from '../CustomModal/CustomModal';
import { Stack, Grid2, SvgIcon } from '@mui/material';
import MainContainer from '../../Components-2.0/MainContainer/MainContainer';
import { ReactComponent as UpperArrowIcon } from '../../Assets/UpperArrow.svg';
import { ReactComponent as DownArrowIcon } from '../../Assets/DownArrow.svg';

export const Svg = ({ svgCarScheme, isSchemeLoaded, setSchemeLoaded, cars, carId }) => {
  const refFirstStorey = useRef(null);
  const refSecondStorey = useRef(null);
  const tooltipRef = useRef(null);
  const [pickedPlaces, setPickPlaces] = useState([]);
  const isReturnPage = useStore($isReturnPageStore);
  const dateReturn = useStore($dateReturnStore);
  const placesTo = useStore($peakedPlacesToStore);
  const [serviceClass, setServiceClass] = useState('');
  const [carPlaceType, setCarPlaceType] = useState('');
  const [schemeUnmapped, setSchemeUnmapped] = useState(false);
  const [localCompartments, setLocalCompartments] = useState({});
  const compartmentsStore = useStore($compartmentsForReservation);
  const [tempCompartments, setTempCompartments] = useState({});
  const [modal, setOpenModal] = useState(false);

  const setLocalCompartmentsHandler = (array, place) => {
    const trainNumber = cars[carId].TrainNumber;
    const carNumber = cars[carId].CarNumber;
    const prevCompartments =
      localCompartments[trainNumber] !== undefined ? localCompartments[trainNumber][carNumber] : {};

    let compartments = {};
    if (prevCompartments.hasOwnProperty(place)) {
      delete prevCompartments[place];
      compartments = prevCompartments;
    } else {
      compartments = { ...prevCompartments, [place]: array };
    }

    setCompartments({ [trainNumber]: { [carNumber]: compartments } });
    setLocalCompartments({
      ...localCompartments,
      [trainNumber]: {
        ...localCompartments[trainNumber],
        [carNumber]: compartments,
      },
    });
  };

  const compartmentsSetter = async (carId, data, state) => {
    const placeIndex = state.findIndex(place => place.Place === data.Place);
    const freePlacesByCompartments = cars[carId].FreePlacesByCompartments;
    const placesArr = Object.values(freePlacesByCompartments).flatMap(({ Places }) => Places);

    if (placesArr.includes(data.Place)) {
      Object.values(freePlacesByCompartments).forEach(places => {
        places.Places.find((element, index, array) => {
          if (element === data.Place) {
            const filteredFreePlaces = cars[carId].FreePlaces.filter(place => array.includes(place.Place));
            if (data.PlaceReservationType !== 'Usual') {
              resetCompartments();
              let compartments = tempCompartments;
              if (placeIndex > -1) {
                const deletePlaces = state.filter(
                  elem1 => !filteredFreePlaces.some(elem2 => JSON.stringify(elem1) === JSON.stringify(elem2)),
                );
                state.splice(0, state.length, ...deletePlaces);

                filteredFreePlaces.forEach(filteredPlace => {
                  delete compartments[filteredPlace.Place];
                });
              } else {
                filteredFreePlaces.forEach(filteredPlace => {
                  state.push(filteredPlace);
                  compartments = {
                    ...compartments,
                    [filteredPlace.Place]: array,
                  };
                });
              }
              setCompartments({
                [cars[carId].TrainNumber]: {
                  [cars[carId].CarNumber]: {
                    ...tempCompartments,
                    ...compartments,
                  },
                },
              });
              setTempCompartments({ ...compartments });
            } else {
              if (placeIndex > -1) {
                state.splice(placeIndex, 1);
              } else {
                setLocalCompartmentsHandler(array, data.Place);
                state.push(data);
              }
            }
          }
        });
      });
    } else {
      if (placeIndex > -1) {
        state.splice(placeIndex, 1);
      } else {
        state.push(data);
      }
    }
  };

  const conditionalPicker = (state, data) => {
    setServiceClass(data.ServiceClass);
    setCarPlaceType(data.CarPlaceType);
    setPlaceReservation(data.PlaceReservationType);

    if (carPlaceType !== '') {
      if (data.CarPlaceType === 'MotherAndBaby' || data.CarPlaceType === 'WithChild') {
        if (data.CarPlaceType !== carPlaceType) {
          state = [];
        }
      } else {
        if (carPlaceType === 'MotherAndBaby' || carPlaceType === 'WithChild') {
          if (data.CarPlaceType !== carPlaceType) {
            state = [];
          }
        }
      }
    }
    const placeIndex = state.findIndex(place => place.Place === data.Place);
    if (serviceClass !== data.ServiceClass && serviceClass !== '') {
      state = [];
      resetCompartments();
    }

    if (serviceClass !== data.ServiceClass) {
      state = [];
    }

    const compartmentSetterCondition = comparativeElement => {
      if (state.length < comparativeElement) compartmentsSetter(carId, data, state);
      else if (placeIndex > -1) {
        compartmentsSetter(carId, data, state);
        // state.splice(placeIndex, 1);
      }
    };
    if (dateReturn !== null) {
      if (isReturnPage) {
        if (data.PlaceReservationType === 'Usual') {
          compartmentSetterCondition(placesTo.length);
        } else {
          setOpenModal(true);
        }
      } else {
        if (data.PlaceReservationType !== 'Usual') {
          setOpenModal(true);
        } else {
          compartmentSetterCondition(4);
        }
      }
    } else {
      let placesAmount = 4;

      if (data.PlaceReservationType === 'TwoPlacesAtOnce') {
        placesAmount = 2;
      }
      compartmentSetterCondition(placesAmount);
      // state.splice(placeIndex, 1);
    }
    return [...state];
  };

  const findGender = gender => {
    switch (gender) {
      case 'Male':
        return 'Муж.';
        break;
      case 'Female':
        return 'Жен.';
        break;
      case 'Whole':
        return 'Целое';
        break;
      case 'Mixed':
        return 'Смешанное';
        break;
      default:
        return 'Целое';
        break;
    }
  };

  const getCarPlaceTypeMeaning = carPlaceType => {
    switch (carPlaceType) {
      case 'AisleSeat':
        return 'Место у прохода';
        break;
      case 'Lower':
      case 'LastCompartmentLowerWithHigherLevelOfNoise':
        return 'Нижнее';
        break;
      case 'Upper':
      case 'LastCompartmentUpperWithHigherLevelOfNoise':
        return 'Верхнее';
        break;
      case 'SideLower':
        return 'Боковое нижнее';
        break;
      case 'SideUpper':
        return 'Боковое верхнее';
        break;
      case 'NoValue':
      case 'NoTableBackward':
      case 'NoTableForward':
      case 'SingleForward':
      case 'NearTableBackward':
      case 'NearTableForward':
      case 'NoWindowBackward':
      case 'NoWindowForward':
      case 'NearRestroomAndForward':
      case 'NearRestroomAndBackward':
      case 'Usual':
        return 'Сидячее';
        break;
      case 'MotherAndBaby':
        return 'Место матери и ребенка';
        break;
      case 'WithChild':
        return 'Для пассажиров с детьми';
        break;
      case 'WithPets':
        return 'Для пассажиров с животными';
        break;
    }
  };

  //Функция показа тултипа

  const showTooltip = (available, place) => {
    if (available === true) {
      tooltipRef.current.setAttribute('class', 'tooltipScheme');
      tooltipRef.current.querySelector('#place').textContent = `Место №${place.Place}`;
      tooltipRef.current.querySelector('#price').textContent = !place.HasNonRefundableTariff
        ? place.ServiceCost !== 0
          ? `Цена: ${
              place.MinPrice !== place.MaxPrice
                ? 'от ' +
                  (place.MinPrice + place.ServiceCost).toFixed(2) +
                  ' до ' +
                  (place.MaxPrice + place.ServiceCost).toFixed(2)
                : (place.MinPrice + place.ServiceCost).toFixed(2)
            } ₽`
          : `Цена: ${
              place.MinPrice !== place.MaxPrice ? 'от ' + place.MinPrice + ' до ' + place.MaxPrice : place.MinPrice
            } ₽`
        : 'Цена: ';
      //   place.ServiceCost === 0
      //     ? `Цена: \n${place.HasNonRefundableTariff ?<br/>`По возвратному тарифу: ${place.MaxPrice} ₽
      //     \nПо невозвратному тарифу: ${place.MinPrice}` :
      //       place.MinPrice !== place.MaxPrice ? 'от ' + place.MinPrice + ' до ' + place.MaxPrice : place.MinPrice} ₽`
      //
      //     :
      //     `Цена: ${place.HasNonRefundableTariff ? `По возвратному тарифу: ${(place.MaxPrice + place.ServiceCost).toFixed(2)} ₽
      //     \nПо невозвратному тарифу: ${(place.MinPrice + place.ServiceCost).toFixed(2)}` :
      //       place.MinPrice !== place.MaxPrice ? 'от ' + (place.MinPrice + place.ServiceCost).toFixed(2) + ' до ' +
      //         (place.MaxPrice + place.ServiceCost).toFixed(2) :
      //         (place.MinPrice + place.ServiceCost).toFixed(2)} ₽`
      tooltipRef.current.querySelector('#refundableTariff').textContent = place.HasNonRefundableTariff
        ? place.ServiceCost !== 0
          ? `по возвратному тарифу: ${(place.MaxPrice + place.ServiceCost).toFixed(2)} ₽`
          : `по возвратному тарифу: ${place.MaxPrice.toFixed(2)} ₽`
        : '';
      tooltipRef.current.querySelector('#nonRefundableTariff').textContent = place.HasNonRefundableTariff
        ? place.ServiceCost !== 0
          ? `по невозвратному тарифу: ${(place.MinPrice + place.ServiceCost).toFixed(2)} ₽,`
          : `по невозвратному тарифу: ${place.MinPrice.toFixed(2)} ₽,`
        : '';
      tooltipRef.current.querySelector('#service-fee').textContent =
        place.ServiceCost === 0 ? '' : `(С учетом сервисного сбора - ${place.ServiceCost} ₽)`;
      tooltipRef.current.querySelector('#tariff').textContent = `Тариф: ${
        place.HasNonRefundableTariff === true ? 'Невозвратный' : 'Возвратный'
      }`;
      tooltipRef.current.querySelector('#childTarif').textContent =
        cars[carId].CarTypeName === 'ЛЮКС' && place.PlaceReservationType == 'TwoPlacesAtOnce'
          ? `(Детский тариф не применяется)`
          : '';
      tooltipRef.current.querySelector('#type').textContent = `Класс обслуживания: ${place.ServiceClass}`;
      if (cars[carId].HasGenderCabins) {
        tooltipRef.current.querySelector('#serviceClass').textContent = `Гендерный признак: ${findGender(
          place.Gender,
        )}`;
      }
      if (place.CarPlaceTypeMeaning !== '') {
        tooltipRef.current.querySelector('#carPlaceTypeMeaning').textContent = `${place.CarPlaceTypeMeaning}`;
      }
      tooltipRef.current.querySelector('#reservationType').textContent =
        place.PlaceReservationType === 'Usual' ? 'Покупка одного места' : 'Цена за все места в купе';
    } else {
      tooltipRef.current.setAttribute('class', 'tooltipScheme');
      tooltipRef.current.querySelector('#place').textContent = `Выбрать нельзя!`;
      tooltipRef.current.querySelector('#type').textContent = `Текущий тип: ${findGender(place.Gender)}`;
      tooltipRef.current.querySelector('#price').textContent = `Тип(Туда): ${findGender(placesTo[0].Gender)}`;
    }
  };
  const hideTooltip = () => {
    tooltipRef.current.setAttribute('class', 'tooltipNone');
  };
  const followMouse = e => {
    tooltipRef.current.style = `position: absolute; left: ${e.pageX - 300}px; top: ${
      cars[carId].HasGenderCabins ? e.pageY - 300 : e.pageY - 300
    }px`;
  };

  //Функция преобразобвания SVG-схемы в кликабельный компонент
  const modifySvgScheme = ref => {
    try {
      if (ref.current !== null) {
        let title = ref.current.querySelector('title');
        let numbersList = ref.current.querySelector('#Numbers').childNodes;
        let seatsList = ref.current.querySelector('#Seats').childNodes;

        //Удаление дефолтного заголовка
        if (title !== null) {
          title.textContent = '';
        }

        //Обрабока блока Numbers
        numbersList.forEach(numberTag => {
          numberTag.setAttribute('class', 'Numbers');
        });

        const seatsArr = [];
        seatsList.forEach(pathTag => {
          seatsArr.push(pathTag.id.replace('Seat', ''));
        });
        const filteredSeatsArr = seatsArr.filter(el => el !== '');

        const isAllPlacesMapped = cars[carId].FreePlaces.every(place => filteredSeatsArr.includes(place.Place));

        if (isAllPlacesMapped) {
          setSchemeLoaded(true);
        } else {
          setSchemeLoaded(false);
        }

        //Обработка блока Seats
        if (isReturnPage) {
          const genderFilteredFreePlaces = cars[carId].FreePlaces.filter(
            freePlace =>
              freePlace.Gender === placesTo[0].Gender || freePlace.Gender === 'Mixed' || freePlace.Gender === 'NoValue',
          );
          seatsList.forEach((pathTag, i = 0) => {
            if (genderFilteredFreePlaces.find(freePlace => freePlace.Place === pathTag.id.replace('Seat', ''))) {
              const placeId = genderFilteredFreePlaces.findIndex(
                place => place.Place === pathTag.id.replace('Seat', ''),
              );
              pathTag.onmouseenter = () => {
                showTooltip(true, genderFilteredFreePlaces[placeId]);
              };
              pathTag.onmouseleave = () => {
                hideTooltip();
              };
              pathTag.onmouseover = e => {
                followMouse(e);
              };
              pathTag.onclick = () => {
                setPickPlaces(state => conditionalPicker(state, genderFilteredFreePlaces[placeId]));
              };
              pathTag.setAttribute(
                'class',
                pickedPlaces.find(place => place.Place === pathTag.id.replace('Seat', '')) ? 'SeatPeaked' : 'Seat',
              );
            } else {
              let isUnavailableByGender = cars[carId].FreePlaces.find(
                freePlace => freePlace.Place === pathTag.id.replace('Seat', ''),
              );
              if (isUnavailableByGender) {
                const placeId = cars[carId].FreePlaces.findIndex(
                  place => place.Place === pathTag.id.replace('Seat', ''),
                );
                pathTag.onmouseenter = () => {
                  showTooltip(false, cars[carId].FreePlaces[placeId]);
                };
                pathTag.onmouseleave = () => {
                  hideTooltip();
                };
                pathTag.onmouseover = e => {
                  followMouse(e);
                };
                pathTag.setAttribute('class', 'SeatUnavailable');
              } else {
                pathTag.setAttribute('class', 'SeatTaken');
              }
            }
          });
        } else {
          seatsList.forEach((pathTag, i = 0) => {
            if (cars[carId].FreePlaces.find(freePlace => freePlace.Place === pathTag.id.replace('Seat', ''))) {
              const placeId = cars[carId].FreePlaces.findIndex(place => place.Place === pathTag.id.replace('Seat', ''));
              pathTag.onmouseenter = () => {
                showTooltip(true, cars[carId].FreePlaces[placeId]);
              };
              pathTag.onmouseleave = () => {
                hideTooltip();
              };
              pathTag.onmouseover = e => {
                followMouse(e);
              };
              pathTag.onclick = () => {
                setPickPlaces(state => conditionalPicker(state, cars[carId].FreePlaces[placeId]));
              };
              pathTag.setAttribute(
                'class',
                pickedPlaces.find(place => place.Place === pathTag.id.replace('Seat', '')) ? 'SeatPeaked' : 'Seat',
              );
            } else {
              pathTag.setAttribute('class', 'SeatTaken');
            }
          });
        }
      }
    } catch (error) {
      console.log(error);
    }
  };

  //Функция для исправления массива FreePlaces в случае мест типа "A02"
  const modifyFreePlaces = data => {
    for (let i = 0; i < data.length; i++) {
      let place = data[i].Place;
      if (place.startsWith('А0')) {
        place = place.slice(2) + 'А';
      } else if (place.match(/^А[^0]\d*$/)) {
        place = place.replace('А', '') + 'А';
      }
      data[i].Place = place;
    }
    return data;
  };

  useEffect(() => {
    setLocalCompartments({});
    setPickPlaces([]);
  }, [carId]);

  useEffect(() => {
    // if (dateReturn !== "") {
    //   cars[carId].FreePlaces = cars[carId].FreePlaces.filter(
    //     (place) => place.PlaceReservationType === "Usual"
    //   );
    // }
    if (isSchemeLoaded) {
      cars[carId].FreePlaces = modifyFreePlaces(cars[carId].FreePlaces);

      if (svgCarScheme.length > 1) {
        modifySvgScheme(refFirstStorey);
        modifySvgScheme(refSecondStorey);
      } else {
        modifySvgScheme(refFirstStorey);
      }
    }
    if (isReturnPage) {
      peakPlaceReturn(pickedPlaces);
    } else {
      peakPlaceTo(pickedPlaces);
    }

    setLocalPlace(pickedPlaces);
  }, [isSchemeLoaded, svgCarScheme, pickedPlaces]);

  const places = cars[carId].FreePlaces;

  return (
    <div className="scheme-block" style={{ width: '1160px' }}>
      <CustomModal open={modal} onOpen={setOpenModal} onClose={() => setOpenModal(false)}>
        <h2>Внимание!</h2>
        <h4>Выбор данных мест по маршруту Туда-Обратно запрещен.</h4>
      </CustomModal>
      {isSchemeLoaded ? (
        <div className="scheme-container">
          {svgCarScheme.length > 1 ? (
            <div className="scheme">
              <h4>Этаж 1</h4>
              <div ref={refFirstStorey} dangerouslySetInnerHTML={{ __html: svgCarScheme[0] }} />
              <br />
              <h4>Этаж 2</h4>
              <div ref={refSecondStorey} dangerouslySetInnerHTML={{ __html: svgCarScheme[1] }} />
            </div>
          ) : (
            <div className="scheme" ref={refFirstStorey} dangerouslySetInnerHTML={{ __html: svgCarScheme[0] }} />
          )}
        </div>
      ) : cars[carId].RailwayCarSchemeId === null || JSON.stringify(svgCarScheme) === '[]' || !isSchemeLoaded ? (
        <MainContainer
          direction={'column'}
          sx={{
            alignItems: 'flex-start',
            padding: { xs: '20px', sm: '35px', md: '50px' },
            backgroundColor: '#fffaf2',
            borderRadius: '20px',
          }}
        >
          <Grid2 container spacing={2}>
            {cars[carId].FreePlaces.map((Place, i) => (
              <Grid2 item xs={1} key={i}>
                <Stack
                  className={
                    pickedPlaces.find(place => place.Place === Place.Place) ? 'divSeatPeaked mt-2' : 'divSeat mt-2'
                  }
                  onMouseEnter={() => showTooltip(true, Place)}
                  onMouseOver={e => followMouse(e)}
                  onMouseLeave={() => hideTooltip()}
                  onClick={() => setPickPlaces(state => conditionalPicker(state, Place))}
                >
                  {Place.CarPlaceType === 'Upper' && (
                    <UpperArrowIcon color={pickedPlaces.find(place => place.Place === Place.Place) ? '#fff' : '#000'} />
                  )}
                  <span style={{ lineHeight: '14px' }}>{Place.Place}</span>
                  {Place.CarPlaceType === 'Lower' && (
                    <DownArrowIcon color={pickedPlaces.find(place => place.Place === Place.Place) ? '#fff' : '#000'} />
                  )}
                </Stack>
              </Grid2>
            ))}
          </Grid2>
        </MainContainer>
      ) : (
        <Container className="d-flex justify-content-center pt-5" style={{ maxHeight: '100%' }}>
          <PuffLoader color={'#000'} loading={!isSchemeLoaded} size={200} />
        </Container>
      )}
      <div ref={tooltipRef} style={{ display: 'none' }}>
        {cars[carId].HasGenderCabins ? (
          <div style={{ display: 'flex', flexDirection: 'column' }}>
            <Stack direction="row" spacing={1} justifyContent="space-between">
              <span id="place" style={{ fontWeight: 700 }}></span>
              <span id="carPlaceTypeMeaning" style={{ fontWeight: 700 }}></span>
            </Stack>
            <span id="cartype">Вагон: {cars[carId].CarTypeName}</span>
            <span id="tariff"></span>
            <p id="childTarif" className="service-fee"></p>
            <span id="type"></span>
            <span id="price"></span>
            <span id="serviceClass"></span>
            <span id="reservationType"></span>
            <span id="nonRefundableTariff" style={{ fontSize: '14px' }}></span>
            <span id="refundableTariff" style={{ fontSize: '14px' }}></span>
            <p id="service-fee" className="service-fee" style={{ fontWeight: 700 }}></p>
          </div>
        ) : (
          <div style={{ display: 'flex', flexDirection: 'column' }}>
            <Stack direction="row" spacing={1} justifyContent="space-between">
              <span id="place" style={{ fontWeight: 700 }}></span>{' '}
              <span id="carPlaceTypeMeaning" style={{ fontWeight: 700 }}></span>
            </Stack>
            <span id="cartype">Вагон: {cars[carId].CarTypeName}</span>
            <span id="tariff"></span>
            <p id="childTarif" className="service-fee"></p>
            <span id="type"></span>
            <span id="reservationType"></span>
            <span id="price" style={{ fontWeight: 700 }}></span>
            <span id="nonRefundableTariff" style={{ fontSize: '14px' }}></span>
            <span id="refundableTariff" style={{ fontSize: '14px' }}></span>
            <p id="service-fee" className="service-fee" style={{ fontWeight: 700 }}></p>
          </div>
        )}
      </div>
    </div>
  );
};
