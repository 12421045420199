import { alpha } from '@mui/material/styles';

// ----------------------------------------------------------------------

export default function Autocomplete(theme) {
  return {
    MuiAutocomplete: {
      defaultProps: {
        loadingText: 'Загрузка...',
        noOptionsText: 'Нет данных',
        slotProps: {
          popper: {
            placement: 'bottom-start',
          },
        },
      },
      styleOverrides: {
        root: {
          color: theme.palette.grey[1000],
          '& .MuiInputBase-input': {
            fontWeight: 600,
            color: theme.palette.grey[1000],
          },
          '& span.MuiAutocomplete-tag': {
            width: 24,
            height: 24,
            lineHeight: '24px',
            textAlign: 'center',
            borderRadius: theme.shape.borderRadius,
            backgroundColor: alpha(theme.palette.grey[500], 0.16),
          },
          '& .MuiAutocomplete-endAdornment': {
            display: 'none',
          },
          '& .MuiOutlinedInput-root': {
            paddingRight: '0px !important',
          },
        },
        popper: {
          width: '100%',
          maxWidth: 660,
        },
        listbox: {
          padding: theme.spacing(0, 1),
        },
        option: {
          padding: theme.spacing(1),
          margin: theme.spacing(0.75, 0),
          color: theme.palette.grey[1000],
          borderRadius: theme.shape.borderRadius,
        },
      },
      MuiOutlinedInput: {
        styleOverrides: {
          root: {
            // Отключаем hover для текстового поля, используя !important
            '&:hover .MuiOutlinedInput-notchedOutline': {
              borderColor: `${theme.palette.grey[1000]} !important`,
            },
            '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
              borderColor: `${theme.palette.grey[1000]} !important`,
            },
          },
          notchedOutline: {
            borderColor: `${theme.palette.grey[1000]} !important`,
          },
        },
      },
    },
  };
}
