import { Image } from 'react-bootstrap';
import { CustomDialog } from '../../../Components/dialogs/CustomDialog';
import transliterationRules1 from './assets/transliteration_rules_1.png';
import transliterationRules2 from './assets/transliteration_rules_2.png';
import { Button } from '@mui/material';

export const FioTransliterationDialog = props => {
  const { isOpen, onClose } = props;

  return (
    <CustomDialog
      show={isOpen}
      onHide={onClose}
      size="lg"
      title="Правила транслитерации"
      body={
        <>
          <p>
            Для документов заграничный паспорт Российской Федерации и документов, выданных другими государствами,
            требуется указание фамилии, имени и отчетсва пассажира латинскими буквами.
          </p>
          <p>
            Если в документе фамилия, имя, отчество указаны латиницей, то вносить их в соответствующие поля, необходимо
            так, как указано в документе.
          </p>
          <p>
            Если фамилия, имя и отчество указаны только национальным алфавитом, то вносить их необходимо латиницей,
            воспользовавшись для перевода символов национального алфавита в латиницу правилами транслитерации,
            указанными в этих таблицах:
          </p>
          <Image src={transliterationRules1} fluid />
          <Image src={transliterationRules2} fluid />
        </>
      }
      actions={
        <Button variant="contained" onClick={onClose}>
          Закрыть
        </Button>
      }
    />
  );
};
